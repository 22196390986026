import Modal, { ModalProps } from "antd/lib/modal";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseModalFilled } from "../../../assets/icons/closeModalFilled.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface CreativePreviewModalProps extends ModalProps {
  url: string;
  type: "ad" | "creative";
}

const CreativePreviewModal = ({
  url,
  type,
  onCancel,
  ...modalProp
}: CreativePreviewModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      closable
      closeIcon={<CloseModalFilled />}
      centered
      onCancel={onCancel}
      className={cc(["campaign-modal", cm.modal])}
      destroyOnClose
      title={
        <RetailTitle level={4} className={cm.title}>
          {t(`pages.admin.creatives.${type}ModalTitle`)}
        </RetailTitle>
      }
      footer={[
        <RetailMainButton
          hasBackground
          className={cm.modalBtn}
          onClick={onCancel}
        >
          {t("common.okay")}
        </RetailMainButton>,
      ]}
      {...modalProp}
    >
      <img src={url} alt="" />
    </Modal>
  );
};

export default CreativePreviewModal;
