import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Input from "antd/lib/input";
import cc from "classcat";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import useApi from "../../../api";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailSettingsHeader from "../../../components/Layout/RetailSettingsHeader";
import RetailSettingsLayout from "../../../components/Layout/RetailSettingsLayout";
import RetailNotification from "../../../components/Notification";
import RetailSelect from "../../../components/Select/RetailSelect";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import useSettings from "../../../hooks/useSettings";
import { settingsSuccessNotification } from "../../../utils/helpers";
import cm from "./style.module.scss";

const GeneralInformationPage = () => {
  const { t } = useTranslation();

  const { data } = useSettings("MARKETPLACE");

  const { api } = useApi();

  const [form] = useForm();

  const [formFields, setFormFields] = useState<{
    name: string;
    url: string;
    email: string;
    seller: string;
  }>({
    name: "",
    url: "",
    email: "",
    seller: "",
  });

  const queryClient = useQueryClient();

  const updateValue = async () => {
    const config = {
      name: form.getFieldValue("name"),
      url: form.getFieldValue("url"),
      country_code: form.getFieldValue("country_code"),
      time_zone: form.getFieldValue("time_zone"),
      email: form.getFieldValue("email"),
      currency_code: form.getFieldValue("currency_code"),
      seller_site: form.getFieldValue("seller"),
    };
    const response = await api.patch(`settings`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const onFinish = async () => {
    try {
      await mutateAsync();
      queryClient.refetchQueries("settings");
      settingsSuccessNotification(t);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = () => {
    RetailNotification.showNotification(
      "error",
      t("pages.admin.advertiserSettings.error_title"),
      t("pages.admin.generalInfo.emailError")
    );
  };

  useEffect(() => {
    setFormFields({
      name: data?.data?.name,
      url: data?.data?.url,
      email: data?.data?.email,
      seller: data?.data?.seller_site || "",
    });
  }, [data?.data]);

  const available_currencies = [
    "TRY",
    "USD",
    "IQD",
    "TWD",
    "HKD",
    "KRW",
    "JPY",
  ];

  const options = available_currencies.map((currency) => {
    return {
      label: t(`common.${currency.toLowerCase()}`),
      value: currency,
    };
  });

  const submit = () => form?.submit();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setFormFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  if (data === undefined) return <></>;

  return (
    <RetailSettingsLayout>
      <RetailSettingsHeader type="general" onClick={submit} />
      <Form
        form={form}
        autoComplete="off"
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: data.data.name,
          url: data.data.url,
          email: data?.data.email,
          time_zone: data?.data.time_zone,
          country_code: data?.data.country_code,
          currency_code: data?.data.currency_code || "TRY",
          seller: data?.data?.seller_site,
        }}
      >
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.market")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.marketText")}
            </RetailText>
          </div>
          <RetailFormInput
            isFocused={formFields.name !== ""}
            label={t("pages.admin.generalInfo.market")}
            name="name"
            rules={[
              {
                required: true,
                message: t("pages.admin.generalInfo.marketError"),
              },
            ]}
            className="floating"
          >
            <Input
              onChange={handleChange}
              className={cc(["floating", cm.input])}
              value={formFields.name}
            />
          </RetailFormInput>
        </div>
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.url")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.urlText")}
            </RetailText>
          </div>
          <RetailFormInput
            isFocused={formFields.url !== ""}
            label={t("pages.admin.generalInfo.url")}
            name="url"
            rules={[
              {
                required: true,
                message: t("pages.admin.generalInfo.urlError"),
              },
            ]}
            className="floating"
          >
            <Input
              onChange={handleChange}
              className={cc(["floating", cm.input])}
              value={formFields.url}
            />
          </RetailFormInput>
        </div>
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.country")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.countryText")}
            </RetailText>
          </div>
          <Form.Item name="country_code">
            <RetailSelect
              className={cm.select}
              options={[
                {
                  label: t("common.turkey"),
                  value: "Turkey",
                },
                {
                  label: t("common.abd"),
                  value: "ABD",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.zone")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.zoneText")}
            </RetailText>
          </div>
          <Form.Item name="time_zone">
            <RetailSelect
              className={cm.select}
              options={[
                {
                  label: t("pages.admin.generalInfo.zoneIstanbul"),
                  value: "Europe/Istanbul",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.currency")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.currencyText")}
            </RetailText>
          </div>
          <Form.Item name="currency_code">
            <RetailSelect className={cm.select} options={options} />
          </Form.Item>
        </div>
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.email")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.emailText")}
            </RetailText>
          </div>
          <RetailFormInput
            isFocused={formFields.email !== ""}
            label={t("pages.admin.generalInfo.emailLabel")}
            name="email"
            rules={[
              {
                required: true,
                message: t("pages.admin.generalInfo.emailError"),
              },
            ]}
            className="floating"
          >
            <Input
              onChange={handleChange}
              className={cc(["floating", cm.input])}
              value={formFields.email}
            />
          </RetailFormInput>
        </div>

        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.generalInfo.seller")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.generalInfo.sellerText")}
            </RetailText>
          </div>
          <RetailFormInput
            isFocused={formFields.seller !== ""}
            label={t("pages.admin.generalInfo.sellerLabel")}
            name="seller"
            className="floating"
          >
            <Input
              onChange={handleChange}
              className={cc(["floating", cm.input])}
              value={formFields.seller}
            />
          </RetailFormInput>
        </div>
      </Form>
    </RetailSettingsLayout>
  );
};

export default GeneralInformationPage;
