import "moment/locale/tr";
import "moment/locale/en-gb";

import localeEN from "antd/es/date-picker/locale/en_US";
import localeTR from "antd/es/date-picker/locale/tr_TR";
import { TFunction } from "react-i18next";

import RetailNotification from "../components/Notification";
import RetailTableTag from "../components/Tag/RetailTableTag";
import RetailText from "../components/Typography/RetailText";

//Render tag according to data value coming from table row data.
export const renderTag = (value: "ACTIVE" | "PAUSED", t: TFunction) => {
  return (
    <RetailTableTag mode={value === "ACTIVE" ? "green" : "unfilledYellow"}>
      <RetailText weight="bold" family="poppins" size="xxxs">
        {value === "ACTIVE" ? t("common.table.on") : t("common.table.stopped")}
      </RetailText>
    </RetailTableTag>
  );
};

//Validate if input keypress is number, delete or backspace. Otherwise prevent key event.
export const validateNumber = (e: any, limited = false) => {
  if (e.which === 8 || e.which === 46 || e.which === 190) return;
  if (limited && e.target.value.length > 4) e.preventDefault();
  if (isNaN(parseInt(e.key))) {
    e.preventDefault();
  }
};

// Truncate text to given length
export const truncate = (text: string) => {
  if (!text.includes(">") || text.length < 25) return text;
  const newText = text.split(">");
  return `... > ${newText[newText.length - 1]}`;
};

export const datePickerLocale = (lang: string) => {
  if (lang === "en") return localeEN;
  else return localeTR;
};

//Divide given string between given character
export const getSubstring = (str: string, char1: string, char2: string) => {
  return str.substring(str.indexOf(char1) + 1, str.lastIndexOf(char2));
};

export const convertKeywordVolume = (volume: string, t: TFunction) => {
  if (volume) {
    switch (volume) {
      case "0-1K":
        return t("components.table.keywords.low");
      case "1K-5K":
        return t("components.table.keywords.medium");
      case "5K-10K":
        return t("components.table.keywords.high");
      case "10K+":
        return t("components.table.keywords.highest");
      default:
        break;
    }
  } else return "-";
};

export const numFormatter = (value: number | string, spendColumn = false) => {
  const lang = localStorage.getItem("retail_lang") || "tr";
  // Get decimal round value from marketplace config
  const decimalRound =
    (window as any).marketplaceConfig?.decimal_round || false;

  const formatter = () => {
    switch (lang) {
      case "tr":
        return "tr-TR";
      default:
        return "en-US";
    }
  };

  let num = (typeof value === "string" ? parseFloat(value) : value) || 0;

  const shouldBeRounded = decimalRound && spendColumn;

  if (shouldBeRounded) {
    num = Math.round(num);
  }

  return num.toLocaleString(formatter(), {
    maximumFractionDigits: shouldBeRounded ? 0 : 2,
  });
};

export const controlSelected = (id: number, arr: any[], iterableArr: any[]) =>
  arr
    .map((keyword) => keyword.id)
    .filter((keywordID) => iterableArr.map((key: any) => keywordID === key.id))
    .includes(id);

export const prefix = () => {
  const currency = (window as any).marketplaceConfig?.currency_code || "TRY";

  switch (currency) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "IQD":
      return "د. ع";
    case "TWD":
      return "NT$";
    case "HKD":
      return "HK$";
    case "KRW":
      return "₩";
    case "JPY":
      return "¥";
    default:
      return "₺";
  }
};

interface ISelectCurrency {
  amount: string | number;
}

export const SelectCurrency = ({ amount }: ISelectCurrency) => (
  <>
    {prefix()}
    {amount}
  </>
);

export const budgetErrorMessage = (value: number, t: TFunction) => {
  return Promise.reject(
    new Error(
      t("components.campaignForm.firstStep.budgetError", {
        value: `${value}${prefix()}`,
      })
    )
  );
};

export const settingsSuccessNotification = (t: TFunction) => {
  return RetailNotification.showNotification(
    "success",
    "",
    t("components.notification.settings_success")
  );
};

export const renderSettingsLinks = (
  link: string,
  el: {
    className: string;
    text: string;
  }
) => {
  if (link === "") return null;
  else
    return (
      <a href={link} className={el.className} target="_blank" rel="noreferrer">
        {el.text}
      </a>
    );
};
