import {
	Dropdown,
	Radio,
} from "antd";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import moment from "moment";
import {
	ChangeEvent,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import { InfoCircleFilled } from "@ant-design/icons";

import useApi from "../../../api";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import { ReactComponent as AdvertisersOutlined } from "../../../assets/icons/menu/advertisersOutlined.svg";
import { ReactComponent as NewAddCircleOutlined } from "../../../assets/icons/newAddCircleOutlined.svg";
import { ReactComponent as NotificationIconFilled } from "../../../assets/icons/notificationIconFilled.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import RetailNumberColumn from "../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../components/Column/RetailPercentageColumn";
import RetailStatusColumn from "../../../components/Column/RetailStatusColumn";
import RetailDrawer from "../../../components/Drawer/RetailDrawer";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailSuccessModal from "../../../components/Modal/RetailSuccessModal";
import RetailNotification from "../../../components/Notification";
import RetailSelect from "../../../components/Select/RetailSelect";
import RetailTable from "../../../components/Table/RetailTable";
import RetailFilterTag from "../../../components/Tag/RetailFilterTag";
import RetailTooltip from "../../../components/Tooltip/RetailTooltip";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import { advertiserValues } from "../../../utils/equalities";
import {
	addAdvertiserFilters,
	advertiserFilters,
} from "../../../utils/filters";
import { truncate } from "../../../utils/helpers";
import cm from "./style.module.scss";

const AdvertisersPage = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { options, handleScroll, setSearch } = useInfiniteScroll(
    "agencies",
    visible
  );

  const { api, baseURL } = useApi();

  const [drawerForm] = Form.useForm();

  const [drawerVisible, setDrawerVisible] = useState<{
    visible: boolean;
    mode: "create" | "update" | null;
  }>({
    visible: false,
    mode: null,
  });

  const [form] = Form.useForm();

  const [filterForm] = Form.useForm();

  const [addUserVisible, setAddUserVisible] = useState(false);

  const [successVisible, setSuccessVisible] = useState(false);

  const [invitedVisible, setInvitedVisible] = useState(false);

  const [createSuccessVisible, setCreateSuccessVisible] = useState(false);

  const [customAdvertiserFiltersVisible, setCustomAdvertiserFiltersVisible] =
    useState(false);

  const [category, setCategory] = useState<
    | [
        {
          key: "productRetailerTaxonomy";
          op: "ct";
          value: string;
        }
      ]
    | []
  >([]);

  const {
    options: categories,
    handleScroll: handleCategoryScroll,
    setSearch: handleCategorySearch,
  } = useInfiniteScroll("parent_categories", customAdvertiserFiltersVisible);

  const [customAdvertiserFilterValue, setCustomAdvertiserFilterValue] =
    useState("");
  const [customAdvertiserFilter, setCustomAdvertiserFilter] = useState("");
  const [customAdvertiserFilters, setCustomAdvertiserFilters] = useState<
    { id: string; key: string; op: string; value: string }[]
  >([]);

  const [advertiser, setAdvertiser] = useState<{
    id: number;
    name: string;
    account_id: number;
  }>();

  const [activeKey, setActiveKey] = useState("");

  const [email, setEmail] = useState("");

  const [drawerFromFields, setDrawerFormFields] = useState({
    name: "",
    agency: null,
    advertiser_id: null,
    email: "",
    type: "",
    filterError: false,
  });

  const open = async (records: any) => {
    const { name, type, email, agency_name, id, filters } = records;
    setDrawerFormFields({
      name,
      agency: agency_name,
      advertiser_id: id,
      email,
      type,
      filterError: false,
    });
    setCustomAdvertiserFilters(
      filters?.map((filter: any) => ({
        id: filter.value,
        key: filter.key,
        op: filter.op,
        value: filter.value,
      }))
    );
    setDrawerVisible({ visible: true, mode: "update" });
  };

  const close = () => setVisible(false);

  const updateValue = async (data: any) => {
    const config = data.isStatusUpdate
      ? { status: data.status === "ACTIVE" ? "PAUSED" : "ACTIVE" }
      : {
          agency_id: drawerFromFields.agency,
          name: drawerFromFields.name,
          email: drawerFromFields.email,
          type: drawerFromFields.type,
        };
    const base =
      drawerFromFields.type === "CUSTOM"
        ? "custom-advertiser/"
        : "advertisers/";
    const response = await api.patch(
      `${base}${data.isStatusUpdate ? data.advertiser_id : data.advertiser_id}`,
      config
    );
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const queryClient = useQueryClient();

  const changeValue = async (data: any, isStatusUpdate = false) => {
    try {
      const { advertiser_id } = data;
      const createdData = { advertiser_id, isStatusUpdate };
      await mutateAsync(createdData);
      closeDrawer();
      resetState();
      queryClient.refetchQueries("table");
    } catch (err: any) {
      console.error(err);
    }
  };

  const error = () => {
    RetailNotification.showNotification(
      "error",
      "",
      t("components.notification.statusError")
    );
  };

  const archive = async (records: any) => {
    const config = {
      status: activeKey === "ARCHIVED" ? "ACTIVE" : "ARCHIVED",
    };
    try {
      await api.patch(`advertisers/${records.id}`, config);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t(`components.notification.${config.status.toLowerCase()}Advertiser`)
      );
    } catch (error) {
      error();
    }
  };

  const closeSuccess = () => {
    close();
    queryClient.refetchQueries("table");
  };

  const closeError = () => {
    error();
    close();
  };

  const invite = async (records: any) => {
    if (baseURL() !== undefined) {
      try {
        await api.post("invite-user", {
          account_id: parseInt(records.account_id),
          email: records.email,
          role: "ADVERTISER",
        });
        setSuccessVisible(true);
        closeSuccess();
      } catch (err: any) {
        closeError();
      }
    }
  };

  const onArchive = async (records: any) => {
    records.status === "UNINVITED" && activeKey !== "ARCHIVED"
      ? invite(records)
      : archive(records);
  };

  const deleteAgency = async () => {
    const resetAgency = () =>
      setDrawerFormFields({ ...drawerFromFields, agency: null });
    if (drawerVisible.mode === "update") {
      try {
        await api.delete(
          `advertisers/${drawerFromFields?.advertiser_id}/agencies_connection`
        );
        resetAgency();
        RetailNotification.showNotification(
          "success",
          "",
          t("pages.admin.advertisers.deleteSuccess")
        );
      } catch (err: any) {
        resetAgency();
      }
    } else resetAgency();
  };

  const handleAddUserOpen = (records: any) => {
    setAddUserVisible(true);
    setAdvertiser({
      id: records.id,
      name: records.name,
      account_id: records.account_id,
    });
  };

  const tableConfig = {
    url: "advertisers",
    isRelation: false,
    filters: advertiserFilters(t),
    activeKey,
    to: open,
    onArchive: onArchive,
    onThirdOption: handleAddUserOpen,
  };

  const activeTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "advertiserStatus", op: "ne", value: "ARCHIVED" }],
  };

  const archivedTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "advertiserStatus", op: "eq", value: "ARCHIVED" }],
  };

  const columns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      disabled: true,
      render: (value: boolean, records: any) => (
        <RetailStatusColumn
          disabled={
            records.status === "INVITED" || records.status === "UNINVITED"
          }
          records={records}
          url={tableConfig.url}
        />
      ),
    },
    {
      title: t("common.table.agency"),
      dataIndex: "agency_name",
      render: (value: string) => (value ? value : "-"),
    },
    {
      title: t("common.table.name"),
      dataIndex: "name",
      disabled: true,
      render: (value: string, records: any) => (
        <RetailNameColumn
          to={`/campaigns`}
          value={`${value} (${records.id})`}
          state={{ id: records.id || 1, name: value || "No Name" }}
        />
      ),
    },
    {
      title: t("common.table.advertiserType"),
      dataIndex: "type",
      render: (value: string) => (
        <span className="flex">
          <RetailText family="poppins" size="xxxs" weight="medium">
            {t(`pages.admin.advertisers.${value.toLowerCase()}`)}
          </RetailText>
          <RetailTooltip
            title={t(`pages.admin.advertisers.${value.toLowerCase()}Text`)}
          >
            <InfoCircleFilled />
          </RetailTooltip>
        </span>
      ),
    },
    {
      title: t("common.table.seller"),
      dataIndex: "resource_id",
    },
    {
      title: t("common.table.lastEnter"),
      dataIndex: "last_login",
      render: (value: string) => (
        <span>{value === "" ? "-" : moment(value).format("L")}</span>
      ),
    },
    {
      title: t("common.table.status"),
      dataIndex: "status",
      key: 2,
      render: (value: string) => (
        <RetailText
          family="poppins"
          weight="medium"
          size="xxxs"
          style={{
            color: value === "ACTIVE" ? "var(--green-10)" : "var(--yellow-6)",
          }}
        >
          {t(`common.table.${value.toLowerCase()}`)}
        </RetailText>
      ),
    },
    {
      title: t("common.table.balance"),
      dataIndex: "balance",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.coupon"),
      dataIndex: "sum_coupons_amount",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.total_balance"),
      dataIndex: "total_balance",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.impression"),
      dataIndex: "impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    /* {
      title: t("common.table.viewable_impressions"),
      dataIndex: "viewable_impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    }, */
    {
      title: t("common.table.click"),
      dataIndex: "clicks",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.ctr"),
      dataIndex: "CTR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.spent"),
      dataIndex: "spend",
      render: (value: any) => (
        <RetailMoneyColumn value={value} spendColumn={true} />
      ),
    },
    //dönüşüm oranı
    {
      title: t("components.reportForm.metric.CVR"),
      dataIndex: "CVR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.quantity"),
      dataIndex: "sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.sold"),
      dataIndex: "sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.roas"),
      dataIndex: "ROAS",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
  ];

  const extraColumns = (t: any) => [
    {
      title: t("common.table.acos"),
      dataIndex: "acos",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.cpc"),
      dataIndex: "CPC",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.cpm"),
      dataIndex: "CPM",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumDirectSaleAmount"),
      dataIndex: "direct_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSaleAmount"),
      dataIndex: "indirect_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumDirectSale"),
      dataIndex: "direct_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSale"),
      dataIndex: "indirect_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
  ];

  const closeAdvertiser = () => {
    setAddUserVisible(false);
    setEmail("");
    form.resetFields();
  };

  const onOk = async () => {
    try {
      await api.post("invite-user", {
        account_id: advertiser?.account_id,
        email: email,
        role: "ADVERTISER",
      });
      closeAdvertiser();
      setInvitedVisible(true);
    } catch (err) {
      error();
    }
  };

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setEmail(target.value);

  const handleCustomAdvertiserFilterChange = ({
    target,
  }: ChangeEvent<HTMLInputElement>) =>
    setCustomAdvertiserFilterValue(target.value);

  const handleKey = (key: string) => setActiveKey(key);

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    setDrawerFormFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const clearFilters = () => setCustomAdvertiserFilters([]);

  const openCreateDrawer = () =>
    setDrawerVisible({ visible: true, mode: "create" });

  const resetState = () => {
    setDrawerFormFields({
      name: "",
      agency: null,
      advertiser_id: null,
      email: "",
      type: "",
      filterError: false,
    });
    setCustomAdvertiserFilters([]);
  };

  const closeDrawer = () => {
    setDrawerVisible({ visible: false, mode: null });
    resetState();
  };

  const closeSuccessModal = () => {
    resetState();
    setSuccessVisible(false);
  };

  const checkFilters = () => {
    if (
      customAdvertiserFilters.length === 0 &&
      drawerVisible.mode === "create"
    ) {
      setDrawerFormFields((prevState) => {
        return {
          ...prevState,
          filterError: true,
        };
      });
      return;
    }
  };

  const createCustomAdvertiser = async () => {
    if (customAdvertiserFilters.length === 0) checkFilters();
    else
      try {
        await api.post("custom-advertiser", {
          name: drawerFromFields.name,
          agency_id: drawerFromFields.agency,
          email: drawerFromFields.email,
          filters: customAdvertiserFilters.map((filter) => ({
            key: filter.key,
            op: filter.op,
            value: filter.value,
          })),
        });
        closeDrawer();
        setCreateSuccessVisible(true);
        queryClient.refetchQueries("table");
      } catch (err) {
        console.log(err);
      }
  };

  const onFinish = async () => {
    if (drawerVisible.mode === "create") createCustomAdvertiser();
    else changeValue(drawerFromFields);
  };

  const customAdvertiserFilterFormOnFinish = (values: any) => {
    setCustomAdvertiserFiltersVisible(false);
    setCustomAdvertiserFilters([
      ...customAdvertiserFilters,
      {
        id: values.key,
        key: values.key,
        op: values.op,
        value: values.value,
      },
    ]);
    setCustomAdvertiserFilterValue("");
  };
  const handleSelect = (value: string) =>
    setCategory([
      {
        key: "productRetailerTaxonomy",
        op: "ct",
        value: value,
      },
    ]);

  const handleClearCategory = () => setCategory([]);

  const clearFormStates = () => {
    handleClearCategory();
    setCustomAdvertiserFilterValue("");
    setCustomAdvertiserFilter("");
    filterForm.resetFields();
  };

  const resetFilterForm = () => {
    clearFormStates();
    setCustomAdvertiserFiltersVisible(false);
  };

  const handleVisibleChange = (value: React.SetStateAction<boolean>) => {
    setCustomAdvertiserFiltersVisible(value);
    clearFormStates();
  };

  const content = (
    <section className={cm.addAdvertiserFilterDropdown}>
      <div>
        <RetailTitle className={cm.filterTitle}>
          {t("pages.admin.advertisers.filterTitle")}
          <span onClick={resetFilterForm}>
            <CloseOutlined />
          </span>
        </RetailTitle>
      </div>
      <Form
        form={filterForm}
        requiredMark={false}
        autoComplete="off"
        onFinish={customAdvertiserFilterFormOnFinish}
        className={cm.filterForm}
      >
        <Form.Item
          name="key"
          rules={[
            {
              required: true,
              message: t("pages.admin.advertisers.filterKeyError"),
            },
          ]}
          className={cm.filterFormItem}
        >
          <RetailSelect
            placeholder={t("pages.admin.advertisers.keyLabel")}
            onChange={(value) => setCustomAdvertiserFilter(value)}
            options={addAdvertiserFilters(t).map((filter: any) => ({
              label: filter.name,
              value: filter.value,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="op"
          rules={[
            {
              required: true,
              message: t("pages.admin.advertisers.filterOpError"),
            },
          ]}
          className={cm.filterFormItem}
        >
          <RetailSelect
            placeholder={t("pages.admin.advertisers.opLabel")}
            options={advertiserValues.map((val: any) => ({
              label: t(`common.equality.${val.name}`),
              value: val.value,
            }))}
          />
        </Form.Item>

        {customAdvertiserFilter === "retailer_taxonomy" ? (
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
                message: t("pages.admin.advertisers.filterCategoryError"),
              },
            ]}
            className={cm.filterFormItem}
          >
            <RetailSelect
              placeholder={t("pages.admin.advertisers.categorySelectLabel")}
              options={categories?.map((opt: any) => ({
                value: opt.text,
                label: opt.text ? truncate(opt.text) : "",
              }))}
              value={category}
              showSearch
              onSelect={handleSelect}
              onPopupScroll={handleCategoryScroll}
              onSearch={handleCategorySearch}
              onClear={handleClearCategory}
              /* clearIcon={<CloseOutlined className={cm.clearSearchIcon} />} */
              virtual={false}
            />
          </Form.Item>
        ) : (
          <RetailFormInput
            isFocused={customAdvertiserFilterValue !== ""}
            label={t("pages.admin.advertisers.valueLabel")}
            name="value"
            className={cc(["floating", cm.input])}
            rules={[
              {
                required: true,
                message: t("pages.admin.advertisers.filterValueError"),
              },
            ]}
          >
            <Input
              className={cc(["floating", cm.input])}
              onChange={handleCustomAdvertiserFilterChange}
            />
          </RetailFormInput>
        )}

        <div className={cm.filterFormBtnContainer}>
          <RetailMainButton
            hasBackground={false}
            className={cm.cancelFilterBtn}
            onClick={resetFilterForm}
          >
            {t("common.cancel")}
          </RetailMainButton>
          <RetailMainButton
            hasBackground
            className={cm.addFilterBtn}
            htmlType="submit"
          >
            {t("common.add")}
          </RetailMainButton>
        </div>
      </Form>
    </section>
  );

  return (
    <RetailPageContainer>
      <Tabs onChange={handleKey} className="bordered-container no-margin">
        <Tabs.TabPane tab={t("common.listed")} key="ACTIVE">
          <RetailTable
            button={{
              title: t("pages.admin.advertisers.create"),
              onClick: openCreateDrawer,
            }}
            placeholder={t("pages.admin.advertisers.search")}
            columns={() => columns(t)}
            extraColumns={() => extraColumns(t)}
            tableConfig={activeTabConfig}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("common.archived")} key="ARCHIVED">
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.advertisers.search")}
            columns={() => columns(t)}
            extraColumns={() => extraColumns(t)}
            tableConfig={archivedTabConfig}
          />
        </Tabs.TabPane>
      </Tabs>

      <CampaignDetailsModal
        type="ADD_USER"
        subtitle={t("pages.admin.advertisers.userSubtext")}
        visible={addUserVisible}
        onOk={form.submit}
        onCancel={closeAdvertiser}
      >
        <div className={cm.advertiserBox}>
          <AdvertisersOutlined />
          <article>
            <RetailText className={cm.title} size="xs">
              {t("components.stepsNavbar.advertiser")}
            </RetailText>
            <RetailText className={cm.text} weight="bold">
              {advertiser?.name}
            </RetailText>
          </article>
        </div>
        <Form
          form={form}
          onFinish={onOk}
          requiredMark={false}
          autoComplete="off"
          className={cm.form}
        >
          <RetailFormInput
            isFocused={email !== ""}
            label={t("pages.admin.users.label")}
            name="email"
            help={t("pages.admin.advertisers.help")}
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
            className="floating"
          >
            <Input className="floating" onChange={handleChange} />
          </RetailFormInput>
        </Form>
      </CampaignDetailsModal>
      <RetailSuccessModal
        type="advertisers"
        visible={successVisible}
        setVisible={setSuccessVisible}
      />
      <RetailSuccessModal
        type="invited"
        visible={invitedVisible}
        setVisible={setInvitedVisible}
      />
      <RetailDrawer
        title={t(`pages.admin.advertisers.${drawerVisible.mode}`)}
        onOk={drawerForm.submit}
        onClose={closeDrawer}
        visible={drawerVisible.visible}
        mode={drawerVisible.mode}
      >
        {drawerVisible.mode === "create" ||
        drawerFromFields.type === "CUSTOM" ? (
          <section className={cm.drawerInfo}>
            <RetailText size="xxs" weight="medium">
              {t("pages.admin.advertisers.type")}
              <strong> {t("pages.admin.advertisers.custom")}</strong>
            </RetailText>
            <RetailText size="xxxs" weight="medium">
              {t("pages.admin.advertisers.customText")}
            </RetailText>
          </section>
        ) : (
          <>
            <RetailTitle level={5} className={cm.generalTitle}>
              {t("pages.admin.advertisers.generalTitle")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.subtext}>
              {t("pages.admin.advertisers.generalSubtext")}
            </RetailText>
          </>
        )}

        <Form
          form={drawerForm}
          requiredMark={false}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={checkFilters}
          initialValues={{
            name: drawerFromFields.name,
            email: drawerFromFields.email,
            agency: drawerFromFields.agency,
            type: drawerFromFields.type,
          }}
        >
          <RetailFormInput
            isFocused={drawerFromFields.name !== ""}
            label={t("pages.admin.advertisers.name")}
            name="name"
            rules={[{ required: true }]}
            className="floating"
            help={t("pages.admin.advertisers.nameHelp")}
          >
            <Input
              onChange={handleFieldChange}
              className="floating"
              name="name"
            />
          </RetailFormInput>

          <Form.Item
            name="agency"
            help={t("pages.admin.advertisers.agencyHelp")}
            className={cm.agencyContainer}
          >
            <RetailSelect
              options={options?.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))}
              optionFilterProp="label"
              placeholder={t("pages.admin.advertisers.modalPlaceholder")}
              showSearch
              onSearch={setSearch}
              onChange={(value) =>
                setDrawerFormFields({ ...drawerFromFields, agency: value })
              }
              value={drawerFromFields.agency}
              onPopupScroll={handleScroll}
            />
            {drawerFromFields.agency && (
              <CloseOutlined className={cm.closeIcon} onClick={deleteAgency} />
            )}
          </Form.Item>
          <RetailFormInput
            isFocused={drawerFromFields.email !== ""}
            label={t("pages.admin.advertisers.email")}
            name="email"
            rules={[{ required: true, type: "email" }]}
            className="floating"
            help={t("pages.admin.advertisers.emailHelp")}
          >
            <Input
              onChange={handleFieldChange}
              className="floating"
              name="email"
              value={drawerFromFields.email}
            />
          </RetailFormInput>
          {drawerVisible.mode === "create" && drawerFromFields.type === "" && (
            <div className="flex">
              <Dropdown
                overlay={content}
                placement="topLeft"
                overlayClassName={cm.filterDropdown}
                visible={customAdvertiserFiltersVisible}
                destroyPopupOnHide
                trigger={["click"]}
                onVisibleChange={handleVisibleChange}
              >
                <RetailMainButton
                  hasBackground={false}
                  className={cc(["flex", cm.filterBtn])}
                  icon={<NewAddCircleOutlined />}
                >
                  {t("components.selectFilter.add")}
                </RetailMainButton>
              </Dropdown>
              {customAdvertiserFilters.length > 0 && (
                <button className={cm.clearBtn} onClick={clearFilters}>
                  <CloseOutlined />
                  {t("common.clear")}
                </button>
              )}
            </div>
          )}

          {drawerVisible.mode === "update" &&
            drawerFromFields.type !== "CUSTOM" && (
              <>
                <RetailTitle level={5} className={cm.typeTitle}>
                  {t("pages.admin.advertisers.typeTitle")}
                </RetailTitle>
                <RetailText size="xxxs" family="poppins" className={cm.subtext}>
                  {t("pages.admin.advertisers.typeSubtext")}
                </RetailText>
                <Form.Item name="type" className={`flex ${cm.type}`}>
                  <Radio.Group onChange={handleFieldChange} name="type">
                    <Radio value="DEFAULT" className="form-radio">
                      <RetailTitle level={5} className={cm.radioTitle}>
                        {t("pages.admin.advertisers.default")}
                      </RetailTitle>
                      <RetailText className={cm.radioText} size="xxxs">
                        {t("pages.admin.advertisers.defaultText")}
                      </RetailText>
                    </Radio>
                    <Radio value="SHAREABLE" className="form-radio">
                      <RetailTitle level={5} className={cm.radioTitle}>
                        {t("pages.admin.advertisers.shareable")}
                      </RetailTitle>
                      <RetailText className={cm.radioText} size="xxxs">
                        {t("pages.admin.advertisers.shareableText")}
                      </RetailText>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
        </Form>

        {drawerFromFields.filterError && drawerVisible.mode === "create" && (
          <p
            className={`${
              customAdvertiserFilters.length === 0
                ? cm.filterError
                : cm.noFilterError
            }
              flex
              `}
          >
            <NotificationIconFilled />
            {t("pages.admin.advertisers.filterWarning")}
          </p>
        )}

        {customAdvertiserFilters?.length > 0 && (
          <section className={cm.tagContainer}>
            <div className="flex">
              <RetailText
                size="xxxs"
                family="poppins"
                weight="medium"
                className={cm.filterContainerTitle}
              >
                {t("pages.admin.advertisers.filters")}
              </RetailText>
            </div>
            <div>
              {customAdvertiserFilters?.map((tag) => (
                <RetailFilterTag
                  type="ADVERTISER"
                  tag={{
                    selectedFilter: tag.key,
                    equality: tag.op,
                    selectedValue: tag.value,
                  }}
                  key={tag.id}
                  closable={false}
                />
              ))}
            </div>
          </section>
        )}
      </RetailDrawer>
      <RetailSuccessModal
        type="create_advertiser"
        visible={createSuccessVisible}
        setVisible={setCreateSuccessVisible}
        onClick={closeSuccessModal}
      >
        <RetailText size="xs" weight="medium" className={cm.advertiserText}>
          {t("pages.admin.advertisers.advertiser")}
          <strong> {drawerFromFields.name}</strong>
        </RetailText>
        <RetailText size="xs" weight="medium" className={cm.successText}>
          {t("pages.admin.advertisers.successText")}
        </RetailText>
      </RetailSuccessModal>
    </RetailPageContainer>
  );
};

export default AdvertisersPage;
