import Row from "antd/lib/row";
import moment from "moment";
import { useTranslation } from "react-i18next";

import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailTable from "../../../components/Table/RetailTable";

const SynchronizationHistoryPage = () => {
  const { t } = useTranslation();

  const columns = (t: any) => [
    /* {
      title: t("common.table.code"),
      dataIndex: "code",
      render: (value: string) => value ? t(`pages.admin.sync.${value}`) : "-",
      disabled: true,
    }, */
    {
      title: t("common.table.row"),
      dataIndex: "row_count",
    },
    {
      title: t("common.table.message"),
      dataIndex: "message",
      render: (value: string, records: any) =>
        value ? t(`pages.admin.sync.${records.code}`) : "-",
      disabled: true,
    },
    {
      title: t("common.table.date"),
      dataIndex: "created_at",
      render: (value: string) =>
        value ? moment(value).format("DD/MM/YYYY HH:mm") : "-",
    },
    {
      title: t("common.table.syncType"),
      dataIndex: "sync_type",
      render: (value: string) =>
        value ? t(`pages.admin.sync.${value.toLowerCase()}`) : "-",
    },
    {
      title: t("common.table.fileName"),
      dataIndex: "file_name",
    },
  ];

  const tableConfig = {
    url: "sync_logs",
    isRelation: false,
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          columns={() => columns(t)}
          tableConfig={tableConfig}
          addBtnVisible={false}
          rowSelection={undefined}
        />
      </Row>
    </RetailPageContainer>
  );
};

export default SynchronizationHistoryPage;
