import Checkbox from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios, { AxiosError } from "axios";
import cc from "classcat";
import i18n from "i18next";
import {
	useEffect,
	useState,
} from "react";
import {
	TFunction,
	useTranslation,
} from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as AddUserOutlined } from "../../../assets/icons/addUserOutlined.svg";
import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkSuccessOutlined.svg";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import { ReactComponent as SupportOutlined } from "../../../assets/icons/supportOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import RetailNotification from "../../../components/Notification";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import cm from "./style.module.scss";

export interface ConditionProps {
  i18n: typeof i18n;
}

const Terms = ({ i18n }: ConditionProps) => {
  const terms = (window as any).marketplaceConfig?.terms_of_use_url || "#";

  if (i18n.language === "tr") {
    return (
      <>
        <a
          href={terms}
          target="_blank"
          rel="noreferrer"
          className={cm.underline}
        >
          KULLANIM KOŞUL VE ŞARTLARI
        </a>
        ’nı kabul ediyor ve onaylıyorum.
      </>
    );
  }

  return (
    <>
      I accept and approve the
      <a
        href={terms}
        target="_blank"
        rel="noreferrer"
        className={cc([cm.underline, cm.underlineEnglish])}
      >
        TERMS AND CONDITIONS OF USE.
      </a>
    </>
  );
};

const Privacy = ({ i18n }: ConditionProps) => {
  const privacy = (window as any).marketplaceConfig?.privacy_policy_url || "#";

  const name = (window as any).marketplaceConfig.name || "acme";

  if (i18n.language === "tr") {
    return (
      <>
        <a
          href={privacy}
          target="_blank"
          rel="noreferrer"
          className={cm.underline}
        >
          {name?.toUpperCase()} REKLAM HİZMETLERİ KULLANICILARI AYDINLATMA METNİ
        </a>
        kapsamında; {window.location.hostname} internet sitesinin reklam
        hizmetlerinin sağlanması ve sitenin kullanımı sırasında tarafıma ait
        kişisel verilerimin, Şirketin iş birliği yaptığı kurum ve kuruluşlarla
        ve internet sitesi alt yapısı için destek almakta olduğu yurt içi ve
        yurt dışındaki iş ortaklarıyla paylaşılmasına ve verilerimin yurtdışına
        aktarılmasına açıkça onay veriyorum.
      </>
    );
  }

  return (
    <>
      Within the scope of the
      <a
        href={privacy}
        target="_blank"
        rel="noreferrer"
        className={cc([cm.underline, cm.underlineEnglish])}
      >
        {name?.toUpperCase()} ADVERTISING SERVICES USERS INFORMATION TEXT;
      </a>
      I expressly consent to the sharing of my personal data with the
      institutions and organizations that the Company cooperates with, and with
      domestic and foreign business partners that the company receives support
      for the infrastructure of the website, and the transfer of my data abroad,
      during the provision of advertising services and the use of the website of{" "}
      {window.location.hostname}.
    </>
  );
};

export interface AfterSignUpProps {
  success: boolean | null;
  t: TFunction;
  emailFromURL: string;
}

const AfterSignUp = ({ success, t, emailFromURL }: AfterSignUpProps) => {
  const mode = success ? "success" : "error";

  const supportEmail = (window as any).marketplaceConfig.support_email || null;

  const icon = success ? <CheckOutlined /> : <CloseOutlined />;

  const text = () => {
    switch (mode) {
      case "success":
        return t("pages.auth.signUp.successText", { value: emailFromURL });
      case "error":
        return supportEmail
          ? t("pages.auth.signUp.errorText", { value: supportEmail })
          : t("pages.auth.signUp.errorTextNoEmail");

      default:
        break;
    }
  };
  return (
    <section className={cc(["flex", cm.afterSignupWrapper])}>
      <article className="flex">
        <span className={cc(["flex", cm.icon, cm[mode]])}>{icon}</span>
        <RetailTitle level={4} className={cc(["flex", cm.afterSignupTitle])}>
          {t(t(`pages.auth.signUp.${mode}`))}
        </RetailTitle>
        <RetailText weight="medium" size="xs" className={cm.afterSignupText}>
          {text()}
        </RetailText>
        <RetailMainButton hasBackground className={cc([cm.btn, cm[mode]])}>
          <Link to="/login">{t(`pages.auth.signUp.${mode}Btn`)}</Link>
        </RetailMainButton>
        {success && (
          <Link to="/" className={cc(["flex", cm.link])}>
            <SupportOutlined />
            <RetailText size="xs" weight="medium" className={cm.underline}>
              {t("pages.auth.signUp.help")}
            </RetailText>
          </Link>
        )}
      </article>
    </section>
  );
};

const SignUpPage = () => {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const [success, setSuccess] = useState<boolean | null>(null);

  const [disabled, setDisabled] = useState(true);

  const access_token = localStorage.getItem("access_token") || "";

  const emailFromURL = localStorage.getItem("email_from_url") || "";

  const handleError = (err: AxiosError) => {
    if (access_token === "" || emailFromURL === "") setSuccess(false);
    else if (
      err.request.responseText.includes("user with that email already exists")
    ) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.auth.signUp.userExistErr")
      );
    } else
      RetailNotification.showNotification(
        "error",
        t("pages.auth.signUp.err"),
        t("pages.auth.signUp.warning")
      );
  };

  const acceptInvite = async (values: {
    access_token: string;
    password: string;
  }) => {
    try {
      await axios.post("api/users/accept-invite", {
        access_token: values.access_token,
        password: values.password,
        password_verify: values.password,
      });
      setSuccess(true);
    } catch (err) {
      handleError(err);
    }
  };

  const onFinish = async () =>
    acceptInvite({ access_token, password: form.getFieldValue("password") });

  const onChange = (checkedValues: CheckboxValueType[]) => {
    checkedValues.length < 2 ? setDisabled(true) : setDisabled(false);
  };

  useEffect(() => {
    if (access_token === "" || emailFromURL === "") setSuccess(false);
  }, [access_token, emailFromURL]);

  return (
    <RetailAuthLayout>
      {success !== null ? (
        <AfterSignUp success={success} t={t} emailFromURL={emailFromURL} />
      ) : (
        <div className={cm.form}>
          <section className={cc(["flex", cm.textContainer])}>
            <div className={cc(["flex", cm.iconContainer])}>
              <AddUserOutlined />
            </div>
            <article>
              <h1>{t("pages.auth.signUp.title")}</h1>
              <p>{t("pages.auth.signUp.text", { value: emailFromURL })}</p>
            </article>
          </section>
          <Form form={form} autoComplete="off" onFinish={onFinish}>
            <Form.Item
              label=""
              name="password"
              className={cm.formItem}
              rules={[
                { required: true, message: t("pages.auth.signUp.passwordErr") },
              ]}
            >
              <Input.Password placeholder={t("pages.auth.signUp.password")} />
            </Form.Item>
            <RetailText
              size="xxxs"
              weight="medium"
              family="poppins"
              className={cm.textSecond}
            >
              {t("pages.auth.signUp.warning")}
            </RetailText>
            <div className={cm.subContainer}>
              <Form.Item
                label=""
                name="privacy"
                valuePropName="privacy"
                className={cm.privacy}
                rules={[
                  {
                    required: true,
                    message: t("pages.auth.signUp.privacyErr"),
                  },
                ]}
              >
                <Checkbox.Group onChange={onChange}>
                  <Checkbox value="terms" className={cm.checkbox}>
                    <Terms i18n={i18n} />
                  </Checkbox>
                  <Checkbox value="users" className={cm.checkbox}>
                    <Privacy i18n={i18n} />
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <Form.Item className={cm.btnItem}>
              <RetailMainButton
                hasBackground
                htmlType="submit"
                className={cm.btn}
                disabled={disabled}
              >
                {t("pages.auth.signUp.title")}
              </RetailMainButton>
            </Form.Item>
            <div className={cm.login}>
              <RetailText size="xs" weight="medium" className={cm.text}>
                {t("pages.auth.signUp.already")}
              </RetailText>
              <Link to="/login">{t("pages.auth.signUp.successBtn")}</Link>
            </div>
          </Form>
        </div>
      )}
    </RetailAuthLayout>
  );
};

export default SignUpPage;
