import "../src/language";
import "./styles/globals.scss";
import "intro.js/introjs.css";

import { useEffect } from "react";
import { TrackJS } from "trackjs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import RetailLayout from "./components/Layout/RetailLayout";
import AuthProvider from "./context/AuthProvider";
import CategoryProvider from "./context/CategoryProvider";
import CreativeProvider from "./context/CreativeProvider";
import { FilterProvider } from "./context/FilterProvider";
import KeywordProvider from "./context/KeywordProvider";
import ProductProvider from "./context/ProductProvider";

const compose = (providers: any) =>
  providers.reduce((Prev: any, Curr: any) => ({ children }: any) => (
    <Prev>
      <Curr>{children}</Curr>
    </Prev>
  ));

const Provider = compose([
  AuthProvider,
  FilterProvider,
  ProductProvider,
  KeywordProvider,
  CategoryProvider,
  CreativeProvider,
]);

const config = (window as any)?.marketplaceConfig;
if (config?.colors) {
  for (const [key, value] of Object.entries(config.colors)) {
    document.documentElement.style.setProperty(key, value as any);
  }
}

function App() {
  //Theme and head elements changes according to marketplaceConfig
  useEffect(() => {
    //Title
    document.title = config?.title || "GoWit - RMA Platform";
    //Description
    document
      .querySelector('meta[name="description"]')!
      .setAttribute(
        "content",
        config?.description || "Multichannel Advertising Platform"
      );
    //Favicon
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = config?.favicon || "";
    //Theme
  }, []);

  TrackJS.install({
    token: "217095ddfe0240feaada9998c95c8547",
  });

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdFDFgjAAAAAEEQxcJmCJZ0Q3kPTsv9QBzTROW8"
      language="tr"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "body", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <Provider>
        <RetailLayout />
      </Provider>
    </GoogleReCaptchaProvider>
  );
}

export default App;