import cc from "classcat";
import { useContext, useEffect, useRef } from "react";

import { Category, CategoryContext } from "../../../context/CategoryProvider";
import RetailText from "../../Typography/RetailText";
import cm from "../style.module.scss";

export interface CategorySelectProps {
  alreadySelectedData?: any;
  setCurrentCategory?: (category: any) => void;
  categoryData: any;
}

const CategorySelect = ({
  alreadySelectedData,
  setCurrentCategory,
  categoryData,
}: CategorySelectProps) => {
  const ref = useRef<any>(null);

  const {
    categories,
    setCategories,
    setNegativeCategories,
    negativeCategories,
  } = useContext(CategoryContext) as Category;

  const ids = alreadySelectedData?.map(
    (data: any) => data.categoryID || data.category_id
  );

  const controlSelectedDetails = (id: number) => ids?.includes(id) ?? false;

  const controlSelected = (id: number) => {
    const selectedCategories = [...categories, ...negativeCategories]
      .map((category) => category?.id)
      .filter((categoryID) =>
        categoryData.options.some((option: any) => categoryID === option.id)
      );
    return selectedCategories.includes(id);
  };

  //Close category select when click outside
  useEffect(() => {
    if (ref.current !== null) {
      let handler = (event: any) => {
        if (!ref.current.contains(event.target)) categoryData.close();
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }
  });

  const handleClick = (item: any) => {
    switch (categoryData.type) {
      case "CATEGORIES":
        return setCategories((curr: any) => [...curr, item]);
      case "NEGATIVE_CATEGORIES":
        return setNegativeCategories((curr: any) => [...curr, item]);
      case "PARENT_CATEGORIES":
        return setCurrentCategory!(item);
      default:
        break;
    }
  };

  if (categoryData.options.length > 0) {
    return (
      <div className={cc([cm.container, cm.fullWidth])}>
        <section
          data-cy="category-container"
          className={cc([cm.categoryContainer, cm.fullWidth])}
          ref={ref}
          onScroll={categoryData.handleScroll}
        >
          {categoryData.options?.map((item: any) => {
            const isAlreadySelected =
              alreadySelectedData && controlSelectedDetails(item.id);
            const isCurrentlySelected = controlSelected(item.id);
            const isDisabled = isAlreadySelected || isCurrentlySelected;

            return (
              <RetailText
                family="poppins"
                size="xs"
                key={item.id}
                className={isDisabled ? cm.disabled : ""}
                onClick={() => handleClick(item)}
              >
                {item.text}
              </RetailText>
            );
          })}
        </section>
      </div>
    );
  } else return null;
};

export default CategorySelect;
