import Menu from "antd/lib/menu";
import SubMenu from "antd/lib/menu/SubMenu";
import cc from "classcat";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Link,
	useLocation,
	useNavigate,
} from "react-router-dom";

import cm from "../style.module.scss";
import { retailAdminRouteObject } from "./RetailAdminRouteObject";

const RetailAdminSider = () => {
  const { t } = useTranslation();

  const [selectedKey, setSelectedKey] = useState<string>("/admin/campaigns");

  const location = useLocation();

  const navigate = useNavigate();

  const submenuItemsCheck = (route: any) =>
    route.children
      ?.map((child: any) => child.link)
      .toString()!
      .includes(selectedKey);

  useEffect(() => {
    if (location.pathname !== selectedKey) {
      setSelectedKey(
        location.pathname.includes("campaign")
          ? "/admin/campaigns"
          : location.pathname
      );
    }
    if (location.pathname.includes("/report/")) {
      setSelectedKey("/admin/reports");
    }
  }, [location, selectedKey]);

  const regex = /\d/.test(decodeURI(location.pathname));

  const handleDetailsPage = () => {
    navigate(selectedKey);
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      selectedKeys={[selectedKey]}
      theme="light"
      className={cm.menu}
    >
      {retailAdminRouteObject.map((route) =>
        route.sub ? (
          <SubMenu
            title={t(`sider.admin.${route.key}`)}
            key={route.key}
            className={cc([cm.subMenu, cm.admin])}
            icon={
              submenuItemsCheck(route) ? route.iconFilled : route.iconOutlined
            }
          >
            {route.children?.map((child) => (
              <Menu.Item
                key={child.link}
                className={cc([
                  cm.listItem,
                  selectedKey === child.link ? cm.selected : "",
                ])}
              >
                <Link
                  to={child.link}
                  state={{
                    breadcrumb: route.breadcrumb,
                    breadcrumbType: route.key,
                    value: t(`sider.admin.${child.key}`),
                  }}
                >
                  {t(`sider.admin.${child.key}`)}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item
            key={route.link}
            className={cc([
              cm.listItem,
              cm.admin,
              selectedKey === route.link ? cm.selected : "",
            ])}
            onClick={
              regex && route.link === selectedKey
                ? handleDetailsPage
                : undefined
            }
          >
            <Link to={route.link!}>
              {selectedKey === route.link
                ? route.iconFilled
                : route.iconOutlined}
              <span>{t(`sider.admin.${route.key}`)}</span>
            </Link>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

export default RetailAdminSider;
