import Col from "antd/lib/col";
import Row from "antd/lib/row";
import cc from "classcat";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ReactComponent as BidOutlined } from "../../../../assets/icons/bidOutlined.svg";
import { ReactComponent as DateOutlined } from "../../../../assets/icons/dateOutlined.svg";
import { ReactComponent as EditOutlined } from "../../../../assets/icons/editWhiteOutlined.svg";
import { ReactComponent as WalletOutlined } from "../../../../assets/icons/walletOutlined.svg";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import { prefix } from "../../../../utils/helpers";
import CampaignDetailsHeaderSwitch from "./CampaignDetailsHeaderSwitch";
import CampaignUpdateModal from "./CampaignDetailsModal";
import cm from "./style.module.scss";

export interface CampaignDetailsHeaderProps {
  data: any;
  chosenMinBid: () => any;
  settings: any;
}

const CampaignDetailsHeader = ({
  data,
  chosenMinBid,
  settings,
}: CampaignDetailsHeaderProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const [visible, setVisible] = useState(false);

  const [activeKey, setActiveKey] = useState("");

  const open = (key: string) => {
    setVisible(true);
    setActiveKey(key);
  };

  const contextMenuRouting = location.state as any;

  useEffect(
    () =>
      contextMenuRouting !== null && contextMenuRouting?.data === true
        ? open("")
        : undefined,
    [contextMenuRouting]
  );

  const cancel = () => setVisible(false);

  const acos = data && data.bid_type === "MAX_ACOS";

  const editableBid =
    data?.bid_type === "FIXED" && data?.targeting_type === "AUTO";

  return (
    <>
      {data !== undefined && (
        <header
          className={cc([cm.header, data.status === "PAUSED" ? cm.paused : ""])}
        >
          <RetailTitle level={2} className={cm.title}>
            {data.name}
            <span className={cm.iconContainer} onClick={() => open("name")}>
              <EditOutlined />
            </span>
          </RetailTitle>
          <Row className={cm.bottomBar}>
            <CampaignDetailsHeaderSwitch id={data.id} initial={data.status} />
            <Col>
              <RetailText className={cm.label} size="xs">
                <DateOutlined style={{ marginRight: "0.75rem" }} />
                {t("pages.acc.campaignDetails.date")}
              </RetailText>
              <RetailText className={cm.label} size="xs">
                {data.start_date}
              </RetailText>
              <RetailText size="xs">-</RetailText>
              <RetailText className={cm.label} size="xs">
                {data.end_date
                  ? data.end_date
                  : t("components.endDatePicker.button")}
                <span
                  className={cm.iconContainer}
                  onClick={() => open("end_date")}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            </Col>
            <RetailText className={cc(["flex", cm.label])} size="xs">
              <span className="flex">
                <WalletOutlined />
                {`${t("common.table.budget")}:`}
              </span>
              <RetailText size="xs">
                {data.budget_type
                  ? t(
                      `pages.acc.campaignDetails.${data.budget_type.toLowerCase()}`
                    )
                  : ""}
              </RetailText>

              <RetailMoneyColumn
                value={data?.daily_budget || data?.total_budget}
              />
              {data.budget_type === "FLEXIBLE" && (
                <>
                  <RetailText
                    family="poppins"
                    size="xs"
                    className={cm.flexibleBudgetText}
                  >
                    -
                  </RetailText>
                  <RetailText
                    family="poppins"
                    size="xs"
                    className={cm.flexibleBudgetText}
                  >
                    {t("pages.acc.campaigns.table.budgetLimit", {
                      value: `${prefix()} ${data?.total_budget}`,
                    })}
                  </RetailText>
                </>
              )}
              <span
                className={cm.iconContainer}
                onClick={() => {
                  open(
                    data?.budget_type === "TOTAL"
                      ? "total_budget"
                      : "daily_budget"
                  );
                }}
              >
                <EditOutlined />
              </span>
            </RetailText>
            <RetailText className={cm.label} size="xs">
              <span className="flex">
                <BidOutlined />
                {t("pages.acc.campaignDetails.bid")}
              </span>
              <RetailText
                className={cc([data.fixed_bid ? "flex" : "", cm.bidText])}
                size="xs"
              >
                {acos
                  ? `${data.max_acos}${t("pages.acc.campaignDetails.maxAcos")}`
                  : t(
                      `pages.acc.campaignDetails.${
                        data && data?.bid_type?.toLowerCase()
                      }`
                    )}
                {data.fixed_bid ? (
                  <RetailMoneyColumn value={data.fixed_bid} />
                ) : null}
                <span
                  className={cc([
                    cm.iconContainer,
                    !editableBid ? cm.uneditable : "",
                  ])}
                  onClick={() => open("bid")}
                >
                  <EditOutlined />
                </span>
              </RetailText>
            </RetailText>
          </Row>
          <CampaignUpdateModal
            visible={visible}
            activeKey={activeKey}
            data={data}
            cancel={cancel}
            chosenMinBid={chosenMinBid}
            settings={settings}
          />
        </header>
      )}
    </>
  );
};

export default CampaignDetailsHeader;
