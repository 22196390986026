import notification from "antd/lib/notification";

import { ReactComponent as CheckCircleFilled } from "../../assets/icons/checkCircleFilled.svg";
import { ReactComponent as CloseOutlined } from "../../assets/icons/closeNotificationOutlined.svg";
import { ReactComponent as NotificationIconFilled } from "../../assets/icons/notificationIconFilled.svg";

type NotificationType = "success" | "error" | "warning" | "info";

export default class RetailNotification {
  title: string;
  desc: string;
  toastType: NotificationType;
  duration?: number;

  constructor(type: NotificationType, title: string, desc: string) {
    this.toastType = type;
    this.title = title;
    this.desc = desc;
  }
  openNotification = () => {
    notification[this.toastType]({
      message: this.title,
      description: this.desc,
      placement: "bottomRight",
      bottom: 54,
    });
  };

  static showNotification(
    type: NotificationType,
    message: string,
    description: string
  ) {
    notification[type]({
      message,
      description,
      placement: "bottomRight",
      bottom: 54,
      closeIcon: <CloseOutlined />,
      icon:
        type === "error" ? <NotificationIconFilled /> : <CheckCircleFilled />,
      style: {
        color:
          message !== ""
            ? "var(--secondary-5)"
            : type === "error"
            ? "var(--red-5)"
            : "var(--green-10)",
      },
    });
  }
}
