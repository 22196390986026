import Sider from "antd/lib/layout/Sider";
import cc from "classcat";
import React from "react";

import RetailAdminSider from "./RetailAdminSider";
import RetailUserSider from "./RetailUserSider";
import cm from "./style.module.scss";

export interface AdpSiderProps {
  adminSider: boolean;
}

const RetailSider: React.FC<AdpSiderProps> = ({ adminSider }) => (
	<Sider className={cc([cm.sider, adminSider ? cm.adminSider : cm.userSider])} width={adminSider ? 230 : 80}>
		{
			adminSider ?
				<RetailAdminSider /> :
				<RetailUserSider />
		}
	</Sider>
);

export default RetailSider;
