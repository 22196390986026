import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import useApi from "../api";
import { SettingTypes } from "../utils/types";

const useSettings = (type: SettingTypes, enabled = true) => {
  const { api, baseURL } = useApi();

  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(false);

  const url = () => {
    switch (type) {
      case "MARKETPLACE":
        return "settings";
      case "ADVERTISER":
        return "marketplace-settings";
      case "INTEGRATION":
        return "integration-information";
      case "PAYMENTS":
        return "marketplace-payments-info";
    }
  };

  useEffect(() => {
    if (baseURL() !== undefined) setIsEnabled(enabled);
  }, [baseURL, enabled]);

  const { isLoading, error, data } = useQuery(
    ["settings", { type }],
    () => {
      const data = api.get(url());
      return data;
    },
    {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchIntervalInBackground: true,
      enabled: isEnabled,
    }
  );
  return {
    isLoading,
    data,
    error,
  };
};

export default useSettings;
