/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from "antd";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import List from "antd/lib/list";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import cc from "classcat";
import moment, { Moment } from "moment";
import {
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";

import useApi from "../../../api";
import { ReactComponent as AddCircleOutlined } from "../../../assets/icons/addCircleOutlined.svg";
import { ReactComponent as CloseCircleOutlined } from "../../../assets/icons/closeCircleOutlined.svg";
import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import FilterContext from "../../../context/FilterProvider";
import useTableFetch from "../../../hooks/useTableFetch";
import {
	createReportFilters,
	createReportFiltersAdmin,
} from "../../../utils/filters";
import { datePickerLocale } from "../../../utils/helpers";
import RetailSearchBar from "../../Bar/RetailSearchBar";
import RetailMainButton from "../../Button/RetailMainButton";
import Empty from "../../Empty";
import RetailStepsNavbar, { RetailStepsNavbarHandles } from "../../Layout/RetailNavbar/RetailStepsNavbar";
import RetailSuccessModal from "../../Modal/RetailSuccessModal";
import RetailNotification from "../../Notification";
import RetailFilterPopover from "../../Popover/RetailFilterPopover";
import RetailSelect from "../../Select/RetailSelect";
import RetailFilterTag from "../../Tag/RetailFilterTag";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import RetailDatePicker from "../RetailDatePicker";
import RetailEndDatePicker from "../RetailEndDatePicker";
import RetailFormInfo from "../RetailFormInfo";
import RetailFormInput from "../RetailFormInput";
import RetailFormRow from "../RetailFormRow";
import {
	adminPredefinedTypes,
	fileFormats,
	frequency,
	monthly,
	predefinedTypes,
} from "./RetailReportsSelectValues";
import cm from "./style.module.scss";

export interface RetailReportsFormProps {
  type: "create" | "edit";
}

const RetailReportsForm = ({ type }: RetailReportsFormProps) => {
  const { t, i18n } = useTranslation();

  const { api, adminInGeneral } = useApi();

  const { data: advertiserInfo } = useQuery(
    "selected_widgets",
    () => {
      const data = api.get("");
      return data;
    },
    {
      retry: false,
      refetchOnWindowFocus: true,
      enabled: !adminInGeneral,
    }
  );

  const advertiserStateForLinks = advertiserInfo?.data;

  const location = useLocation();

  const { local } = useContext(AuthContext) as Auth;

  const [visible, setVisible] = useState(false);

  const [records, setRecords] = useState<any>([]);

  const [metrics, setMetrics] = useState<any>({
    allMetrics: [],
    selectedMetrics: [],
  });

  const [dimensions, setDimensions] = useState<any>([]);

  const [errorStates, setErrorStates] = useState({
    dimensionError: false,
  });

  const handleErrorChange = (value: any, name: string) =>
    setErrorStates((prev) => ({ ...prev, [name]: value }));

  const stepInformation = [
    {
      title: t("components.reportForm.title"),
      sub: t("common.now"),
    },
  ];

  const { data } = useTableFetch("query", false);

  const isMetricExist = (key: string) =>
    i18n.exists(`components.reportForm.metric.${key}`);

  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`components.reportForm.metric.${key}`)
      : t(`marketplaceDependentTable.${key?.toLowerCase()}`);

  const catalogFetch = async () => {
    try {
      const response = await api.post("catalog", { page: 1, pageSize: 10 });
      setMetrics((prev: any) => ({
        ...prev,
        allMetrics: response.data.report_metrics.map((val: any) => ({
          label: renderMetric(val.Value),
          value: val.Value,
        })),
      }));
      setDimensions(
        response.data.report_dimensions.map((val: any) => ({
          Value: val.Value,
          id: val.Value,
        }))
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    catalogFetch();
  }, []);

  const dateFormat = "DD/MM/YYYY";
  const disabledDate = (date: Moment) =>
    date && date < moment().startOf("day").add(1, "days");

  const [requiredFields, setRequiredFields] = useState({
    name: "",
    start_date:
      type === "create"
        ? ""
        : data?.data.report_start_date !== "0001-01-01"
        ? moment(data?.data.start_date)
        : "",
    end_date:
      type === "create"
        ? null
        : data?.data.report_end_date !== "0001-01-01"
        ? moment(data?.data.report_end_date)
        : null,
    reportType: "",
    predefined_type: null,
    is_basic_selected: true,
    sent_type: "STATIC",
    exportFormat: type === "create" ? "" : data?.data.report_export_format,
    email: type === "create" ? "" : data?.data.reportEmails,
    deliveryFrequency:
      type === "create" ? "DAILY" : data?.data.report_delivery_frequency,
    monthlyChoice: "FIRST_DAY",
  });

  const check = () => {
    !requiredFields.is_basic_selected &&
      selectedItems.length === 0 &&
      handleErrorChange(true, "dimensionError");
  };

  const [schedulingStartDate, setSchedulingStartDate] = useState<any>(
    moment().startOf("day").add(1, "days").format("YYYY-MM-DD")
  );

  const handleChange = (value: any, name: string) =>
    setRequiredFields((prev) => ({ ...prev, [name]: value }));

  const { tags, setTags } = useContext(FilterContext);

  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState<
    { id: string; Name: string; Value: string }[]
  >([]);

  const handleClick = (item: any) => {
    if (
      selectedItems.map((selected) => selected.Value).indexOf(item.Value) ===
        -1 &&
      selectedItems.length < 3
    ) {
      setSelectedItems((items) => [...items, item]);
    }
  };

  const handleDelete = (id: string) => {
    setSelectedItems(selectedItems.filter((item) => id !== item.id));
  };

  const handleClean = () => setSelectedItems([]);

  useEffect(() => {
    if (type === "create") {
      form.setFieldsValue({
        type: "STATIC",
        report_type: "ready",
      });
    }
    if (type === "edit") {
      form.setFieldsValue({
        type: data?.data.report_type,
        name: data?.data.report_name,
        report_type: data?.data.report_predefined_type ? "ready" : "special",
        predefined_type: data?.data.report_predefined_type,
        start_date:
          data?.data.report_start_date !== "0001-01-01"
            ? moment(data?.data.report_start_date)
            : null,
        deliveryFrequency:
          data?.data.report_type === "SCHEDULED" &&
          data?.data.report_scheduling_start_date
            ? moment(data?.data.report_scheduling_start_date)
            : moment().add(1, "days"),
        end_date:
          data?.data.report_end_date !== "0001-01-01"
            ? moment(data?.data.report_end_date)
            : "",
        mail:
          data?.data.report_type === "SCHEDULED"
            ? data?.data.report_emails[0]
            : "",
        metrics:
          data?.data.report_metrics &&
          data?.data.report_metrics.map((val: string) => val),
      });
      setRequiredFields({
        ...requiredFields,
        name: data?.data.report_name,
        sent_type: data?.data.report_type,
        start_date:
          data?.data.report_start_date !== "0001-01-01"
            ? moment(data?.data.report_start_date)
            : "",
        end_date:
          data?.data.report_end_date !== "0001-01-01"
            ? moment(data?.data.report_end_date)
            : null,
        predefined_type: data?.data.report_predefined_type,
        is_basic_selected: data?.data.report_predefined_type ? true : false,
        exportFormat:
          data?.data.report_type === "SCHEDULED" &&
          data?.data.report_export_format,
        email:
          data?.data.report_type === "SCHEDULED" && data.data.report_emails[0],
      });
      setSelectedItems(
        data
          ? data?.data.report_dimensions !== null &&
              data?.data.report_dimensions.map((val: string) => ({
                Value: val,
                id: val,
              }))
          : []
      );
      setTags(
        data && data.data.report_filters
          ? data.data.report_filters.map((filter: any) => ({
              selectedFilter: filter.key,
              equality: filter.op,
              selectedValue: filter.value,
            }))
          : []
      );
    }
  }, [data]);

  const report = (values: any) => ({
    name: values.name,
    type: values.type,
    status: "ACTIVE",
    start_date:
      values.type === "STATIC" && values.start_date !== undefined
        ? `${values.start_date.format("YYYY-MM-DD")}T00:00:00.000Z`
        : null,
    end_date:
      values.type === "STATIC" && values.end_date !== undefined
        ? `${values.end_date.format("YYYY-MM-DD")}T00:00:00.000Z`
        : null,
    delivery_frequency:
      values.type === "SCHEDULED"
        ? requiredFields.deliveryFrequency === undefined
          ? "DAILY"
          : requiredFields.deliveryFrequency
        : null,
    scheduling_start_date:
      values.type === "SCHEDULED" ? schedulingStartDate : null,
    export_format:
      values.type === "SCHEDULED"
        ? !requiredFields.exportFormat
          ? "CSV"
          : requiredFields.exportFormat
        : null,
    emails: values.type === "SCHEDULED" ? [requiredFields.email] : null,
    report_predefined_type: requiredFields.predefined_type,
    dimensions: requiredFields.is_basic_selected
      ? null
      : selectedItems.length > 0
      ? selectedItems.map((item) => item.Value)
      : null,
    metrics: requiredFields.is_basic_selected
      ? null
      : values.metrics.map((item: any) => item),
    filters: requiredFields.is_basic_selected
      ? null
      : tags.map((tag) => {
          return {
            key: tag.selectedFilter,
            op: tag.equality,
            value: tag.selectedValue,
          };
        }),
  });

  const resetGlobalStates = () => setTags([]);

  useEffect(() => {
    requiredFields.deliveryFrequency === "MONTHLY" &&
      setSchedulingStartDate(
        requiredFields.monthlyChoice === "FIRST_DAY"
          ? moment().startOf("month").add(1, "months").format("YYYY-MM-DD")
          : moment().endOf("month").format("YYYY-MM-DD")
      );
  }, [requiredFields.monthlyChoice, requiredFields.deliveryFrequency]);

  const handleNavigate = (response: any) => {
    navigate(
      adminInGeneral
        ? `/admin/report/${response.data.report}`
        : `/report/${response.data.report}`,
      {
        state: {
          breadcrumb: true,
          breadcrumbType: adminInGeneral ? "admin_report" : "report",
          value: response.data.report_name,
        },
      }
    );
    resetGlobalStates();
  };

  const createReport = async (values: any) => {
    try {
      await api.post("reports", report(values)).then((response) => {
        setRecords(response);
        setVisible(true);
      });
      check();
    } catch (err: any) {
      RetailNotification.showNotification(
        "error",
        t("components.notification.formTitle"),
        t("components.notification.formText")
      );
    }
  };

  const updateReport = async (values: any) => {
    try {
      await api
        .patch(
          `/reports/${location.pathname.substring(
            location.pathname.lastIndexOf("/") + 1
          )}`,
          report(values)
        )
        .then((response) => {
          handleNavigate(response);
        });
    } catch (err: any) {
      console.log(err);
    }
  };

  const onFinish = () => {
    if (!requiredFields.is_basic_selected && selectedItems.length === 0) {
      RetailNotification.showNotification(
        "error",
        t("components.notification.formTitle"),
        t("components.notification.formText")
      );
    } else {
      type === "create" && createReport(form.getFieldsValue(true));
      type === "edit" && updateReport(form.getFieldsValue(true));
    }
  };

  const onFinishFailed = () => {
    RetailNotification.showNotification(
      "error",
      t("components.notification.formTitle"),
      t("components.notification.formText")
    );
  };

  useEffect(() => {
    if (moment(requiredFields.start_date).isAfter(requiredFields.end_date)) {
      setRequiredFields((prev) => ({ ...prev, end_date: null }));
      form.setFieldsValue({ end_date: "" });
    }
  }, [requiredFields.start_date, requiredFields.end_date]);

  const switchFrequencyDate = () => {
    switch (requiredFields.deliveryFrequency) {
      case "MONTHLY":
        return (
          <RetailSelect
            onChange={(value) => {
              handleChange(value, "monthlyChoice");
              setSchedulingStartDate(
                value === "FIRST_DAY"
                  ? moment().startOf("month").add(1, "months")
                  : moment().endOf("month")
              );
            }}
            onClick={(e) => e.preventDefault()}
            defaultValue="FIRST_DAY"
            data-test="report-form-delivery-frequency-monthly"
            options={monthly(t)}
          />
        );
      default:
        return (
          <Form.Item
            name="deliveryFrequency"
            rules={[
              {
                required: true,
                message: t("components.reportForm.errorStates.date"),
              },
            ]}
          >
            <DatePicker
              format={dateFormat}
              disabledDate={disabledDate}
              className={cm.datePicker}
              onChange={(value) =>
                setSchedulingStartDate(value?.format("YYYY-MM-DD"))
              }
              locale={datePickerLocale(i18n.language)}
              data-test={
                requiredFields.deliveryFrequency === "DAILY"
                  ? "report-form-delivery-frequency-daily"
                  : "report-form-delivery-frequency-weekly"
              }
            />
          </Form.Item>
        );
    }
  };

  const steps = [
    {
      content: (
        <>
          <RetailFormRow
            className={cc(["form-row", local && local.id ? cm.advertiser : ""])}
          >
            <RetailFormInfo
              column={{
                number: "1",
                title: t("components.reportForm.nameTitle"),
                text: t("components.reportForm.nameText"),
              }}
            />
            <Col>
              <RetailFormInput
                isFocused={requiredFields.name !== ""}
                label={t("components.reportForm.nameTitle")}
                name="name"
                help={t("components.reportForm.nameSub")}
                className="floating"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  onChange={({ target }) => handleChange(target.value, "name")}
                  className="floating"
                  value={requiredFields.name}
                  data-test="report-form-name"
                />
              </RetailFormInput>
            </Col>
          </RetailFormRow>
          <RetailFormRow className="form-row">
            <RetailFormInfo
              column={{
                number: "2",
                title: t("components.reportForm.reportTypeTitle"),
                text: t("components.reportForm.reportTypeText"),
              }}
              article={
                adminInGeneral
                  ? undefined
                  : {
                      text: t("components.articles.frequency"),
                      type: "frequency",
                    }
              }
              advertiserStateForLinks={advertiserStateForLinks}
            />
            <Col>
              <Form.Item name="type" className="flex">
                <Radio.Group
                  onChange={({ target }) =>
                    handleChange(target.value, "sent_type")
                  }
                  data-test="report-form-type"
                >
                  <Radio
                    value="STATIC"
                    className="form-radio"
                    data-test="report-form-type-static"
                  >
                    <RetailTitle level={5}>
                      {t("components.reportForm.static")}
                    </RetailTitle>
                    <RetailText
                      style={{ color: "var(--gray-4)" }}
                      size="xs"
                      family="poppins"
                    >
                      {t("components.reportForm.staticText")}
                    </RetailText>
                  </Radio>
                  <Radio
                    value="SCHEDULED"
                    className={cc([
                      "form-radio",
                      cm.marginTop,
                      requiredFields.sent_type ? "expanded" : "",
                    ])}
                    data-test="report-form-type-scheduled"
                  >
                    <RetailTitle level={5}>
                      {t("components.reportForm.scheduled")}
                    </RetailTitle>
                    <RetailText
                      style={{ color: "var(--gray-4)" }}
                      size="xxs"
                      family="poppins"
                    >
                      {t("components.reportForm.scheduledText")}
                    </RetailText>
                    {requiredFields.sent_type === "SCHEDULED" && (
                      <>
                        <div className={cm.selectContainer}>
                          <RetailText
                            size="xxs"
                            family="poppins"
                            className={cm.selectLabel}
                          >
                            {t("components.reportForm.frequency")}
                          </RetailText>
                          <RetailSelect
                            defaultValue={
                              type === "create" ||
                              requiredFields.deliveryFrequency === undefined
                                ? "DAILY"
                                : requiredFields.deliveryFrequency
                            }
                            onChange={(value) =>
                              handleChange(value, "deliveryFrequency")
                            }
                            onClick={(e) => e.preventDefault()}
                            data-test="report-form-delivery-frequency"
                            options={frequency(t)}
                          />
                        </div>
                        <div className={cm.selectContainer}>
                          <RetailText
                            size="xxs"
                            family="poppins"
                            className={cm.selectLabel}
                          >
                            {t("components.reportForm.sendDate")}
                          </RetailText>
                          {switchFrequencyDate()}
                        </div>
                        <div className={cm.selectContainer}>
                          <RetailText
                            size="xxs"
                            family="poppins"
                            className={cm.selectLabel}
                          >
                            {t("components.reportForm.exportType")}
                          </RetailText>
                          <RetailSelect
                            onChange={(value) =>
                              handleChange(value, "exportFormat")
                            }
                            onClick={(e) => e.preventDefault()}
                            defaultValue={
                              type === "create" || !requiredFields.exportFormat
                                ? ".CSV"
                                : requiredFields.exportFormat
                            }
                            data-test="report-form-export-format"
                            options={fileFormats}
                          />
                        </div>
                        <div className={cm.selectContainer}>
                          <RetailText
                            size="xxs"
                            family="poppins"
                            className={cm.selectLabel}
                          >
                            {t("components.reportForm.mail")}
                          </RetailText>
                          <Form.Item
                            name="mail"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "components.reportForm.errorStates.mail"
                                ),
                                type: "email",
                              },
                            ]}
                          >
                            <Input
                              className="floating not-labeled"
                              placeholder="example@email.com"
                              onChange={({ target }) =>
                                handleChange(target.value, "email")
                              }
                              data-test="report-form-email"
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </RetailFormRow>
          {requiredFields.sent_type === "STATIC" && (
            <RetailFormRow className="form-row">
              <RetailFormInfo
                column={{
                  number: "3",
                  title: t("components.reportForm.dateTitle"),
                  text: t("components.reportForm.dateText"),
                }}
              />
              <Col>
                <RetailDatePicker
                  isFocused={requiredFields.start_date !== ""}
                  defaultValue={
                    type === "edit" &&
                    data?.data.report_start_date !== "0001-01-01"
                      ? moment(requiredFields.start_date)
                      : null
                  }
                  type="reports"
                  onChange={(value) =>
                    handleChange(value ? value?.toString() : "", "start_date")
                  }
                  rules={[{ required: true }]}
                  disabledDate={(date: Moment) => date && date > moment()}
                  data-test="report-form-start-date"
                />

                <RetailEndDatePicker
                  /* defaultValue={
                    type === "edit" &&
                    data?.data.report_end_date !== "0001-01-01"
                      ? moment(requiredFields.end_date)
                      : null
                  } */
                  rules={[{ required: true }]}
                  isFocused={requiredFields.end_date !== null}
                  onClose={() => form.setFieldsValue({ end_date: "" })}
                  handleChange={handleChange}
                  startDate={requiredFields.start_date}
                  type="reports"
                  renderExtraFooter={undefined}
                  data-test="report-form-end-date"
                />
              </Col>
            </RetailFormRow>
          )}
          <RetailFormRow className={cc(["form-row", cm.reportType])}>
            <RetailFormInfo
              column={{
                number: requiredFields.sent_type === "STATIC" ? "4" : "3",
                title: t("components.reportForm.reportKindTitle"),
                text: t("components.reportForm.reportKindText"),
              }}
              article={
                adminInGeneral
                  ? undefined
                  : {
                      text: t("components.articles.type"),
                      type: "report_type",
                    }
              }
            />
            <Col className={cm.relative}>
              <Form.Item name="report_type" className="flex reversed">
                <Radio.Group
                  onChange={({ target }) =>
                    target.value === "ready"
                      ? handleChange(true, "is_basic_selected")
                      : handleChange(false, "is_basic_selected")
                  }
                  data-test="report-form-report-type"
                >
                  <Radio
                    value="ready"
                    className={cc([
                      "form-radio",
                      requiredFields.is_basic_selected ? "expanded" : "",
                    ])}
                    data-test="report-form-report-type-ready"
                  >
                    <RetailTitle level={5} className={cm.title}>
                      {t("components.reportForm.predefined")}
                    </RetailTitle>
                    {requiredFields.is_basic_selected && (
                      <Form.Item
                        name="predefined_type"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "components.reportForm.errorStates.type"
                            ),
                          },
                        ]}
                        className={cm.left}
                      >
                        <RetailSelect
                          placeholder={t(
                            "components.reportForm.predefinedPlaceholder"
                          )}
                          value={requiredFields.predefined_type}
                          onChange={(value) =>
                            handleChange(value, "predefined_type")
                          }
                          onClick={(e) => e.preventDefault()}
                          className={cm.select}
                          data-test="report-form-report-type-predefined"
                          options={
                            adminInGeneral
                              ? adminPredefinedTypes(t)
                              : predefinedTypes(t)
                          }
                        />
                      </Form.Item>
                    )}
                  </Radio>
                  <Radio
                    value="special"
                    className={cc(["form-radio", cm.marginTop])}
                    data-test="report-form-report-type-special"
                  >
                    <RetailTitle level={5} className={cm.title}>
                      {t("components.reportForm.special")}
                    </RetailTitle>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </RetailFormRow>
          {requiredFields.is_basic_selected === false && (
            <RetailFormRow className={cc(["form-row", cm.lastBg])}>
              <Col span={24}>
                <RetailTitle level={5} className={cm.selectedTitle}>
                  {t("components.reportForm.breaks")}
                </RetailTitle>
                <RetailText className={cm.selectedText} size="xs">
                  {t("components.reportForm.breaksText")}
                </RetailText>
              </Col>
              <Row className={cm.selectedRow}>
                <Col span={12} className={cm.listContainer}>
                  <RetailSearchBar
                    fullWidth
                    placeholder={t("components.reportForm.breaksPlaceholder")}
                    style={{ padding: "12px 12px 12px 0", gap: "1rem" }}
                    onChange={({ target }) => setSearchValue(target.value)}
                  />
                  <List className={cc([cm.firstList, cm.borderLeft])}>
                    {dimensions
                      .filter((item: any) => {
                        if (searchValue === "") {
                          return t(
                            `components.reportForm.dimensions.${item.Value}`
                          );
                        } else if (
                          t(`components.reportForm.dimensions.${item.Value}`)
                            .toLocaleLowerCase()
                            .includes(searchValue.toLocaleLowerCase())
                        ) {
                          return t(
                            `components.reportForm.dimensions.${item.Value}`
                          );
                        }
                      })
                      .map((item: any) => (
                        <List.Item key={item.id} className={cm.listItem}>
                          <RetailText
                            family="poppins"
                            size="xs"
                            className={cc([cm.pointer, cm.listItemNavyText])}
                          >
                            {t(
                              `components.reportForm.dimensions.${item.Value}`
                            )}
                          </RetailText>
                          <AddCircleOutlined
                            className={cm.pointer}
                            onClick={() => handleClick(item)}
                            data-test="report-form-add-dimension"
                          />
                        </List.Item>
                      ))}
                  </List>
                </Col>
                <Col
                  span={12}
                  className={
                    selectedItems
                      ? selectedItems.length === 0
                        ? cm.secondList
                        : cm.secondListFull
                      : ""
                  }
                >
                  <List split={false}>
                    {selectedItems.length !== undefined &&
                      selectedItems.length === 0 && (
                        <Empty
                          type="dimensions"
                          className={cm.empty}
                          isError={
                            errorStates.dimensionError &&
                            selectedItems.length === 0
                          }
                        />
                      )}
                    {selectedItems &&
                      selectedItems.map((item) => {
                        return (
                          <List.Item
                            key={item.id}
                            className={cc([cm.secondListItem])}
                          >
                            <RetailText
                              size="xs"
                              family="poppins"
                              weight="medium"
                            >
                              {t(
                                `components.reportForm.dimensions.${item.Value}`
                              )}
                            </RetailText>
                            <CloseCircleOutlined
                              onClick={() => handleDelete(item.Value)}
                              className={cm.pointer}
                              data-test="report-form-remove-dimension"
                            />
                          </List.Item>
                        );
                      })}
                  </List>
                  {selectedItems.length > 0 && (
                    <RetailText
                      className={cm.listItemText}
                      family="poppins"
                      size="xxxs"
                    >
                      {t("components.reportForm.maxWarning")}
                    </RetailText>
                  )}
                  {selectedItems.length > 0 && (
                    <Row className={cm.listFooter}>
                      <RetailText
                        family="poppins"
                        weight="medium"
                        size="xs"
                        onClick={handleClean}
                      >
                        {t("components.reportForm.clear")}
                      </RetailText>
                    </Row>
                  )}
                </Col>
              </Row>
              <Col span={24} className={cm.checkboxContainer}>
                <RetailTitle level={5} className={cm.selectedTitle}>
                  {t("components.reportForm.metrics")}
                </RetailTitle>
                <RetailText className={cm.selectedText} size="xs">
                  {t("components.reportForm.metricsText")}
                </RetailText>

                <Form.Item
                  name="metrics"
                  rules={[
                    {
                      required: true,
                      message: t("components.reportForm.errorStates.metric"),
                    },
                  ]}
                  className={cm.metricContainer}
                >
                  <Checkbox.Group
                    options={metrics.allMetrics}
                    onChange={(value) =>
                      setMetrics((prev: any) => ({
                        ...prev,
                        selectedMetrics: value,
                      }))
                    }
                    data-test="report-form-metrics"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className={cm.checkboxContainer}>
                <RetailTitle level={5} className={cm.selectedTitle}>
                  {t("components.reportForm.filters")}
                </RetailTitle>
                <RetailText className={cm.selectedText} size="xs">
                  {t("components.reportForm.filtersText")}
                </RetailText>
              </Col>
              <Col span={24} className={cc(["flex", cm.btnContainer])}>
                {tags.length > 0 && (
                  <RetailMainButton
                    hasBackground={false}
                    icon={<CloseOutlined />}
                    className={cc(["flex", cm.clearBtn])}
                    onClick={resetGlobalStates}
                    data-test="report-form-add-filter"
                  >
                    {t("common.clear")}
                  </RetailMainButton>
                )}
                <RetailFilterPopover
                  data-test="report-form-filter-popover"
                  filters={
                    adminInGeneral
                      ? createReportFiltersAdmin(t)
                      : createReportFilters(t)
                  }
                />
              </Col>
              {tags.length > 0 ? (
                <Col className={cc(["flex", cm.tagContainer])}>
                  {tags.map((tag) => (
                    <RetailFilterTag
                      type="FILTER_REPORT"
                      tag={tag}
                      key={tag.id}
                    />
                  ))}
                </Col>
              ) : null}
            </RetailFormRow>
          )}
        </>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  let navigate = useNavigate();

  const navbarRef = useRef<RetailStepsNavbarHandles>(null);

  return (
    <>
      <RetailStepsNavbar
        stepInformation={stepInformation}
        ref={navbarRef}
        current={current}
        setCurrent={setCurrent}
        type="report"
        resetStates={resetGlobalStates}
        errorStates={errorStates}
        check={check}
        info={{
          selectedItems: selectedItems,
          name: requiredFields.name,
        }}
        form={form}
      />
      {/* Content */}
      <Form
        className={cm.form}
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        data-test="report-form"
        initialValues={{
          deliveryFrequency: moment().add(1, "days"),
          mail: type === "create" ? "" : requiredFields.email,
        }}
      >
        {steps[current].content}
        <Divider className={cm.divider} />
        <div className={cc(["flex", cm.footer])}>
          <RetailMainButton
            className={cc([cm.footerBtn, cm.btnRight, "cancel-reports"])}
            hasBackground={false}
            onClick={() =>
              navigate(adminInGeneral ? "/admin/reports" : "/reports")
            }
            data-test="cancel-report"
          >
            {t("common.cancel")}
          </RetailMainButton>
          {current === steps.length - 1 && (
            <RetailMainButton
              htmlType="button"
              hasBackground
              onClick={form.submit}
              className={cc([cm.footerBtn, "create-reports"])}
              data-test={type === "create" ? "create-report" : "edit-report"}
            >
              {type === "create"
                ? t("pages.acc.reports.form.create")
                : t("components.stepsNavbar.report_edit")}
            </RetailMainButton>
          )}
        </div>
      </Form>
      <RetailSuccessModal
        type="reports"
        visible={visible}
        setVisible={setVisible}
        onClick={() => handleNavigate(records)}
      />
    </>
  );
};

export default RetailReportsForm;
