import Modal from "antd/lib/modal";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseOutlined } from "../../../assets/icons/closeOutlined.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailErrorModalProps {
  type: "invoice" | "craftgate";
  visible: boolean;
  setVisible(visible: boolean): void;
  onClick?(): void;
}

const RetailErrorModal = ({
  type,
  visible,
  setVisible,
  onClick,
}: RetailErrorModalProps) => {
  const { t } = useTranslation();

  const close = () => {
    setVisible(false);
    onClick && onClick();
  };

  return (
    <Modal
      className={cm.errorModal}
      visible={visible}
      closable={false}
      centered
      footer={[
        <RetailMainButton
          hasBackground={true}
          onClick={close}
          className={cm.ok}
        >
          {t("common.okay")}
        </RetailMainButton>,
      ]}
    >
      <section className={cm.errorWrapper}>
        <div className={cc(["flex", cm.errorIcon])}>
          <CloseOutlined />
        </div>
        <RetailTitle className={cm.errorTitle}>
          {t(`marketplaceDependent.${type}_error_modal_title`)}
        </RetailTitle>
        <RetailText size="xs" weight="medium" className={cm.errorText}>
          {t(`marketplaceDependent.${type}_error_modal_text`)}
        </RetailText>
      </section>
    </Modal>
  );
};

export default RetailErrorModal;
