import Input from "antd/lib/input";
import Tabs from "antd/lib/tabs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import useApi from "../../../api";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailSuccessModal from "../../../components/Modal/RetailSuccessModal";
import RetailNotification from "../../../components/Notification";
import RetailTable from "../../../components/Table/RetailTable";
import { agencyFilters } from "../../../utils/filters";

const AgenciesPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [visible, setVisible] = useState<{
    open: boolean;
    mode: "CREATE" | "UPDATE";
    agency_name: string;
  }>({ open: false, mode: "CREATE", agency_name: "" });

  const [successVisible, setSuccessVisible] = useState(false);

  const [id, setID] = useState(null);

  const [activeKey, setActiveKey] = useState("");

  const columns = (t: any) => [
    {
      title: t("common.table.name"),
      dataIndex: "name",
      disabled: true,
    },
  ];

  const open = () => setVisible({ ...visible, open: true });

  const close = () => setVisible({ ...visible, open: false, agency_name: "" });

  const add = async () => {
    try {
      visible.agency_name !== "" &&
        (await api.post("agencies", { name: visible.agency_name }));
      setSuccessVisible(true);
      queryClient.refetchQueries("table");
    } catch (err: any) {
      console.error(err);
    } finally {
      close();
    }
  };

  const open_update = (records: any) => {
    setVisible({
      open: true,
      mode: "UPDATE",
      agency_name: records.name,
    });
    setID(records.id);
  };

  const updateValue = async (data: any) => {
    const response = await api.patch(`agencies/${id}`, {
      name: visible.agency_name,
    });
    return response;
  };
  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (data: any) => {
    try {
      await mutateAsync(data);
      queryClient.refetchQueries("table");
    } catch (err: any) {
      console.error(err);
    } finally {
      close();
    }
  };

  const updateValueArchive = async (data: any) => {
    const config = {
      status: data.status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED",
    };
    const response = await api.patch(`agencies/${data.id}`, config);
    return response;
  };

  const { mutateAsync: mutateArchive } = useMutation(updateValueArchive);

  const changeArchiveStatus = async (data: any) => {
    try {
      await mutateArchive(data);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t(`components.notification.${data.status.toLowerCase()}Agency`)
      );
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  const tableConfig = {
    url: "agencies",
    isRelation: false,
    filters: agencyFilters(t),
    activeKey,
    to: open_update,
    onArchive: changeArchiveStatus,
  };

  const activeTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "status", op: "ne", value: "ARCHIVED" }],
  };

  const archivedTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "status", op: "eq", value: "ARCHIVED" }],
  };

  const subtitle =
    visible.mode === "CREATE"
      ? t("pages.admin.agencies.subtitle")
      : t("pages.admin.agencies.edit");

  return (
    <RetailPageContainer>
      <Tabs
        onChange={(activeKey) => setActiveKey(activeKey)}
        className="bordered-container no-margin"
      >
        <Tabs.TabPane tab={t("common.listed")} key="ACTIVE">
          <RetailTable
            placeholder={t("pages.admin.agencies.search")}
            columns={() => columns(t)}
            tableConfig={activeTabConfig}
            button={{
              title: t("components.modal.campaignDetails.create_agency"),
              onClick: open,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("common.archived")} key="ARCHIVED">
          <RetailTable
            placeholder={t("pages.admin.agencies.search")}
            columns={() => columns(t)}
            tableConfig={archivedTabConfig}
            addBtnVisible={false}
          />
        </Tabs.TabPane>
      </Tabs>

      <CampaignDetailsModal
        subtitle={subtitle}
        type={`${visible.mode}_AGENCY`}
        visible={visible.open}
        destroyOnClose
        onCancel={close}
        onOk={visible.mode === "CREATE" ? add : changeValue}
      >
        <RetailFormInput
          isFocused={visible.agency_name !== ""}
          label={t("pages.admin.agencies.label")}
          subText={t("pages.admin.agencies.subtext")}
          name="name"
          className="floating"
        >
          <Input
            className="floating"
            onChange={({ target }) =>
              setVisible({
                ...visible,
                agency_name: target.value,
              })
            }
            value={visible.agency_name}
            defaultValue={visible.agency_name}
            autoComplete="off"
          />
        </RetailFormInput>
      </CampaignDetailsModal>
      <RetailSuccessModal
        type="agencies"
        visible={successVisible}
        setVisible={setSuccessVisible}
      />
    </RetailPageContainer>
  );
};

export default AgenciesPage;
