import Typography from "antd/lib/typography";
import { TitleProps } from "antd/lib/typography/Title";
import cc from "classcat";
import React from "react";

import cm from "./style.module.scss";

export interface RetailTitleProps extends TitleProps {
  family?: "eudoxus" | "poppins";
}

const RetailTitle: React.FC<RetailTitleProps> = ({
  level = 4,
  children,
  className,
  family = "eudoxus",
  ...titleProps
}) => {
  const { Title } = Typography;
  return (
    <Title
      level={level}
      className={cc(["title", cm[`font-${family}`], className || ""])}
      {...titleProps}
    >
      {children}
    </Title>
  );
};

export default RetailTitle;
