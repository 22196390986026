import { useContext } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as LinkOutlined } from "../../../assets/icons/linkOutlined.svg";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import cm from "../style.module.scss";

export interface RetailNameColumnProps {
  to: string;
  value: string;
  state?: any;
  isForm?: boolean;
}

const RetailNameColumn = ({
  to,
  value,
  state,
  isForm = false,
}: RetailNameColumnProps) => {
  const { setLocal } = useContext(AuthContext) as Auth;

  const handleClick = () => {
    state && localStorage.setItem("advertiser_id", JSON.stringify(state));
    setLocal(state);
  };

  return (
    <>
      {value ? (
        <Link
          to={to}
          className={`${cm.link} ${isForm ? cm.formLink : cm.tableLink}`}
          state={state}
          onClick={handleClick}
          target={isForm ? "_blank" : "_self"}
        >
          {value}
          {!isForm && <LinkOutlined className={cm.linkUp} />}
        </Link>
      ) : (
        <span>-</span>
      )}
    </>
  );
};

export default RetailNameColumn;
