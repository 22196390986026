import Tooltip, { TooltipPropsWithTitle } from "antd/lib/tooltip";
import { ReactNode } from "react";

export interface RetailTooltipProps extends TooltipPropsWithTitle {
  children: ReactNode;
}

const RetailTooltip = ({
  children,
  ...tooltipPropsWithTitle
}: RetailTooltipProps) => {
  return (
    <Tooltip
      color="var(--primary-6)"
      arrowPointAtCenter
      overlayInnerStyle={{
        padding: "10px 12px",
        borderRadius: "8px",
        fontSize: "12px",
        lineHeight: "18px",
        fontFamily: "Poppins, sans-serif !important",
      }}
      {...tooltipPropsWithTitle}
    >
      {children}
    </Tooltip>
  );
};

export default RetailTooltip;
