import Breadcrumb from "antd/lib/breadcrumb";
import { useTranslation } from "react-i18next";
import {
	Link,
	useLocation,
} from "react-router-dom";

import useAcademyCategoryFetch from "../../../../../hooks/useAcademyCategoryFetch";
import usePostFetch from "../../../../../hooks/usePostFetch";
import { truncate } from "../../../../../utils/helpers";
import RetailTooltip from "../../../../Tooltip/RetailTooltip";
import cm from "../style.module.scss";

const RetailBreadcrumb = () => {
  const { t } = useTranslation();

  const { post } = usePostFetch("academy");

  const { category } = useAcademyCategoryFetch();

  const location = useLocation();

  const userType = location.pathname.includes("admin") ? "admin" : "user";

  const breadcrumb = location.state as {
    breadcrumbType: string;
    value: string | { category: string; value: string };
  };

  const switchLink = () => {
    switch (breadcrumb.breadcrumbType) {
      case "finance":
        return "/admin/transactions";
      case "settings":
        return "/admin/general";
      case "academy":
        return "/academy";
      case "report":
        return "reports";
      case "admin_report":
        return "/admin/reports";
      case "access":
        return "/admin/users";
      default:
        return "";
    }
  };

  const returnToBreadcrumb = () => {
    switch (breadcrumb.breadcrumbType) {
      case "finance":
      case "settings":
      case "access":
        return {
          breadcrumb: true,
          breadcrumbType: breadcrumb.breadcrumbType,
          value: t(`components.breadcrumb.${breadcrumb.breadcrumbType}`),
        };
      default:
        return null;
    }
  };

  const renderLastBreadcrumb = () => {
    if (typeof breadcrumb.value === "object") return breadcrumb.value.value;
    else if (breadcrumb.breadcrumbType === "academy") return category?.title;
    return breadcrumb.value;
  };

  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item className={cm.breadcrumb}>
        <Link to={switchLink()} state={returnToBreadcrumb()}>
          {t(`sider.${userType}.${breadcrumb.breadcrumbType}`)}
        </Link>
      </Breadcrumb.Item>
      {typeof breadcrumb.value === "object" && (
        <Breadcrumb.Item className={cm.breadcrumb}>
          <Link
            to={`/academy/blog/${breadcrumb.value.category}`}
            state={{
              breadcrumb: true,
              breadcrumbType: "academy",
              value: breadcrumb.value.category,
            }}
          >
            {post?.categories[0].title}
          </Link>
        </Breadcrumb.Item>
      )}
      <Breadcrumb.Item className={cm.titleCrumb}>
        {renderLastBreadcrumb() &&
          (renderLastBreadcrumb()?.length < 25 ? (
            renderLastBreadcrumb()
          ) : (
            <RetailTooltip title={renderLastBreadcrumb()}>
              {truncate(renderLastBreadcrumb()?.toString())}
            </RetailTooltip>
          ))}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default RetailBreadcrumb;
