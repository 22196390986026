import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import cc from "classcat";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import cm from "./style.module.scss";

export interface RetailPageContainerProps {
  loading?: boolean;
}

const RetailPageContainer: React.FC<RetailPageContainerProps> = ({
  children,
  loading,
}) => {
  const { showAdminBar } = useContext(AuthContext) as Auth;

  const { pathname } = useLocation();
  return (
    <>
      <Layout>
        <Spin spinning={!!loading}>
          <Row
            className={cc([
              cm.pageContainer,
              pathname === "/campaigns" || pathname === "/"
                ? cm.campaignsPage
                : "",
              showAdminBar ? cm.advertiserPage : "",
            ])}
          >
            <Col span={24}>{children}</Col>
          </Row>
        </Spin>
      </Layout>
    </>
  );
};

export default RetailPageContainer;
