import cc from "classcat";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as DownOutlined } from "../../../assets/icons/downOutlined.svg";
import { ReactComponent as EarthOutlined } from "../../../assets/icons/earthOutlined.svg";
import { ReactComponent as RefreshOutlined } from "../../../assets/icons/refreshOutlined.svg";
import { ReactComponent as UpOutlined } from "../../../assets/icons/upOutlined.svg";
import { ReactComponent as UserFilled } from "../../../assets/icons/userFilled.svg";
import { Auth, AuthContext } from "../../../context/AuthProvider";
import CampaignDetailsModal from "../../Modal/CampaignDetailsModal";
import RetailSelect from "../../Select/RetailSelect";
import cm from "../style.module.scss";

const RetailNavbarPopover = () => {
  const { t, i18n } = useTranslation();

  const { logout, showAdminBar } = useContext(AuthContext) as Auth;

  const sso_mode = (window as any).marketplaceConfig?.sso_mode || false;

  const [visible, setVisible] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [lang, setLang] = useState(t(i18n.language).toUpperCase());

  const [accounts, setAccounts] = useState<any[]>([]);

  const toggleVisible = () => setVisible(!visible);

  const handleLangChange = (lang: string) => setLang(lang);

  const onOk = () => {
    setModalVisible(false);
    i18n.changeLanguage(lang);
  };

  const onCancel = () => setModalVisible(false);

  const account_index = JSON.parse(
    localStorage.getItem("account_index") || "0"
  );

  const email = JSON.parse(localStorage.getItem("auth_email") || "null");

  useEffect(() => {
    const timer = setTimeout(() => {
      const accountsFromLocal = localStorage.getItem("auth_info");
      if (accountsFromLocal) {
        setAccounts(JSON.parse(accountsFromLocal));
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className={cc([cm.wrapper, visible ? cm.wrapperVisible : ""])}>
      <div className={cc(["flex", cm.container])} onClick={toggleVisible}>
        <div className={cc(["flex", cm.userIcon])}>
          <UserFilled />
        </div>
        <article>
          <h5 className={cm.name}>{accounts[account_index]?.account_name}</h5>
          {sso_mode ? null : <p className={cm.mail}>{email}</p>}
        </article>
        {visible ? (
          <UpOutlined className={cm.iconUp} />
        ) : (
          <DownOutlined className={cm.iconDown} />
        )}
      </div>

      {visible && (
        <ul className={cc([cm.list, showAdminBar ? cm.adminList : ""])}>
          {accounts?.length > 1 && (
            <li className="flex">
              <RefreshOutlined />
              <Link to="/accounts">
                {t("components.navbarPopover.account")}
              </Link>
            </li>
          )}
          <li className="flex" onClick={() => setModalVisible(true)}>
            <EarthOutlined />
            {t("components.navbarPopover.language")}
          </li>
          <li className="flex" onClick={logout}>
            <RefreshOutlined />
            {t("components.navbarPopover.logout")}
          </li>
        </ul>
      )}

      <CampaignDetailsModal
        type="SELECT_LANGUAGE"
        visible={modalVisible}
        onOk={onOk}
        onCancel={onCancel}
      >
        <RetailSelect
          className={cc([cm.select, "language"])}
          onChange={handleLangChange}
          defaultValue={t(i18n.language).toUpperCase()}
          dropdownClassName={cm.dropdown}
          options={[
            { value: "tr", label: "TR" },
            { value: "en", label: "EN" },
            { value: "ar", label: "AR" },
          ]}
        />
      </CampaignDetailsModal>
    </section>
  );
};

export default RetailNavbarPopover;
