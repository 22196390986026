import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio, { RadioChangeEvent } from "antd/lib/radio";
import Tabs from "antd/lib/tabs";
import { Upload, UploadProps } from "antd";
import moment from "moment";
import { ChangeEvent, useRef, useState } from "react";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { LinkOutlined } from "@ant-design/icons";
import { ReactComponent as InviteUserOutlined } from "../../../assets/icons/inviteUserOutlined.svg";
import useApi from "../../../api";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import RetailRoleRestrictionContainer from "../../../components/Container/RetailRoleRestrictionContainer";
import RetailDrawer from "../../../components/Drawer/RetailDrawer";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailSuccessModal from "../../../components/Modal/RetailSuccessModal";
import RetailNotification from "../../../components/Notification";
import AdvertiserSelect from "../../../components/Select/AdvertiserSelect";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import { getSubstring } from "../../../utils/helpers";
import cm from "./style.module.scss";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

const InvitedPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const [emailPopupVisible, setEmailPopupVisible] = useState(false);

  const [successModal, setSuccessModal] = useState(false);

  const [email, setEmail] = useState("");

  const [user, setUser] = useState<"MARKETPLACE" | "ADVERTISER" | null>(null);

  const [activeKey, setActiveKey] = useState("ADVERTISER");

  const [activeKeyAddUser, setActiveKeyAddUser] = useState("SINGLE");

  const [file, setFile] = useState<any>(null);

  const csvFile = useRef<File>();

  const [advertiser, setAdvertiser] = useState<any>(null);

  const [restricted, setRestricted] = useState(false);

  const [drawer, setDrawer] = useState<{ visible: boolean; records: any }>({
    visible: false,
    records: null,
  });

  const [editDrawer, setEditDrawer] = useState<{
    visible: boolean;
    records: any;
  }>({
    visible: false,
    records: null,
  });

  const users = ["ADVERTISER", "MARKETPLACE"];

  const invite = async (records: any) => {
    try {
      await api.post("invite-user", {
        account_id: parseInt(records.account_id),
        email: records.email,
        role: records.account_role,
      });
      RetailNotification.showNotification(
        "success",
        "",
        t("pages.admin.invited.success")
      );
    } catch (err: any) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.invited.error")
      );
    }
  };

  const openDrawer = (records: any) =>
    setDrawer({ visible: true, records: records });

  const openEditDrawer = (records: any) => {
    setEditDrawer({ visible: true, records: records });
    setRestricted(records.account_role.includes("REPORTER"));
  };

  const closeDrawer = () => setDrawer({ visible: false, records: null });

  const closeEditDrawer = () => {
    setEditDrawer({ visible: false, records: null });
    setRestricted(false);
  };

  const updateRole = async () => {
    //Close drawer if the role is not changed
    const isReporter = editDrawer?.records?.account_role.includes("REPORTER");
    if ((isReporter && restricted) || (!isReporter && !restricted)) {
      closeEditDrawer();
      return;
    }

    const role = restricted
      ? `${editDrawer.records?.account_role}_REPORTER`
      : getSubstring(editDrawer.records?.account_role, " ", "_");

    try {
      await api.patch("invite-user", {
        account_id: editDrawer.records?.account_id,
        email: editDrawer.records?.email,
        role,
      });
      closeEditDrawer();
      queryClient.refetchQueries("table");
    } catch (err: any) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  const tableConfig = {
    url: "invites",
    isRelation: false,
    activeKey,
    to: openEditDrawer,
    onArchive: invite,
    onThirdOption: openDrawer,
  };

  const advertiserTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "account_type", op: "eq", value: "ADVERTISER" }],
  };

  const marketplaceTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "account_type", op: "eq", value: "MARKETPLACE" }],
  };

  const firstColumns = (t: TFunction) => [
    {
      title: t("common.table.email"),
      dataIndex: "email",
      disabled: true,
      width: 350,
    },
    {
      title: t("common.table.role"),
      dataIndex: "account_role",
      render: (value: string) =>
        value?.includes("REPORTER")
          ? t("pages.admin.users.limited")
          : t("pages.admin.users.full"),
    },
  ];

  const lastColumn = (t: TFunction) => ({
    title: t("common.table.invitedAt"),
    dataIndex: "created_at",
    disabled: true,
    width: 250,
    render: (value: string) => (
      <RetailText family="poppins" size="xs">
        {moment(value).format("DD/MM/YY")}
      </RetailText>
    ),
  });

  const advertiserColumn = (t: TFunction) => ({
    title: t("common.table.advertiser"),
    dataIndex: "advertiser_id",
    render: (value: string, records: any) => (
      <RetailNameColumn
        state={{
          id: records.advertiser_id || 1,
          name: records.advertiser_name || "No Name",
        }}
        to={`/campaigns`}
        value={`${records.advertiser_name} (${value})`}
      />
    ),
  });

  const columns = (t: TFunction) => [
    ...firstColumns(t),
    advertiserColumn(t),
    lastColumn(t),
  ];

  const marketplaceColumns = (t: TFunction) => [
    ...firstColumns(t),
    lastColumn(t),
  ];

  const open = () => setVisible(true);

  const close = () => {
    setVisible(false);
    setEmailPopupVisible(false);
    setEmail("");
    setUser(null);
    setActiveKeyAddUser("SINGLE");
    setAdvertiser(null);
    setRestricted(false);
    form.resetFields();
    setFile(null);
    setAdvertiser(null);
  };

  const success = () => {
    close();
    setSuccessModal(true);
    queryClient.refetchQueries("table");
  };

  const handleChange = ({ target }: RadioChangeEvent) => setUser(target.value);

  const handleUserSelect = () => {
    setVisible(false);
    setEmailPopupVisible(true);
  };

  const handleAdvertiser = (item: any) => setAdvertiser(item);

  const AdvertiserLink = () => {
    return (
      <article className={cm.boxContainer}>
        <RetailText weight="medium" size="xxxs" className={cm.title}>
          {t("pages.admin.invited.csvInfo")}
        </RetailText>
        <a
          href="https://docs.gowit.com/v2.0/docs/bulk-invite-advertisers"
          target="_blank"
          rel="noreferrer"
          className={cm.popupLink}
        >
          <LinkOutlined />
          {t("pages.admin.invited.link")}
        </a>
      </article>
    );
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = csvFile;
    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("csv", file.current!);

    setFile(formData.get("csv"));
  };

  const props: UploadProps = {
    name: "files",
    multiple: false,
    className: ["dragger", cm.dragger].join(" "),
    beforeUpload: () => false,
    onChange: (f) => handleFileChange(f),
    accept: ".csv",
    maxCount: 1,
  };

  const handleKey = (key: string) => setActiveKey(key);

  const handleKeyAddUser = (key: string) => setActiveKeyAddUser(key);

  const handleEmail = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setEmail(target.value);

  const advertiserInviteForm = () => {
    return (
      <>
        <Tabs
          className={cm.tabContainer}
          onChange={handleKeyAddUser}
          activeKey={activeKeyAddUser}
        >
          <Tabs.TabPane
            tab={t("pages.admin.invited.single")}
            key="SINGLE"
            className={cm.tab}
          >
            <div className={`${cm.form} ${cm.singleForm}`}>
              <AdvertiserSelect type="INVITED" onClick={handleAdvertiser} />
              <RetailText
                size="xxs"
                family="poppins"
                className={cm.advertiserText}
              >
                {t("pages.admin.invited.advertiserText")}
              </RetailText>
              <Form
                form={form}
                onFinish={onFinish}
                requiredMark={false}
                autoComplete="off"
              >
                <RetailFormInput
                  isFocused={email !== ""}
                  label={t("pages.admin.users.label")}
                  help={t("pages.admin.users.subtext")}
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                  className="floating"
                >
                  <Input className="floating" onChange={handleEmail} />
                </RetailFormInput>
              </Form>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t("pages.admin.invited.multiple")}
            key="MULTIPLE"
            className={cm.tab}
          >
            <AdvertiserLink />
            <div className={cm.form}>
              <Upload.Dragger {...props}>
                <InviteUserOutlined className={cm.upload} />
                <RetailText
                  size="xs"
                  weight="medium"
                  className={cm.uploadTitle}
                >
                  <Trans i18nKey="pages.admin.invited.draggerTitle" />
                </RetailText>
                <RetailText
                  size="xxxs"
                  weight="medium"
                  className={cm.uploadText}
                >
                  {t("pages.admin.invited.draggerText")}
                </RetailText>
              </Upload.Dragger>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  };

  const inviteWithCsv = async () => {
    try {
      await api.post(
        "/invite-multiple-user/csv",
        { inviteCSV: file },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      success();
    } catch (err) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.invited.csvError")
      );
    }
  };

  const adminInviteForm = () => {
    return (
      <Form
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
      >
        <RetailFormInput
          isFocused={email !== ""}
          label={t("pages.admin.users.label")}
          help={t("pages.admin.users.subtext")}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
          className="floating"
        >
          <Input className="floating" onChange={handleEmail} />
        </RetailFormInput>
      </Form>
    );
  };

  const determineDisabledButtonForAddNewUser = () => {
    switch (activeKey) {
      case "SINGLE":
        return user === "MARKETPLACE"
          ? email === ""
          : email === "" || advertiser === null;
      case "MULTIPLE":
        return file === null;
    }
  };

  const onFinish = async () => {
    //Define a variable for account id
    let id;

    //Get account id for creating an admin
    if (advertiser === null) {
      try {
        const res = await api.get("/accounts");
        id = res.data?.id;
      } catch (err) {
        console.log(err);
      }
    }

    //Invite user either with advertiser id (for inviting advertisers) or account id (for inviting admins)
    try {
      await api.post("invite-user", {
        account_id: parseInt(id || advertiser?.account_id),
        email: form.getFieldValue("email"),
        role: restricted ? `${user}_REPORTER` : user,
      });
      success();
    } catch (err: any) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.invited.error")
      );
    }
  };

  const handleAddNewUser = async () => {
    if (activeKeyAddUser === "SINGLE" || user === "MARKETPLACE")
      return form.submit();
    else inviteWithCsv();
  };

  const copyInviteURL = () =>
    navigator.clipboard.writeText(drawer.records?.invite_url);

  const handleRestriction = (value: boolean) => setRestricted(value);

  return (
    <RetailPageContainer>
      <Tabs onChange={handleKey} className="bordered-container no-margin">
        <Tabs.TabPane tab={t("common.advertiser")} key="ADVERTISER">
          <RetailTable
            placeholder={t("pages.admin.invited.search")}
            columns={() => columns(t)}
            tableConfig={advertiserTabConfig}
            button={{
              title: t("components.modal.campaignDetails.create_user"),
              onClick: open,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("common.admin")} key="MARKETPLACE">
          <RetailTable
            placeholder={t("pages.admin.invited.search")}
            columns={() => marketplaceColumns(t)}
            tableConfig={marketplaceTabConfig}
            button={{
              title: t("components.modal.campaignDetails.create_user"),
              onClick: open,
            }}
          />
        </Tabs.TabPane>
      </Tabs>

      <CampaignDetailsModal
        subtitle={t("pages.admin.users.select")}
        type="SELECT_USER"
        visible={visible}
        onCancel={close}
        onOk={handleUserSelect}
        disabled={user === null}
      >
        <Radio.Group onChange={handleChange} className={cm.radioGroup}>
          {users.map((u) => (
            <Radio value={u} className="form-radio" key={u}>
              <RetailTitle level={5}>
                {t(`pages.admin.users.${u.toLowerCase()}`)}
              </RetailTitle>
              <RetailText size="xxxs" weight="medium" className={cm.radioText}>
                {t(`pages.admin.users.${u.toLowerCase()}Text`)}
              </RetailText>
              {user === u && (
                <RetailRoleRestrictionContainer
                  handleRestriction={handleRestriction}
                  restricted={restricted}
                />
              )}
            </Radio>
          ))}
        </Radio.Group>
      </CampaignDetailsModal>
      <CampaignDetailsModal
        subtitle={t("pages.admin.users.invite")}
        type={`CREATE_USER_${user!}`}
        visible={emailPopupVisible}
        onCancel={close}
        onOk={handleAddNewUser}
        disabled={determineDisabledButtonForAddNewUser()}
      >
        {user === "ADVERTISER" ? advertiserInviteForm() : adminInviteForm()}
      </CampaignDetailsModal>

      <RetailSuccessModal
        type="invited"
        visible={successModal}
        setVisible={setSuccessModal}
      />

      <RetailDrawer
        visible={drawer.visible}
        onOk={closeDrawer}
        onClose={closeDrawer}
        title={t("pages.admin.invited.title")}
        type="details"
      >
        <RetailTitle level={5} className={cm.drawerTitle}>
          {t("pages.admin.invited.info")}
        </RetailTitle>
        <div className={cm.container}>
          <div className={cm.card}>
            <RetailText size="xxs" weight="medium">
              {t("pages.admin.invited.mail")}
            </RetailText>
            <RetailText size="xxs" weight="bold">
              {drawer.records?.email}
            </RetailText>
          </div>
          {drawer.records?.advertiser_name && (
            <div className={cm.card}>
              <RetailText size="xxs" weight="medium">
                {t("pages.admin.invited.advertiser")}
              </RetailText>
              <RetailText size="xxs" weight="bold">
                {drawer.records?.advertiser_name}
              </RetailText>
            </div>
          )}
        </div>

        <RetailTitle level={5} className={cm.drawerTitle}>
          {t("pages.admin.invited.urlTitle")}
        </RetailTitle>
        <RetailText
          className={cm.link}
          size="xs"
          weight="medium"
          onClick={copyInviteURL}
        >
          {drawer.records?.invite_url ? drawer.records?.invite_url : "-"}
          <span className={cm.icon}>
            <LinkOutlined />
          </span>
        </RetailText>
      </RetailDrawer>

      <RetailDrawer
        visible={editDrawer.visible}
        onOk={updateRole}
        onClose={closeEditDrawer}
        title={t("pages.admin.invited.edit")}
        type="edit"
      >
        <div className={cm.container}>
          <div className={cm.card}>
            <RetailText size="xxs" weight="medium">
              {t("pages.admin.invited.mail")}
            </RetailText>
            <RetailText size="xxs" weight="bold">
              {editDrawer.records?.email}
            </RetailText>
          </div>

          <div className={cm.card}>
            <RetailText size="xxs" weight="medium">
              {t("pages.admin.invited.role")}
            </RetailText>
            <RetailText size="xxs" weight="bold">
              {t(`pages.admin.invited.${editDrawer.records?.account_role}`)}
            </RetailText>
          </div>
        </div>
        <RetailRoleRestrictionContainer
          handleRestriction={handleRestriction}
          restricted={restricted}
          type="drawer"
        />
      </RetailDrawer>
    </RetailPageContainer>
  );
};

export default InvitedPage;
