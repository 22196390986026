import Spin from "antd/lib/spin";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import {
	useCallback,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import RetailCampaignWidget from "../../../components/Card/RetailCampaignWidget";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailNotification from "../../../components/Notification";
import useCampaignFetch from "../../../hooks/useCampaignFetch";
import useSettings from "../../../hooks/useSettings";
import AdPlacementTable from "./AdPlacementTable";
import SingleCampaignHeader from "./CampaignDetailsHeader";
import CategoriesTable from "./CategoriesTable";
import CreativesTable from "./CreativesTable";
import CreativeInfo from "./CreativesTable/CreativeInfo";
import KeywordsTable from "./KeywordsTable";
import NegativeKeywordsTable from "./NegativeKeywordsTable";
import ProductTable from "./ProductTable";
import TargetingTable from "./TargetingTable";

const SingleCampaignPage = () => {
  const { t } = useTranslation();

  const { isLoading, data, isError } = useCampaignFetch();

  const { data: marketplaceSettings } = useSettings("ADVERTISER");

  const [activeKey, setActiveKey] = useState("");

  const name = (window as any).marketplaceConfig.name?.toLowerCase() || "ACME";

  useEffect(
    () =>
      setActiveKey(data?.data.ad_type === "DISPLAY" ? "CREATIVES" : "PRODUCTS"),
    [data?.data.ad_type]
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(() => navigate("/"), 100);
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const chooseMinimum = useCallback(() => {
    if (data?.data?.ad_type === "PRODUCT" || data?.data?.ad_type === "STORE") {
      return marketplaceSettings?.data?.sponsored_product_price_setting;
    } else return marketplaceSettings?.data?.sponsored_display_price_setting;
  }, [data?.data?.ad_type, marketplaceSettings]);

  const chosenMinBid = useCallback(() => {
    if (chooseMinimum() === "CPM")
      return marketplaceSettings?.data?.minimum_cpm;
    else return marketplaceSettings?.data?.minimum_bid;
  }, [marketplaceSettings, chooseMinimum]);

  const switchTab = () => {
    switch (data?.data?.targeting_type) {
      case "SEARCH":
        return (
          <>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.keywords")}
              key="KEYWORDS"
            >
              <KeywordsTable
                keywordType={data && data?.data.targeting_type}
                bidType={data && data?.data.bid_type}
                chosenMinBid={chosenMinBid}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.negativeKeywords")}
              key="NEGATIVE_KEYWORDS"
            >
              <NegativeKeywordsTable type="NEGATIVE_KEYWORDS" />
            </Tabs.TabPane>
          </>
        );
      case "CATEGORY":
        return (
          <>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.categories")}
              key="CATEGORIES"
            >
              <CategoriesTable
                bidType={data && data?.data.bid_type}
                chosenMinBid={chosenMinBid}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.negativeCategories")}
              key="NEGATIVE_CATEGORIES"
            >
              <NegativeKeywordsTable type="NEGATIVE_CATEGORIES" />
            </Tabs.TabPane>
          </>
        );
      default:
        break;
    }
  };

  const chooseProductOrDisplay = () => {
    switch (data?.data?.ad_type) {
      case "PRODUCT":
      case "STORE":
        return (
          <Tabs.TabPane
            tab={t("pages.acc.campaignDetails.products")}
            key="PRODUCTS"
          >
            <ProductTable ad_type={data?.data.ad_type} name={name} />
          </Tabs.TabPane>
        );
      case "DISPLAY":
        return (
          <Tabs.TabPane
            tab={t("pages.acc.campaignDetails.creatives")}
            key="CREATIVES"
          >
            <CreativeInfo url={data?.data.redirect?.custom_url} />
            <CreativesTable />
          </Tabs.TabPane>
        );
      default:
        break;
    }
  };

  return (
    <RetailPageContainer>
      <Spin spinning={isLoading} size="large">
        <SingleCampaignHeader
          data={data?.data}
          chosenMinBid={chosenMinBid}
          settings={marketplaceSettings?.data}
        />
        <RetailCampaignWidget page="campaign_details" />
        <Tabs
          className={cc([
            "bordered-container",
            "campaign-details-tab",
            activeKey === "targeting" ? "two-step-tabs" : "",
          ])}
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
        >
          {chooseProductOrDisplay()}
          {switchTab()}
          {/**
           * Hide placements tab for beymen
           */}
          {name !== "beymen" && (
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.placements")}
              key="PLACEMENTS"
            >
              <AdPlacementTable />
            </Tabs.TabPane>
          )}
          {/**
           * Show targeting tabs based on marketplace settings
           */}
          {marketplaceSettings?.data?.demographic_targeting_type ===
          "ALLOWED" ? (
            <Tabs.TabPane
              tab={t("pages.acc.campaignDetails.targeting")}
              key="targeting"
              className="targeting"
            >
              <Tabs className="secondary-tab">
                <Tabs.TabPane
                  tab={t("pages.acc.campaignDetails.city")}
                  key="CITIES"
                >
                  <TargetingTable activeKey="CITIES" />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("pages.acc.campaignDetails.age")}
                  key="AGE_RANGES"
                >
                  <TargetingTable activeKey="AGE_RANGES" />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("pages.acc.campaignDetails.gender")}
                  key="GENDERS"
                >
                  <TargetingTable activeKey="GENDERS" />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("pages.acc.campaignDetails.platform")}
                  key="PLATFORMS"
                >
                  <TargetingTable activeKey="PLATFORMS" />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t("pages.acc.campaignDetails.customers")}
                  key="CUSTOMERS"
                >
                  <TargetingTable activeKey="CUSTOMERS" />
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </Spin>
    </RetailPageContainer>
  );
};

export default SingleCampaignPage;
