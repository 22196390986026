import Col from "antd/lib/col";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import cc from "classcat";
import {
	ChangeEvent,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useParams } from "react-router-dom";

import useApi from "../../../../api";
import { ReactComponent as CheckOutlined } from "../../../../assets/icons/checkWhite.svg";
import { ReactComponent as CloseOutlined } from "../../../../assets/icons/closeOutlined.svg";
import { ReactComponent as PencilOutlined } from "../../../../assets/icons/pencilOutlined.svg";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStateColumn from "../../../../components/Column/RetailStateColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import RetailTable from "../../../../components/Table/RetailTable";
import SelectKeywordsTable from "../../../../components/Table/SelectKeywordsTable";
import RetailBidTooltip from "../../../../components/Tooltip/RetailBidTooltip";
import RetailText from "../../../../components/Typography/RetailText";
import RetailTitle from "../../../../components/Typography/RetailTitle";
import {
	Keyword,
	KeywordContext,
} from "../../../../context/KeywordProvider";
import { keywordFilters } from "../../../../utils/filters";
import { validateNumber } from "../../../../utils/helpers";
import cm from "./style.module.scss";

export interface KeywordsTableProps {
  bidType: string;
  keywordType: string;
  chosenMinBid: () => any;
}

const KeywordsTable = ({
  bidType,
  keywordType,
  chosenMinBid,
}: KeywordsTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const { keywords, setKeywords } = useContext(KeywordContext) as Keyword;

  const queryClient = useQueryClient();

  const inputRef = useRef<any>(null);

  const [isVisible, setIsVisible] = useState(false);

  const [editing, setEditing] = useState(false);

  const [popupPositions, setPopupPositions] = useState({ top: 0, left: 0 });

  const [selected, setSelected] = useState<any>([]);

  const [error, setError] = useState(false);

  const handleBlur = () => setEditing(false);

  const isFixedBid = bidType === "FIXED";

  const edit = () => {
    setEditing(true);
    inputRef.current.focus();
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "KEYWORDS",
    id: id ? parseInt(id) : 1,
    filters: keywordFilters(t),
    isFixedBid,
    min: chosenMinBid(),
  };

  const updateBid = async () => {
    try {
      await api.post(`campaigns/${id}/relations`, {
        campaign_keywords: {
          status: selected.status,
          keywords: [{ id: selected.keywordID, bid: parseFloat(selected.bid) }],
        },
      });
      queryClient.refetchQueries({ active: true });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType="KEYWORDS"
        />
      ),
    },
    {
      title: t("components.table.keywords.keyword"),
      dataIndex: "name",
    },
    {
      title: t("common.table.status"),
      dataIndex: "state",
      render: (value: any) => (
        <RetailStateColumn value={value} type="keywords" />
      ),
    },
    {
      title: t("components.table.keywords.matchType"),
      dataIndex: "match_type",
      render: (value: "EXACT" | "PHRASE" | "BROAD") => (
        <span>{t(`common.${value.toLowerCase()}`)}</span>
      ),
    },
    {
      title: t("common.table.bid"),
      dataIndex: "bid",
      width: 125,
      render: (value: number, records: any) =>
        isFixedBid ? (
          <div className="flex">
            <RetailMoneyColumn value={value} />
            <div
              data-cy="keyword-bid"
              className={cc([cm.iconContainer, cm.editContainer, "flex"])}
              onClick={(event: any) => {
                setSelected({ ...records, bid: value.toFixed(2) });
                edit();
                setPopupPositions({ top: event.clientY, left: event.clientX });
              }}
            >
              <PencilOutlined />
            </div>
          </div>
        ) : (
          <span>{t("components.table.keywords.dynamic")}</span>
        ),
    },
    {
      title: t("common.table.impression"),
      dataIndex: "impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    /* {
      title: t("common.table.viewable_impressions"),
      dataIndex: "viewable_impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    }, */
    {
      title: t("common.table.click"),
      dataIndex: "clicks",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.CTR"),
      dataIndex: "CTR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.spent"),
      dataIndex: "spend",
      render: (value: any) => (
        <RetailMoneyColumn value={value} spendColumn={true} />
      ),
    },
    {
      title: t("marketplaceDependentTable.cpc"),
      dataIndex: "CPC",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.cpm"),
      dataIndex: "CPM",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.sold"),
      dataIndex: "sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.roas"),
      dataIndex: "ROAS",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
  ];
  const extraColumns = (t: any) => [
    {
      title: t("components.reportForm.metric.sumDirectSaleAmount"),
      dataIndex: "direct_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSaleAmount"),
      dataIndex: "indirect_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sale"),
      dataIndex: "sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumDirectSale"),
      dataIndex: "direct_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSale"),
      dataIndex: "indirect_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.acos"),
      dataIndex: "ACoS",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    //dönüşüm oranı
    {
      title: t("components.reportForm.metric.CVR"),
      dataIndex: "CVR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
  ];

  const reset = () => {
    setKeywords([]);
    setIsVisible(false);
  };

  const updateValue = async () => {
    const config = {
      keywords:
        keywords!.length > 0
          ? keywords!.map((keyword) => {
              return {
                text: keyword.text,
                match_type: keyword.match_type,
                bid:
                  bidType === "FIXED"
                    ? parseFloat(keyword.bid || keyword.suggested_bid)
                    : null,
              };
            })
          : null,
    };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addNegativeKeywords = async () => {
    await mutateAsync();
    queryClient.refetchQueries({ active: true });
    reset();
  };

  const onOk = () => addNegativeKeywords();

  const open = () => setIsVisible(true);

  const handleOk = () => {
    handleBlur();
    updateBid();
  };

  useEffect(
    () =>
      setError(
        parseFloat(selected && selected.bid) >= parseFloat(chosenMinBid()) &&
          inputRef.current.input.value !== ""
      ),
    [selected, chosenMinBid]
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSelected({ ...selected, bid: target.value });

  return (
    <>
      {keywordType === "AUTO" && (
        <Row className={cm.row}>
          <Col span={24}>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.acc.campaignDetails.keywordTitle")}
            </RetailTitle>
          </Col>
          <Col span={24}>
            <RetailText className={cm.text} family="poppins" size="xs">
              {t("pages.acc.campaignDetails.keywordText")}
            </RetailText>
          </Col>
        </Row>
      )}

      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.keywordsBtn"),
          onClick: open,
        }}
        placeholder={t("pages.acc.campaignDetails.keywordsPlaceholder")}
        tableConfig={tableConfig}
        columns={() => columns(t)}
        extraColumns={() => extraColumns(t)}
        addBtnVisible={keywordType === "SEARCH" ? true : false}
      />
      <div
        className={cc([cm.editPopup, "flex", error ? "" : cm.error])}
        style={{
          opacity: editing ? "1" : "0",
          pointerEvents: editing ? "all" : "none",
          top: `${popupPositions.top}px`,
          left: `${popupPositions.left - 20}px`,
        }}
      >
        {!error && <RetailBidTooltip value={chosenMinBid()} type="general" />}
        <Input
          data-cy="keyword-bid-input"
          ref={inputRef}
          className={cc([cm.input, error ? "" : cm.error])}
          onKeyDownCapture={(e) => validateNumber(e, true)}
          onChange={handleChange}
          autoFocus={true}
          value={selected?.bid}
        />
        <div
          data-cy="keyword-bid-input-ok"
          className={cc([
            cm.iconContainer,
            cm.okContainer,
            "flex",
            error ? "" : cm.error,
          ])}
          onClick={handleOk}
        >
          <CheckOutlined />
        </div>
        <div
          className={cc([cm.iconContainer, cm.closeContainer, "flex"])}
          onClick={handleBlur}
        >
          <CloseOutlined />
        </div>
      </div>

      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t("pages.acc.campaignDetails.keywordsSub")}
        onCancel={reset}
        onOk={onOk}
        type="KEYWORDS"
      >
        <SelectKeywordsTable isDynamic={isFixedBid} />
      </CampaignDetailsModal>
    </>
  );
};

export default KeywordsTable;
