import axios from "axios";
import jwt from "jwt-decode";
import {
	createContext,
	useEffect,
	useState,
} from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { Account } from "../../utils/types";

export type Auth = {
  authToken: string;
  local: any;
  setLocal(data: any): void;
  setAuthToken(token: string): void;
  authInfo: any;
  setAuthInfo(data: any): void;
  logout(): void;
  showAdminBar: boolean;
  accountIndex: string;
  sso: string | undefined;
  navigateDependingOnAccount(account: Account): void;
  getUser(token: string): void;
};

export const AuthContext = createContext<Auth | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  let navigate = useNavigate();

  const [authToken, setAuthToken] = useState("");

  const [local, setLocal] = useState<string | any>("");

  const [authInfo, setAuthInfo] = useState<any>(null);

  const accountIndex = localStorage.getItem("account_index") || "0";

  const sso = decodeURIComponent(window.location.href).split("token=")[1];

  const showAdminBar =
    local &&
    local.id &&
    !pathname.includes("admin") &&
    authInfo[accountIndex]?.account_type === "MARKETPLACE";

  const navigateDependingOnAccount = (account: Account) => {
    if (account.account_type === "ADVERTISER") navigate("/campaigns");
    else navigate("/admin/campaigns");
  };

  const handleLoginSuccess = (token: any, response: any) => {
    setAuthToken(token);
    setAuthInfo(response.data.accounts);
    localStorage.setItem("auth_token", JSON.stringify(token));
    localStorage.setItem("auth_email", JSON.stringify(response.data.email));
    //If the user has more than one account, navigate to accounts page
    if (response.data.accounts.length > 1 && !sso) navigate("/accounts");
    else {
      localStorage.setItem("account_index", JSON.stringify(0));
      navigateDependingOnAccount(response.data.accounts[0]);
    }
  };

  const getUser = async (token: any) => {
    const data = jwt(token) as any;
    try {
      const response = await axios.get(`/api/users/${data.user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      handleLoginSuccess(token, response);
      localStorage.setItem("auth_info", JSON.stringify(response.data.accounts));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("auth_token");
    const auth_info = localStorage.getItem("auth_info");
    const lc = localStorage.getItem("advertiser_id");

    if (token !== null) setAuthToken(JSON.parse(token));
    if (token !== null) setAuthInfo(JSON.parse(auth_info!));
    if (lc !== null) setLocal(JSON.parse(lc));
  }, []);

  useEffect(() => {
    //Marketplace name
    const name = (window as any).marketplaceConfig.name || "ACME";

    //Special Teknosa paths
    const paths = ["/", "/case-study", "/case-study/"];
    //Auth paths
    const authPaths = ["/signup", "/forget-password", "/new-password", "/welcome"];

    // Check for user authentication
    const isUserLoggedIn = localStorage.getItem("auth_token") !== null;

    //Navigate to login page if a user is not logged in, except Teknosa paths and auth paths
    if (!isUserLoggedIn) {
      if (
        authPaths.includes(pathname) ||
        (name === "Teknosa" && paths.includes(pathname))
      )
        return;
      navigate("/login");
      cleanStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanStorage = () => {
    const localStorageKeysForCleaning = [
      "auth_token",
      "auth_email",
      "auth_info",
      "advertiser_id",
      "account_index",
      "_grecaptcha",
    ];
    localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
  };

  const logout = async () => {
    try {
      await axios.get("/auth/logout", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      navigate("/login");
      setAuthToken("");
      setLocal(null);
      setAuthInfo(null);
      cleanStorage();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken,
        authInfo,
        setAuthInfo,
        local,
        setLocal,
        logout,
        showAdminBar,
        accountIndex,
        sso,
        navigateDependingOnAccount,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
