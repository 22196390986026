import Modal, { ModalProps } from "antd/lib/modal";
import React, {
	forwardRef,
	useContext,
	useImperativeHandle,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkSuccessOutlined.svg";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailCampaignModalHandles {
  open(): void;
  close(): void;
}

export interface RetailCampaignModalProps extends ModalProps {
  navigateData: {
    id: string;
    name: string;
    ad_type: string;
  };
  advertiserData?: {
    id: string;
    name: string;
  };
  onClick(): void;
}

const RetailCampaignModal: React.ForwardRefRenderFunction<
  RetailCampaignModalHandles,
  RetailCampaignModalProps
> = ({ navigateData, advertiserData, onClick }, ref) => {
  const { t } = useTranslation();

  const { setLocal } = useContext(AuthContext) as Auth;

  const { pathname } = useLocation();

  let navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const close = () => setIsVisible(false);

  const open = () => setIsVisible(true);

  const okay = () => {
    setIsVisible(false);
    pathname.includes("admin") &&
      setLocal({ id: advertiserData?.id, name: advertiserData?.name });
    navigate(`/campaign/${navigateData.id}`, {
      state: {
        breadcrumb: true,
        breadcrumbType: "campaign",
        value: navigateData.name,
        ad_type: navigateData.ad_type,
      },
    });
    onClick();
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal
      visible={isVisible}
      className={cm.modal}
      onOk={close}
      onCancel={close}
      centered
      footer={[
        <RetailMainButton hasBackground={true} onClick={okay} className={cm.ok}>
          {t("common.okay")}
        </RetailMainButton>,
      ]}
    >
      <div className={cm.container} data-cy="campaign-model">
        <div className={cm.check}>
          <CheckOutlined />
        </div>
        <RetailTitle className={cm.title} level={3}>
          {t("components.modal.campaign.success")}
        </RetailTitle>
        <div className={cm.content}>
          <RetailText family="poppins" size="xxs" className={cm.popupText}>
            {t("components.modal.campaign.successText")}
          </RetailText>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(RetailCampaignModal);
