import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios from "axios";
import cc from "classcat";
import {
	useCallback,
	useState,
} from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkSuccessOutlined.svg";
import { ReactComponent as KeyOutlined } from "../../../assets/icons/keyOutlined.svg";
import { ReactComponent as LeftOutlined } from "../../../assets/icons/leftOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import RetailNotification from "../../../components/Notification";
import RetailTitle from "../../../components/Typography/RetailTitle";
import cm from "./style.module.scss";

const ForgetPasswordPage = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [success, setSuccess] = useState(false);

  const [token, setToken] = useState("");

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  const onFinish = async () => {
    try {
      await axios.post("/api/forgot_password", {
        email: form.getFieldValue("email"),
        recaptchaResponse: token,
        host_name: window.location.hostname,
      });
      setSuccess(true);
    } catch (err: any) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.auth.password.error")
      );
    }
  };

  return (
    <RetailAuthLayout>
      {success ? (
        <section className={cc(["flex", cm.success])}>
          <RetailTitle level={2} className={cc(["flex", cm.check])}>
            <span className={cc(["flex", cm.icon])}>
              <CheckOutlined />
            </span>
            <span>{t("pages.auth.newPassword.check")}</span>
          </RetailTitle>
          <article className="flex">
            <p>{t("pages.auth.newPassword.message")}</p>
            <RetailMainButton hasBackground className={cm.btn}>
              <Link to="/login">{t("common.okay")}</Link>
            </RetailMainButton>
          </article>
        </section>
      ) : (
        <div className={cm.form}>
          <section className={cc(["flex", cm.title])}>
            <div className={cm.icon}>
              <KeyOutlined />
            </div>
            <h1>{t("pages.auth.password.title")}</h1>
          </section>

          <Form form={form} onFinish={onFinish} autoComplete="off">
            <p>{t("pages.auth.password.text")}</p>
            <Form.Item
              label=""
              name="email"
              className={cm.formItem}
              rules={[
                {
                  required: true,
                  message: t("pages.auth.password.mailInputErr"),
                  type: "email",
                },
              ]}
            >
              <Input placeholder={t("pages.auth.password.mail")} />
            </Form.Item>
            <Form.Item>
              <RetailMainButton
                hasBackground
                htmlType="submit"
                className={cm.btn}
              >
                {t("pages.auth.password.reset")}
              </RetailMainButton>
            </Form.Item>
            <GoogleReCaptcha
              onVerify={onVerify}
              refreshReCaptcha={refreshReCaptcha}
            />
            <div className={cm.return}>
              <LeftOutlined />
              <Link to="/login">{t("pages.auth.password.return")}</Link>
            </div>
          </Form>
        </div>
      )}
    </RetailAuthLayout>
  );
};

export default ForgetPasswordPage;
