import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import ar from "./ar";
import beymen_en from "./beymen_en";
import beymen_tr from "./beymen_tr";
import appier_en from "./appier_en";
import appier_tr from "./appier_tr";
import en from "./en";
import koctas_en from "./koctas_en";
import koctas_tr from "./koctas_tr";
import tr from "./tr";

const lang = navigator?.language?.slice(0, 2) === "tr" ? "tr" : "en";

const LANG_SELECTION = localStorage.getItem("retail_lang") || lang;

const name = (window as any).marketplaceConfig?.name?.toLowerCase() || "ACME";

const marketplaceTR = () => {
  switch (name) {
    case "beymen":
      return { ...tr, ...beymen_tr };
    case "koçtaş":
      return { ...tr, ...koctas_tr };
    case "appier":
    case "mannings":
      return { ...tr, ...appier_tr };
    default:
      return tr;
  }
};

const marketplaceEN = () => {
  switch (name) {
    case "beymen":
      return { ...en, ...beymen_en };
    case "koçtaş":
      return { ...en, ...koctas_en };
    case "appier":
    case "mannings":
      return { ...en, ...appier_en };
    default:
      return en;
  }
};

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: marketplaceEN(),
    },
    tr: {
      translation: marketplaceTR(),
    },
    ar: {
      translation: ar,
    },
  },
  lng: LANG_SELECTION,
  fallbackLng: lang,
  interpolation: {
    escapeValue: false,
  },
});

i18next.on("languageChanged", (lng: string) => {
  localStorage.setItem("retail_lang", lng);
});

export default i18next;
