import { Spin } from "antd";
import axios from "axios";
import cc from "classcat";
import jwt from "jwt-decode";
import {
	useContext,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as KeyOutlined } from "../../../assets/icons/keyOutlined.svg";
import { ReactComponent as UserOutlined } from "../../../assets/icons/userOutlined.svg";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import { Account } from "../../../utils/types";
import { addAccount } from "../LoginPage";
import cm from "./style.module.scss";

const AccountsPage = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const {
    authInfo,
    authToken,
    logout,
    navigateDependingOnAccount,
    setAuthInfo,
  } = useContext(AuthContext) as Auth;

  const selectAccount = (index: number) => {
    localStorage.setItem("account_index", JSON.stringify(index));
    navigateDependingOnAccount(authInfo[index]);
  };

  const access_token = localStorage.getItem("access_token") || "";

  const getUser = async (token: string) => {
    const data = jwt(token) as any;
    try {
      const response = await axios.get(`/api/users/${data.user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAuthInfo(response.data.accounts);
      localStorage.setItem("auth_info", JSON.stringify(response.data.accounts));
    } catch (err) {
      console.log(err);
    }
  };

  const createAccount = async () => {
    try {
      setLoading(true);
      await addAccount(access_token, authToken, t);
      await getUser(authToken);
      localStorage.removeItem("access_token");
      localStorage.removeItem("email_from_url");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (access_token !== "") createAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  return (
    <Spin spinning={loading}>
      <RetailAuthLayout>
        <div className={cm.wrapper}>
          <section className={cc(["flex", cm.textContainer])}>
            <div className={cc(["flex", cm.iconContainer])}>
              <KeyOutlined />
            </div>
            <article>
              <h1>{t("pages.auth.accounts.title")}</h1>
              <p>{t("pages.auth.accounts.text")}</p>
            </article>
          </section>

          <section className={cm.accounts}>
            {authInfo?.map((account: Account, index: number) => (
              <div
                key={account.id}
                className={cc(["flex", cm.account])}
                onClick={() => {
                  selectAccount(index);
                }}
              >
                <p className={cc(["flex", cm.userIcon])}>
                  <UserOutlined />
                </p>
                <p>{account?.account_name}</p>
              </div>
            ))}
          </section>

          <button className={cm.button} onClick={logout}>
            {t("pages.auth.accounts.logout")}
          </button>
        </div>
      </RetailAuthLayout>
    </Spin>
  );
};

export default AccountsPage;
