import Steps from "antd/lib/steps";
import cc from "classcat";
import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import { ReactComponent as CloseOutlined } from "../../../../assets/icons/closeOutlined.svg";
import { ReactComponent as RightWhiteOutlined } from "../../../../assets/icons/rightWhiteOutlined.svg";
import { Auth, AuthContext } from "../../../../context/AuthProvider";
import RetailAdminBar from "../../../Bar/RetailAdminBar";
import RetailInfoBar from "../../../Bar/RetailInfoBar";
import RetailMainButton from "../../../Button/RetailMainButton";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailStepsNavbarHandles {
  next(index: number): void;
  prev(): void;
}

export interface RetailStepsNavbarProps {
  type: "report" | "campaign";
  stepInformation: any;
  current: number;
  setCurrent(num: number): void;
  resetStates(): void;
  errorStates?: any;
  check?: any;
  info?: any;
  form?: any;
  switchMovable?: boolean;
}

const { Step } = Steps;

const RetailStepsNavbar: React.ForwardRefRenderFunction<
  RetailStepsNavbarHandles,
  RetailStepsNavbarProps
> = (
  {
    type,
    stepInformation,
    current,
    setCurrent,
    resetStates,
    check,
    info,
    form,
    switchMovable,
  },
  ref
) => {
  const { t } = useTranslation();

  const location = useLocation();

  const { local } = useContext(AuthContext) as Auth;

  const mode = location.pathname.includes("create") ? "create" : "edit";

  const componentRef = useRef<any>(false);

  const isOnStart = current === stepInformation.length - 1;

  let navigate = useNavigate();

  const next = (index: number) => {
    if (!switchMovable && isOnStart) {
      form.submit();
    } else if (!switchMovable && !isOnStart) {
      setCurrent(index! === current ? index + 1 : index!);
      window.scrollTo(0, 0);
    } else {
      form.submit();
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    window.scrollTo(0, 0);
  };

  useImperativeHandle(ref, () => ({
    next,
    prev,
  }));

  const handleClose = () => {
    typeof location.state === "number" || location.pathname.includes("admin")
      ? navigate(`/admin/${type}s`)
      : navigate(`/${type}s`);
    resetStates!();
  };

  const reportSubmit = () => {
    check();
    form.submit();
  };

  const handleClick = (index: number) => {
    if (stepInformation.length === 1 || current === index) return;
    else if (current === stepInformation.length - 1) return prev();
    return next(index);
  };
  return (
    <>
      {local && local.id !== undefined && <RetailAdminBar />}
      <nav
        className={cc([
          "flex",
          cm.nav,
          local && local.id ? cm.advertiserNav : "",
        ])}
        ref={componentRef}
      >
        <div className="flex">
          <span
            className={cc(["flex", cm.close])}
            onClick={handleClose}
            data-test={`${type}-close`}
          >
            <CloseOutlined />
          </span>
          <div>
            <RetailTitle level={5} className={cm.name}>
              {info.name}
            </RetailTitle>
            {info.advertiser && (
              <RetailText
                weight="medium"
                size="s"
                className={cc(["flex", cm.text])}
              >
                {t("components.stepsNavbar.advertiser")}
                <strong>{info.advertiser}</strong>
              </RetailText>
            )}
          </div>
        </div>
        <div
          className={cc([
            cm.container,
            stepInformation.length === 1 ? cm.small : cm.wide,
          ])}
        >
          <Steps current={current} type="navigation" className="nav-steps">
            {stepInformation.map((item: any, index: number) => (
              <Step
                key={item.title}
                title={item.title}
                subTitle={item.sub}
                onClick={() => handleClick(index)}
                data-test={`${type}-step`}
              />
            ))}
          </Steps>
        </div>
        <div className="steps-action">
          <RetailMainButton
            hasBackground
            className={cc(["flex", cm.btn])}
            data-test={isOnStart ? `create-${type}` : `continue-${type}`}
            onClick={type === "campaign" ? () => next(current) : reportSubmit}
          >
            <p className={cm.title}>
              <span className={cm.span}>
                {!isOnStart
                  ? t("common.continue")
                  : t(`components.stepsNavbar.${type}_${mode}`)}
              </span>
              {!isOnStart && (
                <span className={cm.span}>
                  {t("components.stepsNavbar.step", {
                    value: `${current + 2} / ${stepInformation.length}`,
                  })}
                </span>
              )}
            </p>
            <RightWhiteOutlined />
          </RetailMainButton>
        </div>
      </nav>
      {type === "campaign" ? <RetailInfoBar type="create" /> : null}
    </>
  );
};

export default forwardRef(RetailStepsNavbar);
