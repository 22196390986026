import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import RetailTable from "../../../../components/Table/RetailTable";
import { placementFilters } from "../../../../utils/filters";

const AdPlacementTable = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "PLACEMENTS",
    id: id ? parseInt(id) : 1,
    filters: placementFilters(t),
  };

  const columns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      width: 100,
      disabled: true,
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType="PLACEMENTS"
        />
      ),
    },
    {
      title: t("common.table.placement"),
      dataIndex: "text",
      disabled: true,
      render: (value: "IN_SEARCH" | "IN_CATEGORIES") =>
        t(`pages.acc.campaignDetails.${value.toLowerCase()}`),
    },
    {
      title: t("common.table.acos"),
      dataIndex: "ACoS",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.cpc"),
      dataIndex: "CPC",
      render: (value: any) => <RetailMoneyColumn value={value} isZero={true} />,
    },
    {
      title: t("marketplaceDependentTable.cpm"),
      dataIndex: "CPM",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.ctr"),
      dataIndex: "CTR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.roas"),
      dataIndex: "ROAS",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.click"),
      dataIndex: "clicks",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.impression"),
      dataIndex: "impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    /* {
      title: t("common.table.viewable_impressions"),
      dataIndex: "viewable_impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    }, */
    {
      title: t("components.reportForm.metric.sale"),
      dataIndex: "sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.sold"),
      dataIndex: "sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} isZero={true} />,
    },
    {
      title: t("common.table.spent"),
      dataIndex: "spend",
      render: (value: any) => (
        <RetailMoneyColumn value={value} isZero={true} spendColumn={true} />
      ),
    },
  ];

  return (
    <RetailTable
      placeholder={t("pages.acc.campaignDetails.placementPlaceholder")}
      tableConfig={tableConfig}
      columns={() => columns(t)}
      addBtnVisible={false}
    />
  );
};

export default AdPlacementTable;
