import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import cc from "classcat";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

import useApi from "../../../api";
import RetailStatusColumn from "../../../components/Column/RetailStatusColumn";
import RetailDrawer from "../../../components/Drawer/RetailDrawer";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailSelect from "../../../components/Select/RetailSelect";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import cm from "./style.module.scss";

const AdPlacementPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const [visible, setVisible] = useState<{
    open: boolean;
    mode: "CREATE" | "UPDATE";
  }>({
    open: false,
    mode: "CREATE",
  });

  const [placement, setPlacement] = useState<any | null>(null);

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const [requiredFields, setRequiredFields] = useState({
    name: "",
    ad_format: "",
    max_ads: "",
  });

  const { options, fetchData, handleScroll } = useInfiniteScroll(
    "ad_placements/sizes",
    requiredFields.ad_format === "DISPLAY"
  );

  const targetingOptions = [
    {
      label: t("pages.admin.placement.search"),
      value: "IN_SEARCH",
    },
    {
      label: t("pages.admin.placement.category"),
      value: "IN_CATEGORY",
    },
    {
      label: t("pages.admin.placement.homepage"),
      value: "IN_HOME_PAGE",
    },
    {
      label: t("pages.admin.placement.detail"),
      value: "IN_PRODUCT_DETAIL_PAGE",
    },
    {
      label: t("pages.admin.placement.collection"),
      value: "IN_COLLECTION",
    },
  ];

  useEffect(() => {
    if (placement !== null && visible.mode === "UPDATE") {
      setRequiredFields({
        name: placement?.name,
        ad_format: placement?.ad_format,
        max_ads: placement?.max_ads,
      });
      form.setFieldsValue({
        name: placement?.name,
        ad_format: placement?.ad_format,
        max_ads: placement?.max_ads,
        recommended_size:
          placement.recommended_size !== undefined
            ? [placement?.recommended_size]
            : [],
        sizes: placement?.sizes,
        cross_sell_blocking_type: placement.cross_sell_blocking_type,
        type: placement.type,
      });
    }
  }, [visible.mode, placement, form]);

  const placementValues = (values: any) => ({
    status: visible.mode === "CREATE" ? "ACTIVE" : placement?.status,
    name: requiredFields.name,
    ad_format: requiredFields.ad_format,
    max_ads: parseInt(requiredFields.max_ads as string),
    recommended_size: values?.recommended_size
      ? values?.recommended_size[0]
      : null,
    sizes: values.sizes,
    cross_sell_blocking_type: values.cross_sell_blocking_type,
    type: values.type,
  });

  const open = () => setVisible({ open: true, mode: "CREATE" });

  const open_update = (records: any) => {
    setVisible({
      open: true,
      mode: "UPDATE",
    });
    setPlacement(records);
  };

  const tableConfig = {
    url: "ad_placements",
    isRelation: false,
    to: open_update,
  };

  const crossOptions = [
    {
      label: t("pages.admin.placement.crossProduct"),
      value: "PRODUCT",
    },
    {
      label: t("pages.admin.placement.crossBlock"),
      value: "DONT_BLOCK",
    },
  ];

  const columns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      render: (value: any, records: any) => (
        <RetailStatusColumn records={records} url="ad_placements" />
      ),
      disabled: true,
    },
    {
      title: t("common.table.id"),
      dataIndex: "custom_key",
      disabled: true,
    },
    {
      title: t("common.table.name"),
      dataIndex: "name",
      disabled: true,
    },
    {
      title: t("common.table.ad_format"),
      dataIndex: "ad_format",
      render: (value: "DISPLAY" | "PRODUCT") =>
        value ? t(`pages.admin.placement.${value?.toLowerCase()}`) : "-",
    },
    {
      title: t("common.table.max_ads"),
      dataIndex: "max_ads",
      render: (value: number) => (value ? value : "-"),
    },
    {
      title: t("common.table.recommended_size"),
      dataIndex: "recommended_size",
      render: (value: string) => (!value || value === "" ? "-" : value),
    },
    {
      title: t("common.table.sizes"),
      dataIndex: "sizes",
      render: (value: string[]) => (
        <Row className={cm.sizes}>
          {value ? (
            value.map((item: string) => (
              <RetailText size="xs" family="poppins">
                {item}
              </RetailText>
            ))
          ) : (
            <RetailText size="xs" family="poppins">
              -
            </RetailText>
          )}
        </Row>
      ),
    },
  ];

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setRequiredFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onClose = () => {
    form.resetFields();
    setRequiredFields({ name: "", ad_format: "", max_ads: "" });
    setVisible({ open: false, mode: "CREATE" });
  };

  const createPlacement = async (values: any) => {
    try {
      await api.post("ad_placements", placementValues(values));
      queryClient.refetchQueries("table");
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const updatePlacement = async (values: any) => {
    const response = await api.patch(
      `ad_placements/${placement?.id}`,
      placementValues(values)
    );
    return response;
  };

  const { mutateAsync } = useMutation(updatePlacement);

  const changePlacement = async (values: any) => {
    try {
      await mutateAsync(values);
      queryClient.refetchQueries("table");
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const onFinish = (values: any) =>
    visible.mode === "CREATE"
      ? createPlacement(values)
      : changePlacement(values);

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          columns={() => columns(t)}
          tableConfig={tableConfig}
          button={{
            title: t("pages.admin.placement.add"),
            onClick: open,
          }}
        />
      </Row>
      <RetailDrawer
        title={
          visible.mode === "CREATE"
            ? t("pages.admin.placement.add")
            : t("pages.admin.placement.update")
        }
        visible={visible.open}
        onClose={onClose}
        onOk={form.submit}
      >
        <Form
          autoComplete="off"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.placement.settings")}
          </RetailTitle>
          <RetailText size="xxxs" family="poppins" className={cm.subtext}>
            {t("pages.admin.placement.settingsText")}
          </RetailText>

          {/*
           *Name input
           */}
          <RetailFormInput
            isFocused={requiredFields.name !== ""}
            label={t("pages.admin.placement.name")}
            name="name"
            help={t("pages.admin.placement.nameText")}
            className="floating"
            rules={[{ required: true }]}
          >
            <Input
              className="floating"
              name="name"
              onChange={handleChange}
              value={requiredFields.name}
            />
          </RetailFormInput>

          {/*
           *Max Ads input
           */}
          <RetailFormInput
            isFocused={requiredFields.max_ads !== ""}
            label={t("pages.admin.placement.max_ads")}
            name="max_ads"
            help={t("pages.admin.placement.maxText")}
            className="floating"
            rules={[{ required: true }]}
          >
            <Input
              className="floating"
              name="max_ads"
              onChange={handleChange}
              value={requiredFields.max_ads}
            />
          </RetailFormInput>

          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.placement.format")}
          </RetailTitle>
          <RetailText size="xxxs" family="poppins" className={cm.subtext}>
            {t("pages.admin.placement.formatText")}
          </RetailText>

          {/*
           *Placement input
           */}
          <Form.Item
            name="ad_format"
            className={cc(["flex", cm.placement])}
            rules={[
              {
                required: true,
                message: t("pages.admin.placement.formatError"),
              },
            ]}
          >
            <Radio.Group onChange={handleChange} name="ad_format">
              <Radio value="PRODUCT" className="form-radio">
                <RetailTitle level={5} className={cm.title}>
                  {t("pages.admin.placement.product")}
                </RetailTitle>
                <RetailText className={cm.text} size="xxxs">
                  {t("pages.admin.placement.sponsoredText")}
                </RetailText>
              </Radio>
              <Radio value="DISPLAY" className="form-radio">
                <RetailTitle level={5} className={cm.title}>
                  {t("pages.admin.placement.display")}
                </RetailTitle>
                <RetailText className={cm.text} size="xxxs">
                  {t("pages.admin.placement.displayText")}
                </RetailText>
                {requiredFields.ad_format === "DISPLAY" && (
                  <>
                    <div className={cm.selectContainer}>
                      <h6 className={cm.secondaryTitle}>
                        {t("pages.admin.placement.suggestedTitle")}
                      </h6>
                      <RetailText size="xxxs" className={cm.selectLabel}>
                        {t("pages.admin.placement.suggestedText")}
                      </RetailText>
                      <Form.Item
                        name="recommended_size"
                        rules={[
                          {
                            type: "array",
                            max: 1,
                            message: t("pages.admin.placement.suggestedError"),
                          },
                        ]}
                      >
                        <RetailSelect
                          onClick={(e) => e.preventDefault()}
                          onPopupScroll={handleScroll}
                          options={options?.map((opt: string) => ({
                            label: opt,
                            value: opt,
                          }))}
                          mode="tags"
                          placeholder={t(
                            "pages.admin.placement.suggestedTitle"
                          )}
                          className={cm.tags}
                          listHeight={350}
                        />
                      </Form.Item>
                    </div>

                    <div className={cm.selectContainer}>
                      <h6 className={cm.secondaryTitle}>
                        {t("pages.admin.placement.acceptedTitle")}
                      </h6>
                      <RetailText size="xxxs" className={cm.selectLabel}>
                        {t("pages.admin.placement.acceptedText")}
                      </RetailText>
                      <Form.Item
                        name="sizes"
                        rules={[
                          {
                            type: "array",
                            min: 1,
                            max: 100,
                            required: true,
                            message: t("pages.admin.placement.acceptedError"),
                          },
                        ]}
                      >
                        <RetailSelect
                          onClick={(e) => e.preventDefault()}
                          onPopupScroll={handleScroll}
                          options={options?.map((opt: string) => ({
                            label: opt,
                            value: opt,
                          }))}
                          mode="tags"
                          placeholder={t("pages.admin.placement.acceptedTitle")}
                          className={cm.tags}
                          listHeight={350}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </Radio>
              <Radio value="CROSS_SELL" className="form-radio">
                <RetailTitle level={5} className={cm.title}>
                  {t("pages.admin.placement.cross_sell")}
                </RetailTitle>
                <RetailText className={cm.text} size="xxxs">
                  {t("pages.admin.placement.crossText")}
                </RetailText>
                {requiredFields.ad_format === "CROSS_SELL" && (
                  <div className={cm.selectContainer}>
                    <h6 className={cm.secondaryTitle}>
                      {t("pages.admin.placement.crossTitle")}
                    </h6>
                    <RetailText size="xxxs" className={cm.selectLabel}>
                      {t("pages.admin.placement.crossSub")}
                    </RetailText>
                    <Form.Item
                      name="cross_sell_blocking_type"
                      rules={[
                        {
                          required: true,
                          message: t("pages.admin.placement.crossError"),
                        },
                      ]}
                    >
                      <RetailSelect
                        options={crossOptions}
                        placeholder={t(
                          "pages.admin.placement.crossPlaceholder"
                        )}
                        onClick={(e) => e.preventDefault()}
                      />
                    </Form.Item>
                  </div>
                )}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.placement.targeting")}
          </RetailTitle>
          <RetailText size="xxxs" family="poppins" className={cm.subtext}>
            {t("pages.admin.placement.targetingText")}
          </RetailText>

          <Form.Item
            name="type"
            rules={[
              { required: true, message: t("pages.admin.placement.typeError") },
            ]}
          >
            <RetailSelect
              placeholder={t("pages.admin.placement.targetingPlaceholder")}
              options={targetingOptions}
            />
          </Form.Item>
        </Form>
      </RetailDrawer>
    </RetailPageContainer>
  );
};

export default AdPlacementPage;
