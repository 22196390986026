import Col from "antd/lib/col";
import ConfigProvider from "antd/lib/config-provider";
import en from "antd/lib/locale/en_US";
import tr from "antd/lib/locale/tr_TR";
import Row from "antd/lib/row";
import Table from "antd/lib/table";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { ReactComponent as FilterOutlined } from "../../../../assets/icons/filterOutlined.svg";
import RetailExportBottomBar from "../../../../components/Bar/RetailExportBottomBar";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailFilterTag from "../../../../components/Tag/RetailFilterTag";
import RetailText from "../../../../components/Typography/RetailText";
import { numFormatter } from "../../../../utils/helpers";
import cm from "../style.module.scss";

type setSort = {
  sortValue: string;
  order: string;
} | null;
export interface ReportDetailsResultTabProps {
  data: any;
  setSort(value: setSort): void;
  setIsExported(value: boolean): void;
  setFormat(value: "excel" | "pdf" | "csv"): void;
  changePageSize(current: number, value: number): void;
}

const ReportDetailsResultTab = ({
  data,
  changePageSize,
  setSort,
  setIsExported,
  setFormat,
}: ReportDetailsResultTabProps) => {
  const { t, i18n } = useTranslation();

  const switchColumn = (value: any, col: string) => {
    switch (col) {
      case "year":
        return moment(value).format("YYYY");
      case "month":
        return moment(value).locale(i18n.language).format("MMMM YYYY");
      case "week":
        return `
        ${t("components.table.result.weekOf", {
          value: moment(value).format("DD-MM-YYYY"),
        })}`;
      case "day":
        return moment(value).format("DD-MM-YYYY");
      case "hour":
        return moment(value).format("HH:mm DD-MM-YYYY");
      case "ACoS":
      case "CTR":
      case "CVR":
        return `%${numFormatter(value ? parseFloat(value) : 0)}`;
      case "CPC":
      case "CPM":
      case "sumDirectSaleAmount":
      case "sumIndirectSaleAmount":
      case "sumSaleAmount":
      case "sumSpend":
        return <RetailMoneyColumn value={value} isZero={true} />;
      case "sumImpression":
      case "sumViewableImpression":
      case "sumClick":
      case "sumSale":
      case "sumIndirectSale":
      case "sumDirectSale":
      case "RoAS":
        return `${numFormatter(value ? parseFloat(value) : 0)}`;
      case "campaignBidType":
        return t(`pages.acc.campaigns.table.${value.toLowerCase()}`);
      case "campaignBudgetType":
        return t(
          `components.campaignForm.firstStep.${value.toLowerCase()}BudgetLabel`
        );
      case "placementText":
        return value
          ? t(`components.table.result.${value?.toLowerCase()}`)
          : "-";
      case "keywordMatchType":
      case "customerType":
        return t(`common.${value?.toLowerCase()}`);
      case "campaignAdType":
        return t(
          `components.modal.selectCampaign.sponsored_${value?.toLowerCase()}_title`
        );
      default:
        return value;
    }
  };

  const colTitle = (col: string) => {
    switch (col) {
      case "CPC":
      case "CPM":
      case "ROAS":
      case "RoAS":
        return t(`marketplaceDependentTable.${col?.toLowerCase()}`);
      default:
        return t(`components.table.result.${col}`);
    }
  };

  const columns =
    data !== undefined
      ? data.data.columns.map((col: string) => ({
          dataIndex: col,
          title: colTitle(col),
          sorter: col,
          render: (value: any, records: any) => (
            <>{value !== null ? switchColumn(value, col) : "-"}</>
          ),
        }))
      : [];

  const tableExport = (value: "excel" | "pdf" | "csv") => {
    setFormat(value);
    setIsExported(true);
  };

  return (
    <>
      {data && data.data.report_filters !== null && (
        <Row className={cm.tagContainer}>
          <Col className="flex">
            <FilterOutlined />
            <RetailText size="xs" family="poppins">
              {t("components.reportForm.filters")}
            </RetailText>
          </Col>
          <Col className="flex">
            {data.data.report_filters.map((tag: any) => (
              <RetailFilterTag
                tag={tag}
                type="FILTER"
                key={tag.id}
                closable={false}
                isReportResult={true}
              />
            ))}
          </Col>
        </Row>
      )}
      <ConfigProvider locale={i18n.language === "tr" ? tr : en}>
        <div className={cm.wrapper}>
          <Table
            dataSource={data ? data.data.records : []}
            columns={columns}
            showSorterTooltip={false}
            pagination={{
              showSizeChanger: true,
              total: data?.data.total_records,
              showTotal: (total, range) => t("common.pageTotal"),
            }}
            sortDirections={["descend", "ascend", null]}
            onChange={(pagination, filters, sorter: any) => {
              sorter.order !== undefined && sorter.order !== null
                ? setSort({
                    sortValue: sorter.field,
                    order: sorter.order === "ascend" ? "asc" : "desc",
                  })
                : setSort(null);
              changePageSize(pagination.current!, pagination.pageSize!);
            }}
            scroll={{ x: true }}
          />

          <RetailExportBottomBar tableExport={tableExport} />
        </div>
      </ConfigProvider>
    </>
  );
};

export default ReportDetailsResultTab;
