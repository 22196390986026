import Modal from "antd/lib/modal";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkSuccessOutlined.svg";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailSuccessModalProps {
  type:
    | "advertisers"
    | "multiple_advertisers"
    | "create_advertiser"
    | "reports"
    | "invited"
    | "agencies"
    | "invoice"
    | "payment"
    | "craftgate";
  visible: boolean;
  setVisible(visible: boolean): void;
  onClick?(): void;
  children?: React.ReactNode;
}

const RetailSuccessModal = ({
  type,
  visible,
  setVisible,
  onClick,
  children
}: RetailSuccessModalProps) => {
  const { t } = useTranslation();

  const close = () => {
    setVisible(false);
    onClick && onClick();
  };

  return (
    <Modal
      className={cm.successModal}
      visible={visible}
      closable={false}
      centered
      footer={[
        <RetailMainButton
          hasBackground={true}
          onClick={close}
          className={cm.ok}
        >
          {t("common.okay")}
        </RetailMainButton>,
      ]}
    >
      <section className={cm.successWrapper}>
        <div className={cc(["flex", cm.successIcon])}>
          <CheckOutlined />
        </div>
        <RetailTitle className={cm.successTitle}>
          {t(`components.modal.success.${type}_success_title`)}
        </RetailTitle>
        <RetailText size="xs" weight="medium" className={cm.successText}>
          {t(`components.modal.success.${type}_success_text`)}
        </RetailText>
      </section>
      {children}
    </Modal>
  );
};

export default RetailSuccessModal;
