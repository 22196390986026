import Layout, { Content } from "antd/lib/layout/layout";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Auth, AuthContext } from "../../../context/AuthProvider";
import { TeknosaCaseStudyTemplate } from "../../../pages/auth/Teknosa/TeknosCaseStudyTemplate";
import useApi from "../../../api";

import AccountsPage from "../../../pages/auth/AccountsPage";
import ForgetPasswordPage from "../../../pages/auth/ForgetPasswordPage";
import LoginPage from "../../../pages/auth/LoginPage";
import NewPasswordPage from "../../../pages/auth/NewPasswordPage";
import SignUpPage from "../../../pages/auth/SignUpPage";
import TeknosaCaseStudy from "../../../pages/auth/Teknosa/TeknosaCaseStudy";
import TeknosaLanding from "../../../pages/auth/Teknosa/TeknosaLanding";
import WelcomePage from "../../../pages/auth/WelcomePage";
import RetailAdminRoutes from "../../Routes/RetailAdminRoutes";
import RetailUserRoutes from "../../Routes/RetailUserRoutes";
import RetailPageNavbar from "../RetailNavbar/RetailPageNavbar";
import RetailSider from "../RetailSider";

import styles from "./style.module.scss";

const RetailLayout = () => {
  const { authToken } = useContext(AuthContext) as Auth;

  const { role } = useApi();

  const { pathname } = useLocation();

  //Auth role can't be used because a marketplace can go to advertiser pages.
  const adminRoute = pathname.includes("admin") && role === "MARKETPLACE";

  const stepsRoutes = [
    "create-campaign",
    "create-report",
    "edit-campaign",
    "edit-report",
  ];

  //Check if a page should be rendered with a navbar and sider or not
  const steps = stepsRoutes.some((route) => pathname.includes(route));

  const name = (window as any).marketplaceConfig.name || "ACME";

  const retailRoutesRender = () => {
    // Remove query parameters
    const routePath = pathname.split("?")[0];
    //Render Teknosa Case Study Page
    if (
      name === "Teknosa" &&
      (pathname === "/case-study" || pathname === "/case-study/")
    )
      return <TeknosaCaseStudy />;
    //Render Teknosa Case Study Template Page
    if (name === "Teknosa" && pathname.includes("/case-study/"))
      return <TeknosaCaseStudyTemplate />;
    //Render Teknosa Landing Page
    if (name === "Teknosa" && !authToken && routePath === "/")
      return <TeknosaLanding />;
    //Render auth pages
    else {
      switch (routePath) {
        case "/welcome":
          return <WelcomePage />;
        case "/signup":
          return <SignUpPage />;
        case "/login":
          return <LoginPage />;
        case "/forget-password":
          return <ForgetPasswordPage />;
        case "/new-password":
          return <NewPasswordPage />;
        case "/accounts":
          return <AccountsPage />;
        default:
          return (
            <Layout className={styles.layout}>
              {!steps && <RetailPageNavbar />}
              {!steps && <RetailSider adminSider={adminRoute} />}
              <Content className={styles.content}>
                {adminRoute ? <RetailAdminRoutes /> : <RetailUserRoutes />}
              </Content>
            </Layout>
          );
      }
    }
  };

  return retailRoutesRender();
};

export default RetailLayout;
