import Button, { ButtonProps } from "antd/lib/button";
import cc from "classcat";

import cm from "./style.module.scss";

export interface RetailMainButtonProps extends ButtonProps {
  hasBackground: boolean;
}

const RetailMainButton = ({
  hasBackground,
  children,
  className,
  ...buttonProps
}: RetailMainButtonProps) => (
  <Button
    className={cc([
      hasBackground ? cm.hasBackground : cm.noBackground,
      cm.btn,
      className || "",
    ])}
    {...buttonProps}
  >
    {children}
  </Button>
);

export default RetailMainButton;
