import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SearchOutlined } from "../../../assets/icons/searchOutlined.svg";
import RetailTransactionCard from "../../../components/Card/RetailTransactionCard";
import RetailDateColumn from "../../../components/Column/RetailDateColumn";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailInvoiceModal from "../../../components/Modal/RetailInvoiceModal";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import useAdvertiserBalance from "../../../hooks/useAdvertiserBalance";
import { numFormatter } from "../../../utils/helpers";
import cm from "./style.module.scss";

const InvoicesPage = () => {
  const { t } = useTranslation();

  const { data, isLoading, error } = useAdvertiserBalance();

  const [visible, setVisible] = useState(false);

  const [detailsVisible, setDetailsVisible] = useState(false);

  const [details, setDetails] = useState<any>({ spend: null, budget: null });

  const [activeKey, setActiveKey] = useState("SPENDING");

  const openDetails = (records: any) => {
    setDetailsVisible(true);
    setDetails({
      spend: records.spend_details,
      budget: records.budget_details,
    });
  };

  const closeDetails = () => setDetailsVisible(false);

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    /* filters: reportFilters(t), */
    /*  tags,
    activeKey, */
  };

  const open = () => setVisible(true);

  const columns = (t: any) => [
    {
      title: t("common.table.date"),
      dataIndex: "transaction_date",
      render: (value: string) => <RetailDateColumn value={value} />,
      disabled: true,
    },
    {
      title: t("common.table.invoice"),
      dataIndex: "invoice_number",
      render: (value: string) => (value ? value : "-"),
    },
    {
      title: t("common.table.expireDate"),
      dataIndex: "expire_date",
      render: (value: string) => <RetailDateColumn value={value} />,
    },
    {
      title: t("common.table.transaction"),
      dataIndex: "transaction_type",
      render: (value: string) =>
        t(`pages.admin.transactions.${value?.toLowerCase()}`),
      disabled: true,
    },
    {
      title: t("common.table.desc"),
      dataIndex: "description",
      render: (value: string) =>
        value
          ? t(`pages.admin.transactions.${`${value?.toLowerCase()}_desc`}`)
          : "-",
    },
    {
      title: t("common.table.status"),
      dataIndex: "status",
      render: (value: string) =>
        value ? t(`pages.admin.transactions.${value?.toLowerCase()}`) : "-",
    },
    {
      title: t("common.table.amount"),
      dataIndex: "amount",
      width: 120,
      render: (value: number, records: any) => (
        <span
          style={{
            color:
              records.transaction_type === "SPEND"
                ? "var(--red-7)"
                : "var(--green-7)",
          }}
        >
          {records.transaction_type === "SPEND" ? (
            value !== undefined && (
              <>
                - <RetailMoneyColumn value={value} />
              </>
            )
          ) : (
            <>
              + <RetailMoneyColumn value={value} />
            </>
          )}
        </span>
      ),
    },
    {
      title: t("common.table.balance"),
      dataIndex: "balance",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.detail"),
      dataIndex: "spend_details",
      render: (value: any, records: any) =>
        value && (
          <SearchOutlined
            className={cm.icon}
            onClick={() => openDetails(records)}
          />
        ),
      disabled: true,
    },
  ];

  const dateChecker = (date: string) => {
    if (date === "0001-01-01") return "-";
    else return moment(date).format("DD/MM/YYYY");
  };

  return (
    <RetailPageContainer>
      <div className={cm.cardContainer}>
        <RetailTransactionCard
          title={t("pages.acc.invoice.balance")}
          data={!isLoading && !error ? numFormatter(data?.data?.balance) : ""}
        />
        <RetailTransactionCard
          title={t("pages.acc.invoice.coupon")}
          data={!isLoading && !error ? numFormatter(data?.data?.coupons) : ""}
        />
        <RetailTransactionCard
          title={t("pages.acc.invoice.lastPayment")}
          data={
            !isLoading && !error
              ? {
                  amount: data?.data.amount,
                  date: dateChecker(data?.data?.created_at),
                }
              : null
          }
        />
      </div>
      <Row className="bordered-container full-width">
        <RetailTable
          columns={() => columns(t)}
          tableConfig={tableConfig}
          rowSelection={undefined}
          placeholder={t("pages.acc.invoice.searchPlaceholder")}
          button={{ title: t("pages.acc.invoice.modal.button"), onClick: open }}
        />
      </Row>
      <RetailInvoiceModal
        type="INVOICE"
        subtitle={t("pages.acc.invoice.modal.subtitle")}
        visible={visible}
        setVisible={setVisible}
      />

      <CampaignDetailsModal
        type="TRANSACTION_DETAILS"
        subtitle={t("pages.admin.transactions.detailsSubtext")}
        visible={detailsVisible}
        onCancel={closeDetails}
      >
        <Tabs
          className={cc([
            "campaign-details-tab",
            activeKey === "targeting" ? "two-step-tabs" : "",
          ])}
          activeKey={activeKey}
          onChange={(value) => setActiveKey(value)}
        >
          <Tabs.TabPane
            key="SPENDING"
            tab={t("pages.admin.transactions.spendTab")}
            className={cm.tab}
          >
            {details.spend &&
              details.spend.map((item: any, index: number) => (
                <article
                  key={index}
                  className={cc(["flex", cm.spaceBetween, cm.line])}
                >
                  <RetailText weight="bold" size="xs" className={cm.item}>
                    {item.campaign}
                  </RetailText>
                  <RetailText
                    weight="medium"
                    size="xs"
                    family="poppins"
                    className={cm.item}
                  >
                    -<RetailMoneyColumn value={item.amount} />
                  </RetailText>
                </article>
              ))}
          </Tabs.TabPane>
          <Tabs.TabPane
            key="BUDGET"
            tab={t("pages.admin.transactions.budgetTab")}
            className={cm.tab}
          >
            {details.spend &&
              details.budget.map((item: any, index: number) => (
                <article
                  key={index}
                  className={cc(["flex", cm.spaceBetween, cm.line])}
                >
                  <RetailText weight="bold" size="xs" className={cm.item}>
                    {t(
                      `pages.admin.transactions.${item.resource.toLowerCase()}`
                    )}
                  </RetailText>
                  <RetailText
                    weight="medium"
                    size="xs"
                    family="poppins"
                    className={cm.item}
                  >
                    -<RetailMoneyColumn value={item.amount} />
                  </RetailText>
                </article>
              ))}
          </Tabs.TabPane>
        </Tabs>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default InvoicesPage;
