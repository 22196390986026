import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useParams } from "react-router-dom";

import useApi from "../api";
import RetailNotification from "../components/Notification";
import { prefix } from "../utils/helpers";

const useTableBulkEdit = (
  tableConfig: any,
  arr: number[],
  handleRowkeys: Function
) => {
  const { t } = useTranslation();

  const { url, isRelation, relationType } = tableConfig;

  const { id } = useParams();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const updateValue = async (value: any) => {
    //Config for any table other than campaign relations.
    const config = {
      id: arr,
      status: value,
    };
    //Config for product relation
    const productConfig = {
      campaign_advertiser_products: {
        id: arr,
        status: value,
      },
    };
    //Config for keyword relation
    const keywordConfig = {
      campaign_keywords: {
        status: value,
        keywords: arr.map((item) => ({ id: item })),
      },
    };
    // Config for category relation
    const categoryConfig = {
      campaign_categories: {
        status: value,
        categories: arr.map((item) => ({ id: item })),
      },
    };
    //Config for inviting a user
    const inviteConfig = {
      invites: arr.map((records: any) => ({
        account_id: parseInt(records.account_id),
        email: records.email,
        role: records.account_role || "ADVERTISER",
      })),
    };
    //Config for advertiser archive
    const advertiserStatusConfig = {
      id: arr.map((records: any) => parseInt(records.id)),
      status: value,
    };

    //Config for any table other than campaign relations.
    const creativesConfig = {
      id: arr,
      policy_status: value,
    };

    //If table belongs to any campaign relation, config structure changes.
    const relationConfig = {
      [`campaign_${relationType && relationType.toLowerCase()}`]: {
        id: arr,
        status: value,
      },
    };

    const configType = () => {
      if (url === "invite-multiple-user") return inviteConfig;

      if (url === "advertisers") return advertiserStatusConfig;

      if (url === "creatives") return creativesConfig;

      if (isRelation) {
        switch (relationType) {
          case "PRODUCTS":
            return productConfig;
          case "KEYWORDS":
            return keywordConfig;
          case "CATEGORIES":
            return categoryConfig;
          default:
            return relationConfig;
        }
      }
      return config;
    };

    const methodType =
      isRelation || url === "invite-multiple-user" ? "post" : "patch";

    const response = await api({
      method: methodType,
      url: isRelation ? `campaigns/${id}/relations` : url,
      data: configType(),
    });
    return response;
  };

  const { mutateAsync, isLoading } = useMutation(updateValue);

  //Update bids for keywords and categories
  const updateBids = async (bid: any) => {
    //Get query data without selecting rows
    const data = queryClient.getQueriesData([
      "table",
      {
        type: relationType,
      },
    ]) as any;
    //Get id variable for keyword and category
    const idVariable = relationType === "KEYWORDS" ? "keywordID" : "categoryID";
    //Change selected rows according to row selection
    const selectedRows =
      arr.length === 0
        ? data[0][1]?.data?.records.map((x: any) => x[idVariable])
        : arr;
    //Config for keyword bid updates
    const keywordsBidConfig = {
      campaign_keywords: {
        keywords: selectedRows.map((item: any) => ({
          id: item,
          bid: parseFloat(bid),
        })),
      },
    };
    // Config for category bid updates
    const categoriesBidConfig = {
      campaign_categories: {
        categories: selectedRows.map((item: any) => ({
          id: item,
          bid: parseFloat(bid),
        })),
      },
    };
    //Create a text for notification showing bid value and number of rows
    const textsToCombineForNotification = [
      t("components.notification.bid", { value: selectedRows.length }),
      t("components.notification.newBid", { value: `${bid}${prefix()}` }),
    ];
    //Combine success texts together
    const successText = textsToCombineForNotification.join(" ");
    // Then send the request
    try {
      const response = await api.post(
        `campaigns/${id}/relations`,
        relationType === "KEYWORDS" ? keywordsBidConfig : categoriesBidConfig
      );
      queryClient.refetchQueries(["table", { type: relationType }]);

      RetailNotification.showNotification("success", "", successText);
      return response;
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    } finally {
      handleRowkeys([]);
    }
  };

  const { mutateAsync: mutateBids } = useMutation(updateBids);

  const notificationTextValue = isRelation
    ? t(`components.notification.${relationType?.toLowerCase()}`)
    : t(`components.notification.${url.replace("-", "_")}`);

  const tableFetch = () => {
    switch (isRelation) {
      case true:
        return ["table", { type: relationType }];
      case false:
        return ["table", { url }];
      default:
        break;
    }
  };

  const inviteStatus = (errors: any[]) => {
    const errorCounts = errors.reduce(
      (accumulator: any, currentElement: any) => {
        if (accumulator[currentElement] === undefined) {
          accumulator[currentElement] = 1;
        } else {
          accumulator[currentElement]++;
        }
        return accumulator;
      },
      {}
    );

    Object.entries(errorCounts).forEach(([key, value]) => {
      RetailNotification.showNotification(
        "error",
        "",
        t(`components.notification.${key.replace(/\s/g, "_")}`, {
          value: value,
        })
      );
    });
  };

  const successText = (data: any, errors?: any[]) => {
    if (url === "invite-multiple-user" && errors) {
      inviteStatus(errors);
      return t("components.notification.invite", {
        value: arr.length - errors?.length!,
      });
    }
    switch (data) {
      case "ARCHIVED":
        return t(
          `components.notification.${data.toLowerCase()}_${url.replace(
            "-",
            "_"
          )}_bulk`
        );
      case "INVITE":
        return t("components.notification.full_invite");
      default:
        return t(`components.notification.${data.toLowerCase()}`, {
          value: notificationTextValue,
        });
    }
  };

  const changeValue = async (data: any) => {
    try {
      await mutateAsync(data).then((res) => {
        const areThereErrorsInSuccessfulResponse = () => {
          if (Array.isArray(res?.data))
            return res?.data?.map((item: any) => item?.error);
          else;
        };
        //Fetch table data after mutation
        queryClient.refetchQueries(tableFetch());
        //Show notification success
        RetailNotification.showNotification(
          "success",
          "",
          successText(data, areThereErrorsInSuccessfulResponse())
        );
      });
    } catch (error) {
      //Error for inviting multiple users
      if (
        url === "invite-multiple-user" &&
        Array.isArray(error.response.data)
      ) {
        const errors = error.response?.data.map((item: any) => item.error);
        inviteStatus(errors);
        //Error for relation tables
      } else if (relationType !== undefined && error.response.status === 400) {
        RetailNotification.showNotification(
          "error",
          "",
          t(`pages.acc.campaignDetails.${relationType.toLowerCase()}Error`)
        );
        // General error notification
      } else {
        RetailNotification.showNotification(
          "error",
          "",
          t("components.notification.statusError")
        );
      }
    } finally {
      handleRowkeys([]);
    }
  };

  return {
    changeValue,
    isLoading,
    successModalVisible,
    setSuccessModalVisible,
    mutateBids,
  };
};

export default useTableBulkEdit;
