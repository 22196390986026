import Tabs from "antd/lib/tabs";
import moment from "moment";
import { useState } from "react";
import {
	TFunction,
	useTranslation,
} from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import useApi from "../../../api";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import RetailStatusColumn from "../../../components/Column/RetailStatusColumn";
import RetailRoleRestrictionContainer from "../../../components/Container/RetailRoleRestrictionContainer";
import RetailDrawer from "../../../components/Drawer/RetailDrawer";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailNotification from "../../../components/Notification";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import { getSubstring } from "../../../utils/helpers";
import cm from "./style.module.scss";

const UsersPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [activeKey, setActiveKey] = useState("");

  const [restricted, setRestricted] = useState(false);

  const [editDrawer, setEditDrawer] = useState<{
    visible: boolean;
    records: any;
  }>({
    visible: false,
    records: null,
  });

  const openEditDrawer = (records: any) => {
    setEditDrawer({ visible: true, records: records });
    setRestricted(records?.account_user_role?.includes("REPORTER"));
  };

  const closeEditDrawer = () => {
    setEditDrawer({ visible: false, records: null });
    setRestricted(false);
  };

  const updateRole = async () => {
    //Close drawer if the role is not changed
    const isReporter =
      editDrawer?.records?.account_user_role.includes("REPORTER");
    if ((isReporter && restricted) || (!isReporter && !restricted)) {
      closeEditDrawer();
      return;
    }
    const role = restricted
      ? `${editDrawer.records?.account_user_role}_REPORTER`
      : getSubstring(editDrawer.records?.account_user_role, " ", "_");

    try {
      await api.patch(`account-users/${editDrawer.records?.account_user_id}`, {
        role,
      });
      closeEditDrawer();
      queryClient.refetchQueries("table");
    } catch (err: any) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  const updateValue = async (data: any) => {
    const config = {
      status: data?.account_user_status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED",
    };
    const response = await api.patch(
      `account-users/${data?.account_user_id}`,
      config
    );
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeArchiveStatus = async (data: any) => {
    try {
      await mutateAsync(data);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t(
          `components.notification.${data?.account_user_status.toLowerCase()}_account_user`
        )
      );
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  const tableConfig = {
    url: "account-users",
    isRelation: false,
    activeKey,
    to: openEditDrawer,
    onArchive: changeArchiveStatus,
  };

  const advertiserTabConfig = {
    ...tableConfig,
    defaultFilter: [
      { key: "account_type", op: "eq", value: "ADVERTISER" },
      {
        key: "account_user_status",
        op: "ne",
        value: "ARCHIVED",
      },
    ],
  };

  const marketplaceTabConfig = {
    ...tableConfig,
    defaultFilter: [
      { key: "account_type", op: "eq", value: "MARKETPLACE" },
      {
        key: "account_user_status",
        op: "ne",
        value: "ARCHIVED",
      },
    ],
  };

  const archivedTabConfig = {
    ...tableConfig,
    defaultFilter: [
      { key: "account_user_status", op: "eq", value: "ARCHIVED" },
    ],
  };

  const firstColumns = (t: TFunction) => [
    {
      title: t("common.table.active"),
      dataIndex: "account_user_status",
      disabled: true,
      render: (value: boolean, records: any) => (
        <RetailStatusColumn records={records} url={tableConfig.url} />
      ),
    },
    {
      title: t("common.table.email"),
      dataIndex: "user_email",
      disabled: true,
    },
    {
      title: t("common.table.role"),
      dataIndex: "account_user_role",
      render: (value: string) =>
        value?.includes("REPORTER")
          ? t("pages.admin.users.limited")
          : t("pages.admin.users.full"),
    },
  ];

  const advertiserColumn = (t: TFunction) => ({
    title: t("common.table.advertiser"),
    dataIndex: "advertiser_name",
    render: (value: string, records: any) => (
      <RetailNameColumn
        state={{
          id: records?.advertiser_id || 1,
          name: records?.advertiser_name || "No Name",
        }}
        to={`/campaigns`}
        value={`${records?.advertiser_name} (${records?.advertiser_id})`}
      />
    ),
  });

  const lastColumns = (t: TFunction) => [
    {
      title: t("common.table.lastEnter"),
      dataIndex: "user_last_login",
      render: (value: string) => (
        <span>{moment(value).format("DD/MM/YY")}</span>
      ),
    },
    {
      title: t("common.table.initialDate"),
      dataIndex: "account_user_created_at",
      render: (value: string) => (
        <RetailText family="poppins" size="xs">
          {moment(value).format("DD/MM/YY")}
        </RetailText>
      ),
    },
  ];
  const columns = (t: TFunction) => [
    ...firstColumns(t),
    advertiserColumn(t),
    ...lastColumns(t),
  ];

  const marketplaceColumns = (t: TFunction) => [
    ...firstColumns(t),
    ...lastColumns(t),
  ];

  const handleRestriction = (value: boolean) => setRestricted(value);

  return (
    <RetailPageContainer>
      <Tabs
        onChange={(activeKey) => setActiveKey(activeKey)}
        className="bordered-container no-margin"
      >
        <Tabs.TabPane tab={t("common.advertiser")} key="ADVERTISER">
          <RetailTable
            placeholder={t("pages.admin.users.search")}
            columns={() => columns(t)}
            tableConfig={advertiserTabConfig}
            addBtnVisible={false}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("common.admin")} key="MARKETPLACE">
          <RetailTable
            placeholder={t("pages.admin.users.search")}
            columns={() => marketplaceColumns(t)}
            tableConfig={marketplaceTabConfig}
            addBtnVisible={false}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("common.archived")} key="ARCHIVED">
          <RetailTable
            placeholder={t("pages.admin.users.search")}
            columns={() => marketplaceColumns(t)}
            tableConfig={archivedTabConfig}
            addBtnVisible={false}
          />
        </Tabs.TabPane>
      </Tabs>

      <RetailDrawer
        visible={editDrawer.visible}
        onOk={updateRole}
        onClose={closeEditDrawer}
        title={t("pages.admin.invited.edit")}
        type="edit"
      >
        <div className={cm.container}>
          <div className={cm.card}>
            <RetailText size="xxs" weight="medium">
              {t("pages.admin.invited.mail")}
            </RetailText>
            <RetailText size="xxs" weight="bold">
              {editDrawer.records?.user_email}
            </RetailText>
          </div>

          <div className={cm.card}>
            <RetailText size="xxs" weight="medium">
              {t("pages.admin.invited.role")}
            </RetailText>
            <RetailText size="xxs" weight="bold">
              {t(
                `pages.admin.invited.${editDrawer.records?.account_user_role}`
              )}
            </RetailText>
          </div>
        </div>
        <RetailRoleRestrictionContainer
          handleRestriction={handleRestriction}
          restricted={restricted}
          type="drawer"
        />
      </RetailDrawer>
    </RetailPageContainer>
  );
};

export default UsersPage;
