import {
	createContext,
	useState,
} from "react";

import { Img } from "../../utils/types";

export type Creative = {
  // Creatives
  img: Img[];
  setImg(i: any): void;
  /* handleCategoryAdd(value: string): void; */
  deleteCreative(uuid: string): void;
};

export const CreativeContext = createContext<Creative | null>(null);

export const CreativeProvider: React.FC = ({ children }) => {
  const [img, setImg] = useState<Img[] | []>([]);

  // Function which adds manually typed category to category table
  /*  const handleCategoryAdd = (value: string) => {
    value !== "" && setCurrentCategory(currentCategory);
  };
 */

  const deleteCreative = (uuid: string) =>
    setImg(img.filter((i: Img) => uuid !== i.uuid));

  return (
    <CreativeContext.Provider
      value={{
        img,
        setImg,
        deleteCreative,
      }}
    >
      {children}
    </CreativeContext.Provider>
  );
};

export default CreativeProvider;
