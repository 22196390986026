import cc from "classcat";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as LeftCircleOutlined } from "../../../assets/icons/leftCircleOutlined.svg";
import { ReactComponent as ShopOutlined } from "../../../assets/icons/shopOutlined.svg";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

const RetailAdminBar = () => {
  const { t } = useTranslation();

  const { local, setLocal } = useContext(AuthContext) as Auth;

  const handleClick = () => {
    localStorage.removeItem("advertiser_id");
    setLocal(null);
  };

  return (
    <div className={cc(["flex", cm.container])}>
      <div className={cc(["flex"])}>
        <RetailText weight="medium">{t("components.adminBar.rn")}</RetailText>
        <div className={cc(["flex", cm.advertiser])}>
          <ShopOutlined />
          <RetailText weight="bold">{local.name || "No Name"}</RetailText>
        </div>
        <RetailText weight="medium">
          {t("components.adminBar.visible")}
        </RetailText>
      </div>
      <Link
        to="/admin/campaigns"
        className={cc(["flex", cm.btn])}
        onClick={handleClick}
      >
        <LeftCircleOutlined />
        <RetailText size="xs" weight="bold">
          {t("components.adminBar.back")}
        </RetailText>
      </Link>
    </div>
  );
};

export default RetailAdminBar;
