import cc from "classcat";
import {
	useContext,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Link,
	useNavigate,
} from "react-router-dom";

import { ReactComponent as HomeOutlined } from "../../../assets/icons/homeOutlined.svg";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import RetailText from "../../../components/Typography/RetailText";
import {
	Auth,
	AuthContext,
} from "../../../context/AuthProvider";
import { getSubstring } from "../../../utils/helpers";
import cm from "./style.module.scss";

const WelcomePage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { authToken } = useContext(AuthContext) as Auth;

  const access_token = getSubstring(window.location.href, "=", "&");

  const emailFromURL = decodeURIComponent(window.location.href).split(
    "email="
  )[1];

  useEffect(() => {
    access_token !== "" && localStorage.setItem("access_token", access_token);
    emailFromURL !== undefined &&
      localStorage.setItem("email_from_url", emailFromURL);
    if (authToken && access_token) {
      navigate("/accounts");
    }
  }, [access_token, authToken, navigate, emailFromURL]);

  return (
    <RetailAuthLayout>
      <div className={cm.wrapper}>
        <article className={cc(["flex", cm.textContainer])}>
          <div className={cc(["flex", cm.iconContainer])}>
            <HomeOutlined />
          </div>
          <h1>{t("pages.auth.welcome.title")}</h1>
        </article>

        <section className={cm.container}>
          <article>
            <RetailText size="xxs" weight="medium" className={cm.text}>
              {t("pages.auth.welcome.loginText")}
            </RetailText>
            <Link to="/login" className={cm.loginBtn}>
              {t("pages.auth.welcome.loginBtn")}
            </Link>
          </article>
          <p className={cm.divider}>veya</p>
          <article>
            <RetailText size="xxs" weight="medium" className={cm.text}>
              {t("pages.auth.welcome.signupText")}
            </RetailText>
            <Link to="/signup" className={cm.signupBtn}>
              {t("pages.auth.welcome.signupBtn")}
            </Link>
          </article>
        </section>
      </div>
    </RetailAuthLayout>
  );
};

export default WelcomePage;
