import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Input from "antd/lib/input";
import cc from "classcat";
import {
	ChangeEvent,
	KeyboardEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import useApi from "../../../api";
import { ReactComponent as CheckOutlined } from "../../../assets/icons/checkWhite.svg";
import useSettings from "../../../hooks/useSettings";
import {
	prefix,
	validateNumber,
} from "../../../utils/helpers";
import RetailNotification from "../../Notification";
import RetailBidTooltip from "../../Tooltip/RetailBidTooltip";
import cm from "./style.module.scss";

export interface Selected {
  editing: boolean;
  data: { [key: string]: string | number };
  value: string;
  editing_field: string;
}
export interface RetailBidEditContainerProps {
  selected: Selected;
  setSelected: (selected: Selected) => void;
  onClick?: () => void;
}

const RetailBidEditContainer = ({
  selected,
  setSelected,
  onClick,
}: RetailBidEditContainerProps) => {
  const { t } = useTranslation();
  const { roleForDashboard } = useApi();

  const { data, value, editing_field } = selected;

  const { data: bidData } = useSettings(
    roleForDashboard,
    editing_field === "bid"
  );

  const location = useLocation();

  const ad_type = location.state as { ad_type: string };

  const chooseMinimum = useCallback(() => {
    if (ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE") {
      return bidData?.data?.sponsored_product_price_setting;
    } else return bidData?.data?.sponsored_display_price_setting;
  }, [ad_type?.ad_type, bidData]);

  const chosenMinBid = useCallback(() => {
    if (chooseMinimum() === "CPM") return bidData?.data?.minimum_cpm;
    else return bidData?.data?.minimum_bid;
  }, [bidData, chooseMinimum]);

  const ref = useRef<any>(null);

  const [error, setError] = useState(true);

  const [form] = useForm();

  const close = () => {
    setSelected({ ...selected, editing: false, editing_field: "" });
    if (!error) {
      data[editing_field] = chosenMinBid();
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.editableFieldError")
      );
    } else {
      data[editing_field] = value;
      onClick && onClick();
    }
  };

  //Memorize the function to avoid re-rendering
  const errorNumber = useCallback(() => {
    const value = parseFloat(ref.current.input.value.replace(/,/g, "."));
    //If field is a percent value, check if value is between 0 and 100
    if (editing_field === "acos") return value >= 0.0001 && value <= 100;
    //If field is just a number, check if value is between 0 and 100
    if (editing_field === "relevance") return value >= 0 && value <= 100;
    //Check if value is greater or equal than minimum_bid
    else return value >= (parseFloat(chosenMinBid()) || 0);
  }, [editing_field, chosenMinBid]);

  const type = () => {
    switch (editing_field) {
      case "bid":
      case "minimum_bid":
      case "minimum_cpm":
        return "general";
      case "acos":
        return "percent";
      default:
        return "number";
    }
  };

  useEffect(() => {
    setError(errorNumber() && ref.current.input.value !== "");
  }, [errorNumber, value]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setSelected({ ...selected, value: target.value });

  const handleNumberValidation: KeyboardEventHandler<HTMLInputElement> = (e) =>
    validateNumber(e, editing_field !== "acos");

  return (
    <div
      data-cy="keyword-bid-input"
      className={cc(["flex", cm.inputContainer, error ? "" : cm.error])}
    >
      <div
        data-cy="keyword-bid-ok-button"
        className={cc([
          cm.iconContainer,
          cm.okContainer,
          "flex",
          error ? "" : cm.error,
        ])}
        onClick={close}
      >
        <CheckOutlined />
      </div>
      {editing_field === "relevance" || editing_field === "acos" ? null : (
        <span className={cm.prefix}>{prefix()}</span>
      )}
      <Form
        form={form}
        initialValues={{ bid: parseFloat(chosenMinBid()).toFixed(2) }}
        autoComplete="off"
      >
        <Form.Item name="bid" className={cm.formItem}>
          <Input
            data-cy="keyword-edit-bid-icon"
            autoFocus={true}
            className={cc([cm.input, error ? "" : cm.error])}
            onKeyDownCapture={handleNumberValidation}
            onChange={handleChange}
            ref={ref}
            onBlur={close}
          />
        </Form.Item>
      </Form>

      {!error && <RetailBidTooltip value={chosenMinBid() || 0} type={type()} />}
    </div>
  );
};

export default RetailBidEditContainer;
