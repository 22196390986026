import { Modal } from "antd";
import cc from "classcat";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseModalFilled } from "../../../assets/icons/closeModalFilled.svg";
import useTableFetch from "../../../hooks/useTableFetch";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailText from "../../Typography/RetailText";
import RetailTitle from "../../Typography/RetailTitle";
import cm from "./style.module.scss";

export interface RetailCreativeUploadInfoProps {
  fullWidth?: boolean
}

const RetailCreativeUploadInfo = ({fullWidth = true}: RetailCreativeUploadInfoProps) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { data } = useTableFetch("ad_placements", false, {}, undefined, [
    { key: "status", op: "eq", value: "ACTIVE" },
    {
      key: "ad_format",
      op: "eq",
      value: "DISPLAY",
    },
  ]);

  const open = () => setVisible(true);

  const close = () => setVisible(false);

  return (
    <>
      <ul className={cc([cm.list, fullWidth ? cm.openList : cm.formList])}>
        <li>
          <RetailText family="poppins" size="xxxs">
            {t("components.campaignForm.firstStep.listWarningSize")}
          </RetailText>
        </li>
        <li>
          <RetailText family="poppins" size="xxxs">
            {t("components.campaignForm.firstStep.listWarningType")}
          </RetailText>
        </li>
        <li>
          <RetailText
            family="poppins"
            size="xxxs"
            className={cm.warningRecommended}
            onClick={open}
          >
            {t("components.campaignForm.firstStep.listWarningRecommended")}
          </RetailText>
        </li>
      </ul>
      <Modal
        closable
        closeIcon={<CloseModalFilled />}
        centered
        visible={visible}
        onCancel={close}
        className={cc(["campaign-modal", cm.modal])}
        destroyOnClose
        title={
          <>
            <RetailTitle level={4} className={cm.modalTitle}>
              {t("components.campaignForm.firstStep.modalTitle")}
            </RetailTitle>
            <RetailText size="xs" family="poppins" className={cm.modalText}>
              {t("components.campaignForm.firstStep.modalText")}
            </RetailText>
          </>
        }
        footer={[
          <RetailMainButton
            hasBackground
            className={cm.modalBtn}
            onClick={close}
          >
            {t("common.okay")}
          </RetailMainButton>,
        ]}
      >
        <table className={cm.table}>
          <thead>
            <th>
              <RetailText weight="medium" family="poppins" className={cm.title}>
                {t("components.campaignForm.firstStep.recommended")}
              </RetailText>
            </th>
            <th>
              <RetailText weight="medium" family="poppins" className={cm.title}>
                {t("components.campaignForm.firstStep.accepted")}
              </RetailText>
            </th>
          </thead>

          <tbody className={cm.tableBody}>
            <td className={cm.recommendedSizes}>
              {data?.data.records.map((x: any) => (
                <tr>
                  <RetailText className={cm.data} size="xs" family="poppins">
                    {x.recommended_size}
                  </RetailText>
                </tr>
              ))}
            </td>
            <td className={cm.sizes}>
              {data?.data.records.map((x: any) => (
                <tr className={cm.sizes}>
                  <RetailText className={cm.data} size="xs" family="poppins">
                    {x?.sizes?.map((size: string) => (
                      <span>{size}</span>
                    ))}
                  </RetailText>
                </tr>
              ))}
            </td>
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default RetailCreativeUploadInfo;
