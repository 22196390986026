import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import RetailTable from "../../../../components/Table/RetailTable";
import {
	ageFilters,
	citiesFilters,
	customerFilters,
	genderFilters,
	platformFilters,
} from "../../../../utils/filters";
import { renderTag } from "../../../../utils/helpers";
import { Relations } from "../../../../utils/types";

export interface TargetingTableProps {
  activeKey: Relations;
}

const TargetingTable = ({ activeKey }: TargetingTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const switchFilters = () => {
    switch (activeKey) {
      case "CITIES":
        return citiesFilters(t);
      case "AGE_RANGES":
        return ageFilters(t);
      case "GENDERS":
        return genderFilters(t);
      case "PLATFORMS":
        return platformFilters(t);
      case "CUSTOMERS":
        return customerFilters(t);
      default:
        break;
    }
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: activeKey,
    filters: switchFilters(),
  };

  const switchColumns = () => {
    switch (activeKey) {
      case "CITIES":
        return {
          title: t("pages.acc.campaignDetails.city"),
          dataIndex: "city",
        };
      case "AGE_RANGES":
        return {
          title: t("pages.acc.campaignDetails.age"),
          dataIndex: "age_range",
        };
      case "GENDERS":
        return {
          title: t("pages.acc.campaignDetails.gender"),
          dataIndex: "gender",
          render: (value: "FEMALE" | "MALE") => (
            <span>
              {t(`components.campaignForm.thirdStep.${value?.toLowerCase()}`)}
            </span>
          ),
        };
      case "PLATFORMS":
        return {
          title: t("pages.acc.campaignDetails.platform"),
          dataIndex: "platform",
          render: (value: string) =>
            t(`components.campaignForm.thirdStep.${value.toLowerCase()}`),
        };
      case "CUSTOMERS":
        return {
          title: t("pages.acc.campaignDetails.customers"),
          dataIndex: "text",
          render: (value: string) =>
            t(`pages.acc.campaignDetails.${value.toLowerCase()}`),
        };
      default:
        return "";
    }
  };

  const columns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      width: 100,
      disabled: true,
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType={activeKey}
        />
      ),
    },
    switchColumns(),
    {
      title: t("common.table.status"),
      dataIndex: "status",
      render: (value: "ACTIVE" | "PAUSED") => renderTag(value, t),
    },
    {
      title: t("common.table.acos"),
      dataIndex: "ACoS",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.click"),
      dataIndex: "clicks",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.sold"),
      dataIndex: "sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.spent"),
      dataIndex: "spend",
      render: (value: any) => (
        <RetailMoneyColumn value={value} spendColumn={true} />
      ),
    },
    {
      title: t("marketplaceDependent.table.CPC"),
      dataIndex: "CPC",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
  ];
  const extraColumns = (t: any) => [
    {
      title: t("common.table.impression"),
      dataIndex: "impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    /* {
      title: t("common.table.viewable_impressions"),
      dataIndex: "viewable_impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    }, */
    {
      title: t("components.reportForm.metric.CTR"),
      dataIndex: "CTR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    //dönüşüm oranı
    {
      title: t("components.reportForm.metric.CVR"),
      dataIndex: "CVR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sale"),
      dataIndex: "sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumDirectSaleAmount"),
      dataIndex: "direct_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSaleAmount"),
      dataIndex: "indirect_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumDirectSale"),
      dataIndex: "direct_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSale"),
      dataIndex: "indirect_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.roas"),
      dataIndex: "ROAS",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
  ];
  return (
    <RetailTable
      placeholder={t(
        `pages.acc.campaignDetails.${activeKey.toLowerCase()}_placeholder`
      )}
      tableConfig={tableConfig}
      columns={() => columns(t)}
      extraColumns={() => extraColumns(t)}
      addBtnVisible={false}
    />
  );
};

export default TargetingTable;
