import { ButtonProps } from "antd/lib/button";
import ConfigProvider from "antd/lib/config-provider";
import en from "antd/lib/locale/en_US";
import tr from "antd/lib/locale/tr_TR";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Table, { TableProps } from "antd/lib/table";
/* import RetailFilterPopover from "../../Popover/RetailFilterPopover"; */
import cc from "classcat";
import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import useApi from "../../../api";
import { ReactComponent as MoreOutlined } from "../../../assets/icons/moreOutlined.svg";
import useTableFetch from "../../../hooks/useTableFetch";
import RetailBulkUpdateBar from "../../Bar/RetailBulkUpdateBar";
import RetailExportBottomBar from "../../Bar/RetailExportBottomBar";
import RetailFilterBar from "../../Bar/RetailFilterBar";
import RetailInviteBar from "../../Bar/RetailInviteBar";
import RetailSearchBar from "../../Bar/RetailSearchBar";
import RetailMainButton from "../../Button/RetailMainButton";
import Empty from "../../Empty";
import RetailActionPopover from "../../Popover/RetailActionPopover";
import RetailColumnPopover from "../../Popover/RetailColumnPopover";
import cm from "./style.module.scss";

export interface RetailTableButtonProps extends Pick<ButtonProps, "onClick"> {
  title: string;
  dataTest?: string;
}
export interface RetailTableProps extends TableProps<any> {
  /**
   * Add new button of table. It accepts a title and an onClick function.
   */
  button?: RetailTableButtonProps;
  /**
   * Columns of table and popover.
   */
  columns: any;
  /**
   * Some of the tables doesn't have an add new button. This props determines if there is a button. Default is true.
   */
  addBtnVisible?: boolean;
  /**
   * Placeholder for search input.
   */
  placeholder: string;
  /**
   * Change search value
   */
  tableConfig: any;

  extraColumns?: any;
}

const RetailTable = ({
  columns,
  extraColumns = null,
  button,
  placeholder,
  tableConfig,
  addBtnVisible = true,
  pagination,
  className,
  ...tableProps
}: RetailTableProps) => {
  const { t, i18n } = useTranslation();

  const {
    url,
    isRelation,
    relationType,
    to,
    state,
    defaultFilter,
    activeKey,
    onArchive,
    onThirdOption,
    fetchAll,
  } = tableConfig;

  const { adminInGeneral } = useApi();

  const localStorageKey = `${adminInGeneral ? "admin" : "user"}_${url}_${
    isRelation ? "relation_" : "page"
  }${relationType || ""}`;

  const sortKey = () => {
    if (isRelation) {
      switch (relationType) {
        case "PRODUCTS":
          return "productCreatedAt";
        case "KEYWORDS":
          return "keywordCreatedAt";
        case "NEGATIVE_KEYWORDS":
          return "negativeKeywordCreatedAt";
        case "CATEGORIES":
          return "categoryCreatedAt";
        case "NEGATIVE_CATEGORIES":
          return "negativeCategoryCreatedAt";
        case "CITIES":
          return "cityName";
        case "GENDERS":
        case "AGE_RANGES":
        case "PLACEMENTS":
        case "PLATFORMS":
          return `${relationType.toLowerCase()}Text`;
        case "CUSTOMERS":
          return "customerTargetingText";
        case "CREATIVES":
          return "creativeCreatedAt";
      }
    }
    switch (url) {
      case "coupons":
        return "created_date";
      case "agencies":
      case "sync_logs":
      case "creatives":
        return "createdAt";
      case "account-users":
        return "account_user_created_at";
      case "ad_placements":
      case "invites":
        return "created_at";
      case "reports":
        return "report_created_at";
      case "keyword-blacklist":
        return "text";
      case "products":
        return "product";
      case "transactions":
        return "transaction_date";
      default:
        return `${url.slice(0, -1)}CreatedAt`;
    }
  };

  const {
    data,
    isLoading,
    changePageSize,
    search,
    setSearch,
    setSort,
    setIsExported,
    setFormat,
    config,
  } = useTableFetch(
    url,
    isRelation,
    { [sortKey()]: "desc" },
    relationType && relationType,
    defaultFilter && defaultFilter,
    fetchAll
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectedInfo, setSelectedInfo] = useState<any>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[], info: any) => {
    if (url === "advertisers" || url === "invites") {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedInfo(info);
    } else {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const action =
    to !== undefined || onArchive !== undefined
      ? {
          title: "",
          dataIndex: "action",
          render: (value: any, records: any) => (
            <Popover
              content={
                <RetailActionPopover
                  to={
                    typeof to === "string"
                      ? `/${to}/${records.id ? records.id : records.report}`
                      : to
                  }
                  state={state}
                  onArchive={onArchive}
                  onThirdOption={onThirdOption}
                  records={records}
                  activeKey={activeKey}
                />
              }
              placement="leftTop"
              trigger="hover"
              arrowPointAtCenter
              zIndex={1}
            >
              <MoreOutlined className={cm.action} />
            </Popover>
          ),
        }
      : {};

  const columnsWithSort = (t: any) =>
    columns(t).map((col: any) =>
      col.title === ""
        ? col
        : {
            ...col,
            sorter: col.dataIndex,
          }
    );

  const [selectedColumns, setSelectedColumns] = useState(() => (t: any) => [
    ...columnsWithSort(t),
    action,
  ]);

  const extraColumnsWithSort = (t: any) =>
    extraColumns !== null &&
    extraColumns(t).map((col: any) => ({
      ...col,
      sorter: col.dataIndex,
    }));

  const extraColumnOptions =
    extraColumns !== null
      ? extraColumnsWithSort(t).map((opt: any) => ({
          label: opt.title,
          value: opt.dataIndex,
          defaultChecked:
            localStorage.getItem(localStorageKey) !== null
              ? JSON.parse(localStorage.getItem(localStorageKey)!).some(
                  (item: any) => {
                    return item === opt.dataIndex;
                  }
                )
              : false,
        }))
      : [];

  const columnOptions = columnsWithSort(t)
    .map((opt: any) => ({
      label: opt.title,
      value: opt.dataIndex,
      disabled: opt.disabled,
      defaultChecked:
        localStorage.getItem(localStorageKey) !== null
          ? JSON.parse(localStorage.getItem(localStorageKey)!).some(
              (item: any) => {
                return item === opt.dataIndex;
              }
            )
          : true,
    }))
    .filter((opt: any) => opt.label !== "")
    .concat(extraColumns ? extraColumnOptions : []);

  const [checkedValues, setCheckedValues] = useState([]);

  const handleChange = (checkedValues: any) => {
    const cols = (t: any) =>
      columnsWithSort(t).filter((column: any) =>
        checkedValues.includes(column.dataIndex)
      );
    const extraCols = (t: any) =>
      extraColumnsWithSort(t).filter((column: any) =>
        checkedValues.includes(column.dataIndex)
      );
    if (extraColumns === null) {
      const dataIndex = [...cols(t), action].map((item: any) => item.dataIndex);
      localStorage.setItem(localStorageKey, JSON.stringify(dataIndex));
    } else {
      const dataIndex = [...cols(t), ...extraCols!(t), action].map(
        (item: any) => item.dataIndex
      );
      localStorage.setItem(localStorageKey, JSON.stringify(dataIndex));
    }
    setCheckedValues(checkedValues);
  };

  useEffect(() => {
    const data = localStorage.getItem(localStorageKey);
    const extras = extraColumns !== null ? extraColumnsWithSort(t) : [];
    if (data !== null) {
      setSelectedColumns(
        () => (t: any) =>
          [...columnsWithSort(t), ...extras, action].filter((item: any) => {
            return JSON.parse(data).indexOf(item.dataIndex) !== -1;
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValues]);

  const handleRowKeys = () => {
    setSelectedRowKeys([]);
    setSelectedInfo([]);
  };

  const tableExport = (value: "excel" | "pdf" | "csv") => {
    setFormat(value);
    setIsExported(true);
  };

  const switchTableType = () => {
    const linkForTableType = url.replace("-", "_");
    if (search !== "") return "searched_table";

    if (search === "") {
      switch (activeKey) {
        case "ARCHIVED":
        case "INVITED":
        case "ACCEPTED":
        case "REJECTED":
        case "PENDING":
          return `${activeKey.toLowerCase()}_table_${linkForTableType}`;
        default:
          if (relationType === "CREATIVES") {
            return "creatives_relations_table";
          }
          if (relationType !== undefined) {
            return `${relationType.toLowerCase()}_table`;
          }
      }
    }

    if (url === "keyword-blacklist") return "blacklist_table";

    return `${linkForTableType}_table`; // Default return value
  };

  const customizeRenderEmpty = () => <Empty type={switchTableType()} />;

  const switchIDForRowKey = (record: any) => {
    if (isRelation) {
      switch (relationType) {
        case "PRODUCTS":
          return record.productID;
        case "KEYWORDS":
        case "NEGATIVE_KEYWORDS":
          return record.keywordID;
        case "CATEGORIES":
        case "NEGATIVE_CATEGORIES":
          return record.categoryID;
        case "CREATIVES":
          return record.creativeID;
        case "PLACEMENTS":
          return record.placementID;
        default:
          break;
      }
    } else return record.id || record.account_user_id;
  };

  const switchDataSource = () => {
    if (url === "users") {
      const uniqueAccounts = Array.from(
        new Set(
          data?.data?.records.flatMap((record: any) =>
            record.accounts.map((account: any) => account.id)
          )
        )
      ).map((accountId) => {
        const record = data?.data?.records.find((record: any) =>
          record.accounts.some((account: any) => account.id === accountId)
        );

        const account = record.accounts.find(
          (account: any) => account.id === accountId
        );

        return {
          ...account,
          email: record.email,
          created_at: record.created_at,
          last_login: record.last_login,
          user_id: record.id,
        };
      });
      return uniqueAccounts;
    } else return data?.data?.records;
  };

  return (
    <Row className={cm.container}>
      {selectedRowKeys.length === 0 && url !== "products" && (
        <Space
          className={cc([
            cm.space,
            addBtnVisible ? cm.spaceBig : cm.spaceSmall,
            url === "transactions" || url === "coupons" ? cm.notSearch : "",
          ])}
        >
          {addBtnVisible && (
            <RetailMainButton
              hasBackground
              className={cc([cm.btn, cm.add, "flex"])}
              onClick={button?.onClick}
              data-test={button?.dataTest}
              id={`${url}-add-button`}
            >
              {button?.title}
            </RetailMainButton>
          )}
          {url !== "transactions" && url !== "coupons" && (
            <RetailSearchBar
              placeholder={placeholder}
              onChange={({ target }) => setSearch(target.value)}
              defaultValue={search}
            />
          )}

          <RetailColumnPopover
            options={columnOptions}
            onChange={handleChange}
          />
          {/* <RetailFilterPopover mode="table" filters={tableConfig.filters} /> */}
        </Space>
      )}
      <RetailFilterBar />
      {url === "advertisers" || url === "invites" ? (
        <RetailInviteBar
          selectedRowKeys={selectedInfo}
          handleRowkeys={handleRowKeys}
          activeKey={activeKey}
        />
      ) : (
        url !== "products" && (
          <RetailBulkUpdateBar
            tableConfig={tableConfig}
            selectedRowKeys={selectedRowKeys}
            handleRowkeys={handleRowKeys}
            archived={activeKey === "ARCHIVED"}
          />
        )
      )}

      <ConfigProvider
        renderEmpty={customizeRenderEmpty}
        locale={i18n.language === "tr" ? tr : en}
      >
        <Table
          columns={selectedColumns(t)}
          showSorterTooltip={false}
          className={cc([cm.table, className || ""])}
          loading={isLoading}
          dataSource={switchDataSource()}
          rowKey={(record) => switchIDForRowKey(record)}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={{
            current: config?.page,
            pageSize: config?.pageSize,
            total: data?.data.total_records || data?.data.totalRecords,
            showSizeChanger: true,
            showTotal: (total, range) => t("common.pageTotal"),
            ...pagination,
          }}
          sortDirections={["descend", "ascend", null]}
          onChange={(pagination, filters, sorter: any) => {
            sorter.order !== undefined && sorter.order !== null
              ? setSort({
                  sortValue: sorter.field,
                  order: sorter.order === "ascend" ? "asc" : "desc",
                })
              : setSort(null);
            changePageSize(pagination.current!, pagination.pageSize!);
          }}
          scroll={{ x: true }}
          {...tableProps}
        />
      </ConfigProvider>

      {selectedRowKeys.length === 0 && url !== "products" && (
        <RetailExportBottomBar tableExport={tableExport} />
      )}
    </Row>
  );
};

export default RetailTable;
