import { Col } from "antd/lib/grid";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as DotFilled } from "../../../assets/icons/dotFilled.svg";
import useAdvertiserBalance from "../../../hooks/useAdvertiserBalance";
import RetailMoneyColumn from "../../Column/RetailMoneyColumn";
import RetailText from "../../Typography/RetailText";
import cm from "./style.module.scss";

export interface RetailBalanceContainerProps {
  className?: string;
}

const RetailBalanceContainer = ({ className }: RetailBalanceContainerProps) => {
  const { t } = useTranslation();

  const { data, error, isLoading } = useAdvertiserBalance();

  if (isLoading || error) return null;

  return (
    <Col className={cc(["flex", cm.balanceContainer, className || ""])}>
      <Col>
        <RetailText className={cm.balanceText} size="xs" weight="medium">
          {`${t("common.table.balance")}: `}
        </RetailText>
        <RetailText className={cm.balanceText} size="xs" weight="bold">
          <RetailMoneyColumn value={data?.data?.balance} isZero={true} />
        </RetailText>
      </Col>
      <DotFilled />
      <Col>
        <RetailText className={cm.balanceText} size="xs" weight="medium">
          {`${t("common.table.coupon")}: `}
        </RetailText>
        <RetailText className={cm.balanceText} size="xs" weight="bold">
          <RetailMoneyColumn value={data?.data?.coupons} isZero={true} />
        </RetailText>
      </Col>
    </Col>
  );
};

export default RetailBalanceContainer;
