import {
	useContext,
	useState,
} from "react";
import {
	TFunction,
	useTranslation,
} from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useParams } from "react-router";

import useApi from "../../../../api";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStateColumn from "../../../../components/Column/RetailStateColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import RetailTable from "../../../../components/Table/RetailTable";
import SelectProductsTable from "../../../../components/Table/SelectProductsTable";
import {
	Product,
	ProductContext,
} from "../../../../context/ProductProvider";
import { productFilters } from "../../../../utils/filters";

export interface ProductTableProps {
  ad_type: "PRODUCT" | "STORE" | "DISPLAY";
  name: string;
}
const ProductTable = ({ ad_type, name }: ProductTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const [isVisible, setIsVisible] = useState(false);

  const { selectedProducts, setSelectedProducts, handleDeleteAllProducts } =
    useContext(ProductContext) as Product;

  const queryClient = useQueryClient();

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "PRODUCTS",
    filters: productFilters(t),
  };

  const generalColumns = (t: TFunction) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType="PRODUCTS"
        />
      ),
    },
    {
      title: t("common.table.product"),
      dataIndex: "name",
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: t("common.table.status"),
      dataIndex: "state",
      render: (value: any) => (
        <RetailStateColumn value={value} type="products" />
      ),
    },
    {
      title: t("common.table.impression"),
      dataIndex: "impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    /* {
      title: t("common.table.viewable_impressions"),
      dataIndex: "viewable_impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    }, */
    {
      title: t("common.table.click"),
      dataIndex: "clicks",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.CTR"),
      dataIndex: "CTR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.spent"),
      dataIndex: "spend",
      render: (value: any) => (
        <RetailMoneyColumn value={value} spendColumn={true} />
      ),
    },
    {
      title: t("marketplaceDependentTable.cpc"),
      dataIndex: "CPC",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.cpm"),
      dataIndex: "CPM",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.sold"),
      dataIndex: "sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.roas"),
      dataIndex: "ROAS",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
  ];

  const buyboxColumn = (t: TFunction) => ({
    title: t("common.table.buybox"),
    dataIndex: "buybox",
    render: (value: boolean) =>
      value
        ? t("pages.acc.campaignDetails.winner")
        : t("pages.acc.campaignDetails.loser"),
  });

  const columns = (t: TFunction) => {
    if (name.toLowerCase() === "beymen") return generalColumns(t);
    else return [...generalColumns(t), buyboxColumn(t)];
  };

  const extraColumns = (t: TFunction) => [
    {
      title: t("components.reportForm.metric.sumDirectSaleAmount"),
      dataIndex: "direct_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSaleAmount"),
      dataIndex: "indirect_sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sale"),
      dataIndex: "sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumDirectSale"),
      dataIndex: "direct_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sumIndirectSale"),
      dataIndex: "indirect_sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.acos"),
      dataIndex: "ACoS",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    //dönüşüm oranı
    {
      title: t("components.reportForm.metric.CVR"),
      dataIndex: "CVR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
  ];

  const reset = () => {
    setIsVisible(false);
    handleDeleteAllProducts();
    setSelectedProducts([]);
  };

  const updateValue = async () => {
    const config = {
      products: selectedProducts.map((product) => product.id),
    };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addProduct = async () => {
    await mutateAsync();
    queryClient.refetchQueries({ active: true });
    reset();
  };

  const onOk = () => addProduct();

  return (
    <>
      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.productsBtn"),
          onClick: () => setIsVisible(true),
        }}
        placeholder={t("pages.acc.campaignDetails.productsPlaceholder")}
        tableConfig={tableConfig}
        columns={() => columns(t)}
        extraColumns={() => extraColumns(t)}
      />
      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t("pages.acc.campaignDetails.productsSub")}
        onCancel={reset}
        onOk={onOk}
        type="PRODUCTS"
      >
        <SelectProductsTable ad_type={ad_type} fetchAll={true} />
      </CampaignDetailsModal>
    </>
  );
};

export default ProductTable;
