import {
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	Link,
	useParams,
} from "react-router-dom";

import { ReactComponent as RightOutlined } from "../../../assets/icons/rightOutlined.svg";
import sanityClient from "../../../client";
import RetailAcademyHeader from "../../../components/Layout/RetailAcademyHeader";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import useAcademyCategoryFetch from "../../../hooks/useAcademyCategoryFetch";
import cm from "./style.module.scss";

const RetailAcademyPostCategory = () => {
  const { title } = useParams<{ title: string }>();

  const { i18n } = useTranslation();

  const { category } = useAcademyCategoryFetch();

  const [posts, setPosts] = useState<
    { title: string; slug: { current: string } }[]
  >([]);

  const fetchPosts = async () => {
    sanityClient
      .fetch(
        `*[count((categories[]->slug.current)[@ in ["${title}"]]) > 0 && language == "${i18n.language}" ]{
        title,
        description,
        slug,
      }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  };

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <RetailPageContainer>
      <RetailAcademyHeader
        data={{
          title: category?.title,
          description: category?.description,
        }}
      />
      <section className={cm.container}>
        {posts.map((post, index) => (
          <article key={index} className={cm.item}>
            <Link
              to={`/academy/blog/${category?.slug.current}/${post.slug.current}`}
              className={cm.text}
              state={{
                breadcrumb: true,
                breadcrumbType: "academy",
                value: { value: post.title, category: category?.slug.current },
              }}
            >
              {post.title}
            </Link>
            <RightOutlined />
          </article>
        ))}
      </section>
    </RetailPageContainer>
  );
};

export default RetailAcademyPostCategory;
