import { useTranslation } from "react-i18next";
import RetailTableTag from "../../Tag/RetailTableTag";
import RetailTooltip from "../../Tooltip/RetailTooltip";
import RetailText from "../../Typography/RetailText";

import QuestionCircleFilled from "@ant-design/icons/lib/icons/QuestionCircleFilled";
import { StateColumns } from "../../../utils/types";

export interface RetailStateColumnProps {
  value: any;
  type: StateColumns;
}

const RetailStateColumn = ({ value, type }: RetailStateColumnProps) => {
  const { t } = useTranslation();
  return (
    <RetailTableTag mode={value === "ON_PUBLISH" ? "green" : "unfilledYellow"}>
      <RetailText weight="bold" family="poppins" size="xxxs">
        {value === "ON_PUBLISH"
          ? t("common.table.on")
          : t("common.table.stopped")}
        {value !== "ON_PUBLISH" && value !== undefined && (
          <RetailTooltip
            title={t(`components.states.${type}_${value.toLowerCase()}`)}
          >
            <QuestionCircleFilled style={{ marginLeft: "6px" }} />
          </RetailTooltip>
        )}
      </RetailText>
    </RetailTableTag>
  );
};

export default RetailStateColumn;
