import Tabs from "antd/lib/tabs";
import cc from "classcat";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";

import { CheckOutlined } from "@ant-design/icons";

import useApi from "../../../api";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailCreativeImgColumn from "../../../components/Column/RetailCreativeImgColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CreativePreviewModal from "../../../components/Modal/CreativePreviewModal";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import { PolicyStatus } from "../../../utils/types";
import cm from "./style.module.scss";

const CreativesPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [activeKey, setActiveKey] = useState("ALL");

  const [visible, setVisible] = useState(false);

  const [url, setURL] = useState("");

  const close = () => setVisible(false);

  const tableConfig = {
    url: "creatives",
    isRelation: false,
    activeKey
    /* filters: advertiserFilters(t),
    onArchive: onArchive, */
  };

  const tableConfigTab = {
    ...tableConfig,
    defaultFilter: [{ key: "policyStatus", op: "eq", value: activeKey }],
  };

  const updateValue = async (data: any) => {
    const response = await api.patch(`creatives/${data.id}`, {
      policy_status: data.updated_status,
    });
    return response;
  };
  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (data: any, status: PolicyStatus) => {
    try {
      const createdData = { ...data, updated_status: status };
      await mutateAsync(createdData);
      queryClient.refetchQueries("table");
    } catch (err: any) {
      console.error(err);
    }
  };

  const okBtn = (records: any) => {
    return (
      <RetailMainButton
        hasBackground
        className={cc(["flex", cm.btn, cm.okBtn])}
        onClick={() => changeValue(records, "ACCEPTED")}
      >
        <CheckOutlined /> {t("common.accept")}
      </RetailMainButton>
    );
  };

  const rejectBtn = (records: any) => {
    return (
      <RetailMainButton
        hasBackground={false}
        className={cc(["flex", cm.btn, cm.rejectBtn])}
        onClick={() => changeValue(records, "REJECTED")}
      >
        {t("common.reject")}
      </RetailMainButton>
    );
  };

  const switchBtn = (value: PolicyStatus, records: any) => {
    switch (value) {
      case "ACCEPTED":
        return rejectBtn(records);
      case "REJECTED":
        return okBtn(records);
      case "PENDING":
        return (
          <>
            {rejectBtn(records)}
            {okBtn(records)}
          </>
        );
    }
  };

  const columns = (t: any) => [
    {
      title: t("pages.admin.creatives.creative"),
      dataIndex: "image_url",
      disabled: true,
      width: 300,
      render: (value: string) => (
        <RetailCreativeImgColumn
          onClick={() => {
            setURL(value);
            setVisible(true);
          }}
          value={value}
        />
      ),
    },
    {
      title: t("pages.admin.creatives.name"),
      dataIndex: "name",
      disabled: true,
      width: 200,
    },
    {
      title: t("common.table.status"),
      dataIndex: "policy_status",
      width: 200,
      render: (value: PolicyStatus) => (
        <RetailText
          family="poppins"
          weight="medium"
          size="xxxs"
          className={value ? cm[value.toLowerCase()] : ""}
        >
          {value ? t(`pages.admin.creatives.${value.toLowerCase()}`) : "-"}
        </RetailText>
      ),
    },
    {
      title: "",
      dataIndex: "policy_status",
      render: (value: PolicyStatus, records: any) => (
        <div className={cc(["flex", cm.btnContainer])}>
          {switchBtn(value, records)}
        </div>
      ),
    },
  ];
  return (
    <RetailPageContainer>
      <Tabs
        className="bordered-container"
        activeKey={activeKey}
        onChange={(value) => setActiveKey(value)}
      >
        <Tabs.TabPane key="ALL" tab={t("pages.admin.creatives.all")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columns={() => columns(t)}
            tableConfig={tableConfig}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="ACCEPTED" tab={t("pages.admin.creatives.accepteds")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columns={() => columns(t)}
            tableConfig={tableConfigTab}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="REJECTED" tab={t("pages.admin.creatives.rejecteds")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columns={() => columns(t)}
            tableConfig={tableConfigTab}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="PENDING" tab={t("pages.admin.creatives.pendings")}>
          <RetailTable
            addBtnVisible={false}
            placeholder={t("pages.admin.creatives.search")}
            columns={() => columns(t)}
            tableConfig={tableConfigTab}
          />
        </Tabs.TabPane>
      </Tabs>
      <CreativePreviewModal
        type="ad"
        url={url}
        visible={visible}
        onCancel={close}
      />
    </RetailPageContainer>
  );
};

export default CreativesPage;
