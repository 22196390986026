import Row from "antd/lib/row";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import RetailDateColumn from "../../../components/Column/RetailDateColumn";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailInvoiceModal from "../../../components/Modal/RetailInvoiceModal";
import RetailTable from "../../../components/Table/RetailTable";

const CouponsPage = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const columns = (t: any) => [
    {
      title: t("common.table.date"),
      dataIndex: "transaction_date",
      render: (value: string) => <RetailDateColumn value={value} />,
      disabled: true,
    },
    {
      title: t("common.table.agency"),
      dataIndex: "agency_name",
      render: (value: string) => (value ? value : "-"),
    },
    {
      title: t("common.table.advertiser"),
      dataIndex: "advertiser_id",
      render: (value: string, records: any) => (
        <RetailNameColumn
          state={{
            id: records.advertiser_id || 1,
            name: records.advertiser_name || "No Name",
          }}
          to={`/campaigns`}
          value={`${records.advertiser_name} (${value})`}
        />
      ),
    },
    {
      title: t("common.table.couponType"),
      dataIndex: "description",
      disabled: true,
      render: (value: string) =>
        t(`pages.admin.coupons.${value && value.toLowerCase()}`),
    },
    {
      title: t("common.table.desc"),
      dataIndex: "description",
      render: (value: string) =>
        t(`pages.admin.coupons.${value && value.toLowerCase()}_desc`),
    },
    {
      title: t("common.table.note"),
      dataIndex: "note",
      render: (value: string) => (value ? value : "-"),
    },
    {
      title: t("common.table.lastDate"),
      dataIndex: "expire_date",
      render: (value: string) => <RetailDateColumn value={value} />,
    },
    {
      title: t("common.table.amount"),
      dataIndex: "amount",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
  ];

  const open = () => setVisible(true);

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    defaultFilter: [
      {
        key: "transaction_type",
        op: "in",
        value: ["WELCOME_COUPON", "GIFT_COUPON"],
      },
    ],
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          columns={() => columns(t)}
          tableConfig={tableConfig}
          button={{
            title: t("pages.admin.coupons.add"),
            onClick: open,
          }}
          rowSelection={undefined}
        />
        <RetailInvoiceModal
          type="COUPONS"
          subtitle={t("pages.admin.coupons.subtext")}
          visible={visible}
          setVisible={setVisible}
        />
      </Row>
    </RetailPageContainer>
  );
};

export default CouponsPage;
