import {
	useContext,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useParams } from "react-router-dom";

import useApi from "../../../../api";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import RetailTable from "../../../../components/Table/RetailTable";
import SelectNegativeTable from "../../../../components/Table/SelectNegativeTable";
import {
	Category,
	CategoryContext,
} from "../../../../context/CategoryProvider";
import {
	Keyword,
	KeywordContext,
} from "../../../../context/KeywordProvider";
import useTableFetch from "../../../../hooks/useTableFetch";
import { negativeKeywordFilters } from "../../../../utils/filters";

export interface NegativeKeywordsTableProps {
  type: "NEGATIVE_KEYWORDS" | "NEGATIVE_CATEGORIES";
}

const NegativeKeywordsTable = ({ type }: NegativeKeywordsTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [alreadySelectedData, setAlreadySelectedData] = useState([]);

  const { data } = useTableFetch("campaign", true, {}, "NEGATIVE_CATEGORIES");

  const { negativeKeywords, setNegativeKeywords } = useContext(
    KeywordContext
  ) as Keyword;

  const { negativeCategories, setNegativeCategories } = useContext(
    CategoryContext
  ) as Category;

  const [isVisible, setIsVisible] = useState(false);

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: type,
    id: id ? parseInt(id) : 1,
    filters: negativeKeywordFilters(t),
  };

  const negativeKeywordColumns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType="NEGATIVE_KEYWORDS"
        />
      ),
      width: 85,
    },
    {
      title: t("components.table.keywords.keyword"),
      dataIndex: "name",
      width: 200,
    },
    {
      title: t("components.table.keywords.matchType"),
      dataIndex: "match_type",
      render: (value: "EXACT" | "PHRASE" | "BROAD") => (
        <span>{t(`common.${value.toLowerCase()}`)}</span>
      ),
      width: 200,
    },
  ];

  const negativeCategoriesColumns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType="NEGATIVE_CATEGORIES"
        />
      ),
      width: 85,
    },
    {
      title: t("common.table.category"),
      dataIndex: "name",
    },
  ];
  const reset = () => {
    type === "NEGATIVE_KEYWORDS"
      ? setNegativeKeywords([])
      : setNegativeCategories([]);
    setIsVisible(false);
  };

  const updateValue = async () => {
    const configNegativeKeywords = {
      negative_keywords:
        negativeKeywords.length > 0
          ? negativeKeywords.map((word) => {
              return {
                text: word.text,
                match_type: word.match_type,
              };
            })
          : null,
    };
    const configNegativeCategories = {
      negative_categories:
        negativeCategories.length > 0
          ? negativeCategories.map((category: any) => category.id)
          : null,
    };
    const response = await api.patch(
      `campaigns/${id}`,
      type === "NEGATIVE_KEYWORDS"
        ? configNegativeKeywords
        : configNegativeCategories
    );
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addNegativeKeywords = async () => {
    await mutateAsync();
    queryClient.refetchQueries({ active: true });
    reset();
  };

  const onOk = () => {
    addNegativeKeywords();
  };

  useEffect(() => {
    setAlreadySelectedData(data?.data.records);
  }, [data]);

  return (
    <>
      <RetailTable
        button={{
          title: t(`pages.acc.campaignDetails.${type.toLowerCase()}_btn`),
          onClick: () => setIsVisible(true),
        }}
        placeholder={t(
          `pages.acc.campaignDetails.${type.toLowerCase()}_placeholder`
        )}
        tableConfig={tableConfig}
        columns={() =>
          type === "NEGATIVE_KEYWORDS"
            ? negativeKeywordColumns(t)
            : negativeCategoriesColumns(t)
        }
      />
      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t(
          `pages.acc.campaignDetails.${type.toLowerCase()}_modal_subtitle`
        )}
        onCancel={reset}
        onOk={onOk}
        type={type}
      >
        <SelectNegativeTable
          type={type}
          alreadySelectedData={alreadySelectedData}
        />
      </CampaignDetailsModal>
    </>
  );
};

export default NegativeKeywordsTable;
