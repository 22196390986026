import Input from "antd/lib/input";
import cc from "classcat";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import RetailText from "../../Typography/RetailText";
import cm from "../style.module.scss";

export interface AdvertiserSelectProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  type: "CAMPAIGN" | "COUPONS" | "BALANCE" | "INVITED";
}

const AdvertiserSelect = ({ type, onClick }: AdvertiserSelectProps) => {
  const { t } = useTranslation();

  const { options, handleScroll, search, setSearch, handleKey } =
    useInfiniteScroll("advertisers", true, type !== "INVITED");

  const [visible, setVisible] = useState(false);

  const open = () => setVisible(true);

  const className =
    type === "CAMPAIGN" || type === "INVITED" ? cm.campaign : cm.financial;

  return (
    <div className={cm.container}>
      <Input
        onChange={handleKey}
        className={cc([cm.input, className])}
        value={search}
        placeholder={t("components.advertiserSelect.placeholder")}
        onClick={open}
      />
      <section
        className={cc([
          visible && options && options.length > 0 ? cm.selectBox : "",
        ])}
        onScroll={handleScroll}
      >
        {visible &&
          options &&
          options.map((item: any) => (
            <RetailText
              family="poppins"
              size="xs"
              key={item.id}
              onClick={() => {
                setSearch(item.name);
                setVisible(false);
                onClick && onClick(item);
              }}
            >
              {item.name} ({item.id})
            </RetailText>
          ))}
      </section>
    </div>
  );
};

export default AdvertiserSelect;
