import { useForm } from "antd/es/form/Form";
import Form from "antd/lib/form/Form";
import Input from "antd/lib/input";
import Upload from "antd/lib/upload";
import {
	UploadChangeParam,
	UploadFile,
	UploadProps,
} from "antd/lib/upload/interface";
import cc from "classcat";
import {
	useContext,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import { useParams } from "react-router";

import {
	DeleteOutlined,
	SearchOutlined,
} from "@ant-design/icons/lib/icons";

import useApi from "../../../../api";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/draggerOutlined.svg";
import RetailCreativeImgColumn from "../../../../components/Column/RetailCreativeImgColumn";
import RetailMoneyColumn from "../../../../components/Column/RetailMoneyColumn";
import RetailNumberColumn from "../../../../components/Column/RetailNumberColumn";
import RetailPercentageColumn from "../../../../components/Column/RetailPercentageColumn";
import RetailStateColumn from "../../../../components/Column/RetailStateColumn";
import RetailStatusColumn from "../../../../components/Column/RetailStatusColumn";
import RetailCreativeUploadInfo from "../../../../components/Form/RetailCreativeUploadInfo";
import RetailFormInput from "../../../../components/Form/RetailFormInput";
import CampaignDetailsModal from "../../../../components/Modal/CampaignDetailsModal";
import CreativePreviewModal from "../../../../components/Modal/CreativePreviewModal";
import RetailTable from "../../../../components/Table/RetailTable";
import RetailText from "../../../../components/Typography/RetailText";
import {
	Creative,
	CreativeContext,
} from "../../../../context/CreativeProvider";
import useTableFetch from "../../../../hooks/useTableFetch";
import { Img } from "../../../../utils/types";
import cm from "./style.module.scss";

const CreativesTable = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api } = useApi();

  const { data } = useTableFetch("ad_placements", false, {}, undefined, [
    { key: "status", op: "eq", value: "ACTIVE" },
    {
      key: "ad_format",
      op: "eq",
      value: "DISPLAY",
    },
  ]);

  const [isVisible, setIsVisible] = useState(false);

  const [visible, setVisible] = useState(false);

  const [url, setURL] = useState("");

  const { img, setImg, deleteCreative } = useContext(
    CreativeContext
  ) as Creative;

  const mainImgFile = useRef<File>();

  const [form] = useForm();

  const queryClient = useQueryClient();

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "CREATIVES",
    /* filters: productFilters(t), */
  };

  const close = () => setVisible(false);

  const columns = (t: any) => [
    {
      title: t("common.table.active"),
      dataIndex: "status",
      render: (value: any, records: any) => (
        <RetailStatusColumn
          records={records}
          url={`campaigns/${id}/relations`}
          relationType="CREATIVES"
        />
      ),
    },
    {
      title: t("pages.admin.creatives.creative"),
      dataIndex: "image_url",
      width: 240,
      disabled: true,
      render: (value: string) => (
        <RetailCreativeImgColumn
          onClick={() => {
            setURL(value);
            setVisible(true);
          }}
          value={value}
        />
      ),
    },
    {
      title: t("pages.admin.creatives.name"),
      dataIndex: "name",
      disabled: true,
    },
    {
      title: t("common.table.status"),
      dataIndex: "state",
      render: (value: any) => (
        <RetailStateColumn value={value} type="creatives" />
      ),
    },
    {
      title: t("common.table.acos"),
      dataIndex: "ACoS",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.impression"),
      dataIndex: "impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    /* {
      title: t("common.table.viewable_impressions"),
      dataIndex: "viewable_impressions",
      render: (value: any) => <RetailNumberColumn value={value} />,
    }, */
    {
      title: t("common.table.click"),
      dataIndex: "clicks",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.sold"),
      dataIndex: "sale_amount",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.spent"),
      dataIndex: "spend",
      render: (value: any) => (
        <RetailMoneyColumn value={value} spendColumn={true} />
      ),
    },
    {
      title: t("marketplaceDependentTable.cpm"),
      dataIndex: "CPM",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
  ];

  const extraColumns = (t: any) => [
    {
      title: t("common.table.ctr"),
      dataIndex: "CTR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.cpc"),
      dataIndex: "CPC",
      render: (value: any) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.roas"),
      dataIndex: "ROAS",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("components.reportForm.metric.sale"),
      dataIndex: "sale",
      render: (value: any) => <RetailNumberColumn value={value} />,
    },
    //dönüşüm oranı
    {
      title: t("components.reportForm.metric.CVR"),
      dataIndex: "CVR",
      render: (value: any) => <RetailPercentageColumn value={value} />,
    },
  ];

  const reset = () => {
    setIsVisible(false);
    setImg([]);
  };

  const updateValue = async () => {
    const config = {
      creatives: img.map((i) => i.uuid),
    };
    const response = await api.patch(`campaigns/${id}`, config);
    return response;
  };

  const updateCreativeName = async (uuid: string) => {
    const response = await api
      .patch(`/creatives/${uuid}`, {
        name: form.getFieldValue(`${uuid}_name`),
      })
      .then((response) => {
        setImg((current: Img[]) =>
          current.map((obj: Img) => {
            if (obj.uuid === uuid) {
              return { ...obj, name: response.data.name };
            }
            return obj;
          })
        );
      });

    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const addCreatives = async () => {
    await mutateAsync();
    queryClient.refetchQueries({ active: true });
    reset();
  };

  const onOk = () => {
    addCreatives();
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const file = mainImgFile;
    if (!info || !info.file || info.fileList.length === 0) {
      file.current = undefined;
    }

    file.current = info.file ? (info.file as unknown as File) : undefined;

    const formData = new FormData();

    formData.append("image", file.current!);
    formData.append("name", file?.current?.name.replace(/\.[^/.]+$/, "")!);
    formData.append("status", "ACTIVE");

    const config = {
      image: formData.get("image"),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
      }),
    };

    await api
      .post("/creatives", config, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        const img = new Image();

        img.src = response.data.image_url;

        img.onload = () => {
          setImg((prev: Img[]) => [
            ...prev,
            {
              url: img.src,
              size: `${img.width}x${img.height}`,
              name: response.data.name,
              uuid: response.data.id,
              size_valid: response.data.size_valid,
            },
          ]);
        };
      });
  };

  const props: UploadProps = {
    name: "files",
    multiple: true,
    className: "dragger",
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) => handleFileChange(f),
    accept: ".jpg, .svg, .jpeg, .png, gif",
  };

  const open = (url: string) => {
    setURL(url);
    setVisible(true);
  };

  return (
    <>
      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.creativesBtn"),
          onClick: () => setIsVisible(true),
        }}
        placeholder={t("pages.acc.campaignDetails.creativesPlaceholder")}
        tableConfig={tableConfig}
        columns={() => columns(t)}
        extraColumns={() => extraColumns(t)}
      />

      <CampaignDetailsModal
        visible={isVisible}
        subtitle={t("pages.acc.campaignDetails.creativesSub")}
        onCancel={reset}
        onOk={onOk}
        type="CREATIVES"
      >
        <Upload.Dragger {...props}>
          <UploadIcon className={cm.upload} />
          <RetailText size="xs" weight="medium">
            {t("components.campaignForm.firstStep.draggerTitle")}
          </RetailText>
          <RetailText size="xxxs" weight="medium" className={cm.creativeText}>
            {t("components.campaignForm.firstStep.draggerText")}
          </RetailText>
          <div className={cc(["flex", cm.sizeContainer])}>
            {data?.data.records.map((d: any) => (
              <RetailText
                size="xxxs"
                family="poppins"
                weight="medium"
                className={cm.sizeTag}
              >
                {d.recommended_size}
              </RetailText>
            ))}
          </div>
        </Upload.Dragger>

        {img.length > 0 && (
          <div className={cm.creativeSub}>
            <section className={cc(["flex", cm.creatives])}>
              {img.map((i) => (
                <div
                  className={cc([
                    "flex",
                    i.size_valid ? "" : cm.validError,
                    cm.creativeContainer,
                  ])}
                  key={i.uuid}
                >
                  <div className="flex">
                    <img src={i.url} alt={i.name} />
                  </div>
                  <div>
                    <Form
                      onFinish={() => updateCreativeName(i.uuid)}
                      form={form}
                      autoComplete="off"
                      requiredMark={false}
                      initialValues={{ [`${i.uuid}_name`]: i.name }}
                    >
                      <RetailFormInput
                        isFocused={i.name !== ""}
                        label={t(
                          "components.campaignForm.firstStep.creativeLabel"
                        )}
                        className="floating"
                        name={`${i.uuid}_name`}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "components.campaignForm.firstStep.creativeError"
                            ),
                          },
                        ]}
                      >
                        <Input
                          className="floating"
                          data-test="campaign-form-creative-name"
                          onBlur={() => form.submit()}
                        />
                      </RetailFormInput>
                    </Form>

                    <RetailText
                      weight="medium"
                      size="xs"
                      className={cc(["flex", cm.creativeContainerText])}
                    >
                      <span>{t("components.campaignForm.firstStep.size")}</span>
                      <span className={cm.size}>{i.size}</span>
                    </RetailText>
                    {i.size_valid ? (
                      <div className={cc(["flex", cm.icons])}>
                        <div
                          className={cc(["flex", cm.iconBtn])}
                          onClick={() => open(i.url)}
                        >
                          <SearchOutlined />
                        </div>
                        <div
                          className={cc(["flex", cm.iconBtn])}
                          onClick={() => deleteCreative(i.uuid)}
                        >
                          <DeleteOutlined className={cm.delete} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className={cc(["flex", cm.justDelete])}
                          onClick={() => deleteCreative(i.uuid)}
                        >
                          <DeleteOutlined className={cm.delete} />
                          <RetailText weight="bold" size="xs">
                            {t("common.delete")}
                          </RetailText>
                        </div>
                        <div className={cm.invalidTag}>
                          <RetailText
                            family="poppins"
                            weight="bold"
                            size="xxxs"
                          >
                            {t("components.campaignForm.firstStep.invalid")}
                          </RetailText>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </section>
          </div>
        )}
        <RetailCreativeUploadInfo />
      </CampaignDetailsModal>
      <CreativePreviewModal
        type="creative"
        url={url}
        visible={visible}
        onCancel={close}
      />
    </>
  );
};

export default CreativesTable;
