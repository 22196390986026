import Tabs from "antd/lib/tabs";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	useMutation,
	useQueryClient,
} from "react-query";
import {
	useLocation,
	useNavigate,
} from "react-router-dom";

import useApi from "../../../api";
import RetailDateColumn from "../../../components/Column/RetailDateColumn";
import RetailLinkColumn from "../../../components/Column/RetailLinkColumn";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import RetailNotification from "../../../components/Notification";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import { reportFilters } from "../../../utils/filters";
import cm from "./style.module.scss";

const { TabPane } = Tabs;

const ReportsPage: React.VFC = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  const { api } = useApi();

  const [activeKey, setActiveKey] = useState("");

  const updateValue = async (data: any) => {
    const config = {
      status: data.report_status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED",
    };
    const response = await api.patch(`reports/${data.report}`, config);
    return response;
  };
  const { mutateAsync } = useMutation(updateValue);

  const changeArchiveStatus = async (data: any) => {
    try {
      await mutateAsync(data);
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        "",
        t(`components.notification.${data.report_status.toLowerCase()}Report`)
      );
    } catch (error) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };
  const tableConfig = {
    url: "reports",
    isRelation: false,
    filters: reportFilters(t),
    activeKey,
    to: !pathname.includes("admin") ? "edit-report" : "admin/edit-report",
    onArchive: changeArchiveStatus,
  };

  const activeTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "reportStatus", op: "ne", value: "ARCHIVED" }],
  };

  const archivedTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "reportStatus", op: "eq", value: "ARCHIVED" }],
  };

  const columns = (t: any) => [
    {
      title: t("common.table.name"),
      dataIndex: "report_name",
      disabled: true,
      render: (value: string, records: any) => (
        <RetailLinkColumn
          value={value}
          to={
            pathname.includes("admin")
              ? `/admin/report/${records.report}`
              : `/report/${records.report}`
          }
          state={{
            breadcrumb: true,
            breadcrumbType: pathname.includes("admin")
              ? "admin_report"
              : "report",
          }}
        />
      ),
    },
    {
      title: t("common.table.startDate"),
      dataIndex: "report_start_date",
      render: (value: string, records: any) =>
        records.report_type === "SCHEDULED" || value === "0001-01-01"
          ? "-"
          : moment(value).format("DD/MM/YY"),
    },
    {
      title: t("common.table.endDate"),
      dataIndex: "report_end_date",
      render: (value: string) => <RetailDateColumn value={value} />,
    },
    {
      title: t("pages.acc.reports.table.type"),
      dataIndex: "report_type",
      render: (value: "STATIC" | "SCHEDULED") =>
        t(`components.reportForm.${value && value.toLowerCase()}`),
    },
    {
      title: t("common.table.frequency"),
      dataIndex: "report_delivery_frequency",
      render: (value: string) =>
        value ? t(`components.reportForm.${value.toLowerCase()}`) : "-",
    },
    {
      title: t("common.table.initialDate"),
      dataIndex: "report_created_at",
      render: (value: string) => (
        <RetailText family="poppins" size="xs">
          {moment(value).format("DD/MM/YY")}
        </RetailText>
      ),
    },
  ];

  const handleClick = () =>
    navigate(
      !pathname.includes("admin") ? "/create-report" : "/admin/create-report"
    );

  return (
    <RetailPageContainer>
      <Tabs
        className={cm.container}
        onChange={(activeKey) => setActiveKey(activeKey)}
      >
        <TabPane tab={t("common.listed")} key="ACTIVE">
          <RetailTable
            columns={() => columns(t)}
            tableConfig={activeTabConfig}
            placeholder={t("pages.acc.reports.placeholder")}
            button={{
              title: t("pages.acc.reports.button"),
              dataTest: "add-new-report",
              onClick: handleClick,
            }}
            rowKey={(record) => record.report}
          />
        </TabPane>
        <TabPane tab={t("common.archived")} key="ARCHIVED">
          <RetailTable
            tableConfig={archivedTabConfig}
            columns={() => columns(t)}
            placeholder={t("pages.acc.reports.placeholder")}
            addBtnVisible={false}
            rowKey={(record) => record.report}
          />
        </TabPane>
      </Tabs>
    </RetailPageContainer>
  );
};

export default ReportsPage;
