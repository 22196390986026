import Row, { RowProps } from "antd/lib/row";
import cc from "classcat";
import React from "react";

import cm from "./style.module.scss";

export interface RetailFormRowProps extends RowProps {
  hasBackground?: boolean
}

const RetailFormRow: React.FC<RetailFormRowProps> = ({
  children,
  hasBackground = false,
  className,
  ...rowProps
}) => {
  return (
    <Row className={cc([cm.row, hasBackground ? cm.bg : "", className || ""])} {...rowProps}>
      {children}
    </Row>
  );
};

export default RetailFormRow;