import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

import { MoreOutlined } from "@ant-design/icons/lib/icons";

import useApi from "../../../api";
import { ReactComponent as PencilOutlined } from "../../../assets/icons/pencilOutlined.svg";
import { ReactComponent as SearchOutlined } from "../../../assets/icons/searchOutlined.svg";
import RetailMoneyColumn from "../../../components/Column/RetailMoneyColumn";
import RetailNameColumn from "../../../components/Column/RetailNameColumn";
import RetailFormInput from "../../../components/Form/RetailFormInput";
import RetailPageContainer from "../../../components/Layout/RetailPageContainer";
import CampaignDetailsModal from "../../../components/Modal/CampaignDetailsModal";
import RetailInvoiceModal from "../../../components/Modal/RetailInvoiceModal";
import RetailTable from "../../../components/Table/RetailTable";
import RetailText from "../../../components/Typography/RetailText";
import RetailTitle from "../../../components/Typography/RetailTitle";
import { validateNumber } from "../../../utils/helpers";
import cm from "./style.module.scss";

const TransactionsPage = () => {
  const { t } = useTranslation();

  const { api } = useApi();

  const queryClient = useQueryClient();

  const [visible, setVisible] = useState(false);

  const [detailsVisible, setDetailsVisible] = useState(false);

  const [updateVisible, setUpdateVisible] = useState(false);

  const [details, setDetails] = useState<any>({ spend: null, budget: null });

  const [activeKey, setActiveKey] = useState("SPENDING");

  const [invoiceNo, setInvoiceNo] = useState<string | undefined>();

  const [id, setID] = useState<string | undefined>();

  const openDetails = (records: any) => {
    setDetailsVisible(true);
    setDetails({
      spend: records.spend_details,
      budget: records.budget_details,
    });
  };

  const openUpdate = (records: any) => {
    setUpdateVisible(true);
    setInvoiceNo(records.invoice_number);
    setID(records.id);
  };

  const invoiceNumberCharacterLimit = 30;

  const columns = (t: any) => [
    {
      title: t("common.table.date"),
      dataIndex: "transaction_date",
      render: (value: string) => moment(value).format("DD/MM/YYYY"),
      disabled: true,
    },
    {
      title: t("common.table.invoice"),
      dataIndex: "invoice_number",
      render: (value: string) => (
        <span className={cm.invoiceNo}>{value ? value : "-"}</span>
      ),
    },
    {
      title: t("common.table.agency"),
      dataIndex: "agency_name",
      render: (value: string) => (value ? value : "-"),
    },
    {
      title: t("common.table.advertiser"),
      dataIndex: "advertiser_name",
      disabled: true,
      render: (value: string, records: any) => (
        <RetailNameColumn
          to={`/campaigns`}
          value={`${value} (${records.advertiser_id})`}
          state={{ id: records.advertiser_id || 1, name: value || "No Name" }}
        />
      ),
    },
    {
      title: t("common.table.transaction"),
      dataIndex: "transaction_type",
      render: (value: string) =>
        t(`pages.admin.transactions.${value?.toLowerCase()}`),
      disabled: true,
    },
    {
      title: t("common.table.desc"),
      dataIndex: "description",
      render: (value: string) =>
        t(`pages.admin.transactions.${`${value?.toLowerCase()}_desc`}`),
    },
    {
      title: t("common.table.creator"),
      dataIndex: "created_by",
      render: (value: string) => (value ? value : "-"),
    },
    {
      title: t("common.table.amount"),
      dataIndex: "amount",
      width: 120,
      render: (value: number, records: any) => (
        <span
          style={{
            color:
              records.transaction_type === "SPEND"
                ? "var(--red-7)"
                : "var(--green-7)",
            display: "flex",
          }}
        >
          {records.transaction_type === "SPEND" ? (
            value !== undefined && (
              <>
                - <RetailMoneyColumn value={value} />
              </>
            )
          ) : (
            <>
              + <RetailMoneyColumn value={value} />
            </>
          )}
        </span>
      ),
    },
    {
      title: t("common.table.detail"),
      dataIndex: "spend_details",
      render: (value: any, records: any) =>
        value && (
          <SearchOutlined
            className={cm.icon}
            onClick={() => openDetails(records)}
          />
        ),
      disabled: true,
    },
    {
      title: "",
      dataIndex: "transaction_type",
      unsortable: true,
      render: (value: any, records: any) =>
        value === "BALANCE_UPDATED" && (
          <Popover
            content={
              <span
                className={cc(["flex", cm.text, cm.link])}
                onClick={() => openUpdate(records)}
              >
                <PencilOutlined /> {t("common.edit")}
              </span>
            }
            placement="leftTop"
            trigger="hover"
            arrowPointAtCenter
            overlayClassName={cm.popover}
          >
            <MoreOutlined style={{ cursor: "pointer", height: "1px" }} />
          </Popover>
        ),
    },
  ];

  const open = () => setVisible(true);

  const close = () => setVisible(false);

  const closeDetails = () => setDetailsVisible(false);

  const closeUpdate = () => {
    setUpdateVisible(false);
    setInvoiceNo("");
  };

  const onOk = async () => {
    try {
      await api.patch(`transactions/${id}`, {
        invoice_number: invoiceNo,
      });
      closeUpdate();
      queryClient.refetchQueries("table");
    } catch (e) {
      console.log(e);
    }
  };

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    /* to: openUpdate, */
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          columns={() => columns(t)}
          tableConfig={tableConfig}
          rowSelection={undefined}
          button={{
            title: t("components.modal.campaignDetails.transactions"),
            onClick: open,
          }}
        />
        <RetailInvoiceModal
          type="TRANSACTIONS"
          subtitle={t("pages.admin.transactions.subtext")}
          visible={visible}
          setVisible={setVisible}
          onCancel={close}
        />
        <CampaignDetailsModal
          type="TRANSACTION_DETAILS"
          subtitle={t("pages.admin.transactions.detailsSubtext")}
          visible={detailsVisible}
          onCancel={closeDetails}
        >
          <Tabs
            className={cc([
              "campaign-details-tab",
              activeKey === "targeting" ? "two-step-tabs" : "",
            ])}
            activeKey={activeKey}
            onChange={(value) => setActiveKey(value)}
          >
            <Tabs.TabPane
              key="SPENDING"
              tab={t("pages.admin.transactions.spendTab")}
              className={cm.tab}
            >
              {details.spend &&
                details.spend.map((item: any, index: number) => (
                  <article
                    key={index}
                    className={cc(["flex", cm.spaceBetween, cm.line])}
                  >
                    <RetailText weight="bold" size="xs" className={cm.item}>
                      {item.campaign}
                    </RetailText>
                    <RetailText
                      weight="medium"
                      size="xs"
                      family="poppins"
                      className={cm.item}
                    >
                      - <RetailMoneyColumn value={item?.amount} />
                    </RetailText>
                  </article>
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="BUDGET"
              tab={t("pages.admin.transactions.budgetTab")}
              className={cm.tab}
            >
              {details.spend &&
                details.budget.map((item: any, index: number) => (
                  <article
                    key={index}
                    className={cc(["flex", cm.spaceBetween, cm.line])}
                  >
                    <RetailText weight="bold" size="xs" className={cm.item}>
                      {t(
                        `pages.admin.transactions.${item.resource?.toLowerCase()}`
                      )}
                    </RetailText>
                    <RetailText
                      weight="medium"
                      size="xs"
                      family="poppins"
                      className={cm.item}
                    >
                      - <RetailMoneyColumn value={item?.amount} />
                    </RetailText>
                  </article>
                ))}
            </Tabs.TabPane>
          </Tabs>
        </CampaignDetailsModal>
      </Row>
      <CampaignDetailsModal
        type="UPDATE_TRANSACTION"
        subtitle={t("pages.admin.transactions.update")}
        visible={updateVisible}
        disabled={
          invoiceNo === undefined ||
          invoiceNo.length > invoiceNumberCharacterLimit
        }
        onCancel={closeUpdate}
        onOk={onOk}
      >
        <Form
          initialValues={{
            invoice_no: invoiceNo,
          }}
          autoComplete="off"
        >
          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.transactions.title")}
          </RetailTitle>
          <RetailFormInput
            isFocused={invoiceNo !== undefined}
            label={t("pages.admin.transactions.title")}
            name="invoice_no"
            className="floating"
            rules={[
              {
                max: invoiceNumberCharacterLimit,
                message: t("pages.admin.transactions.invoiceWarning"),
              },
            ]}
          >
            <Input
              onChange={({ target }) => setInvoiceNo(target.value)}
              className={cc(["floating", cm.input])}
              value={invoiceNo}
              onKeyDownCapture={(e) => validateNumber(e)}
              data-test="campaign-form-name"
            />
          </RetailFormInput>
        </Form>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default TransactionsPage;
