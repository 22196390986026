import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import useApi from "../api";
import {
	Auth,
	AuthContext,
} from "../context/AuthProvider";

const useAdvertiserBalance = () => {
  const { api, baseURL } = useApi();

  const { pathname } = useLocation();

  const { authToken } = useContext(AuthContext) as Auth;

  const isEnabled =
    !pathname.includes("admin") && baseURL() !== undefined && authToken !== "";

  const { isLoading, error, data } = useQuery(
    "advertiser_balance",
    () => {
      const data = api.get("/balance");
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return { isLoading, data, error };
};

export default useAdvertiserBalance;
